import React, { useEffect, useState } from "react" ;
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faTrash , faPlay , faMinus } from "@fortawesome/free-solid-svg-icons";
import Table from "../../../widget/Table/Table";
import {Admin_Series_Delete_Ads , Admin_Sereas_Add_Ads , Admin_Get_Episode_Ads} from '../../../widget/API_Admin';
import Toast_noty from '../../../widget/Toast_noty'
import CModal from "../../../widget/CModal/CModal";
import { BASE_Medai_URL } from "../../../widget/BASE_Medai_URL";
import moment from "moment";
import {TimePicker} from 'antd'
import Loader from "../../../widget/Loader/Loader";
import '../seriesStyle.css';




function SeriesEditAds(props) {
    const[display , setDisplay]=useState(false)
    const[listData , setListData]=useState([])
    const[actionList , setActionList]=useState(-1);
    const[visibleDelete , setVisibleDelete]=useState(false);
    const[deleteId , setDeleteId]=useState(null);
    const[visiblePlay , setVisiblePlay]=useState(false)
    const[playFile , setPlayFile]=useState(null)
    const[visibleAdd,setVisibleAdd]=useState(false)
    const[adsAddFile , setAdsAddFile]=useState(null);
    const[adsAddTime , setAdsAddTime]=useState(null);




    const openDeleteModal=(id)=>{
        setDeleteId(id)
        setVisibleDelete(true)
    }

    const onFinishDelete=()=>{
        setDisplay(true);
        const returndata =  Admin_Series_Delete_Ads(props.match.params.seriesId ,props.match.params.id , deleteId);
        returndata.then((res)=>{
        setDisplay(false)
        if(res.result == 'ok'){
            setDisplay(false);
            setDeleteId(null);
            setVisibleDelete(false)
            getData()
        }
        })
    }

    const moviesAddAdsSingle=()=>{
        if(adsAddTime != null || adsAddFile != null){
            setDisplay(true);
            let dataAdd = [{
                'file':adsAddFile ,
                'duration':adsAddTime
            }]
            const returndata =  Admin_Sereas_Add_Ads(props.match.params.seriesId ,props.match.params.id , dataAdd);
            returndata.then((res)=>{
            setDisplay(false)
            if(res.result == 'ok'){
                setVisibleAdd(false)
                setAdsAddFile(null)
                setAdsAddTime(null)
                getData()
                Toast_noty("Success", 5000, "seuccess");
            }
            })
        }
        else {
            Toast_noty("Select Time and Video", 5000, "error");
        }
    }

    const openModalPlay=(file)=>{
        setPlayFile(file);
        setVisiblePlay(true)
    }


    const changeDisplayActionList=(id)=>{
        if(actionList == id){
            setActionList(-1)
        }
        else {
            setActionList(id)
        }
    }

    const emptyFields=()=>{
        setPlayFile(null)
    }

    const col = [
        {
            title:"id" ,
            render:(i) => (<>{i.id != null && i.id}</>)
        },
        {
            title:"Time" ,
            render:(i) => (<>{i.second != null && `${i.second} s`}</>)
        },
        {
            title:"Play" ,
            render:(i) => (<div onClick={()=>openModalPlay(i.file_url)} className="table-play" ><FontAwesomeIcon icon={faPlay} /></div>)
        },
        {
            title:"Action" ,
            render:(i) => (
            <div id="table-action" className="table-action">
                <div className="table-action-icon" onClick={()=>changeDisplayActionList(i.id)}>
                    <FontAwesomeIcon icon={faEllipsisV}  />
                </div>
                <div onClick={()=>openDeleteModal(i.id)} className={`table-action-list ${actionList == i.id && 'table-action-list-active'}`}>
                    <div  className="table-action-list-item">
                        <FontAwesomeIcon icon={faTrash} />
                        <span>Delete</span>
                    </div>
                </div>
            </div>
            )
        },

    ]


    const getData=()=>{
        setDisplay(true);
        const returndata =  Admin_Get_Episode_Ads(props.match.params.seriesId ,props.match.params.id );
        returndata.then((res)=>{
        setDisplay(false)
        if(res.result == 'ok'){
            setListData(res.data)
        }
        })
    }


    useEffect(()=>{
        if(props.match.params.id){
            getData()
        }
    },[])


    return (
        <div className="fullPage">
            {display && <Loader />}
            <CModal  visible={visibleDelete} setVisible={setVisibleDelete} title="DeleteAds"> 
                <p>Are you sure delete Ads  ? </p>
                <div className="CModel-Bottom">
                    <button className="CModel-Bottom-btn-ok" onClick={onFinishDelete} >Ok</button>
                    <button className="CModel-Bottom-btn-cancel" onClick={()=>{setVisibleDelete(false)}}>Close</button>
                </div>
            </CModal>
            <CModal onScap={emptyFields}  visible={visiblePlay} setVisible={setVisiblePlay} title="PlayAds"> 
                {playFile != null && 
                    <video  controls>
                        <source src={`${BASE_Medai_URL}/${playFile}`} type="video/mp4" />
                    </video> 
                }
            </CModal>
            <CModal onScap={emptyFields}  visible={visibleAdd} setVisible={setVisibleAdd} title="AddAds"> 
                <div className="addseries-cont-form-section-ads">
                    <div  className="addseries-cont-form-section-ads-item">
                        <div className="addseries-cont-form-section-ads-item-sec">
                            <label>
                                <input  onChange={(e)=>{setAdsAddFile(e.target.files[0])}} type="file" accept="video/*"  required/>
                                <span>{adsAddFile != null && adsAddFile.name ? adsAddFile.name : "Select ads file"}</span>
                            </label>
                        </div>
                        <div className="addseries-cont-form-section-ads-item-sec">
                            <TimePicker onChange={(e)=>{setAdsAddTime(moment(e._d , 'HH:mm:ss').diff(moment().startOf('day'), 'seconds'))}} required/>
                        </div>
                    </div>
                    <div className="addseries-cont-form-section-ads-plus" onClick={moviesAddAdsSingle} >Add</div>
                    
                </div>
            </CModal>
            <div className="pagelist">
                <div className="pagelist-head">
                    <span>Episode Ads</span>
                    <button className="pagelist-head-button" onClick={()=>{setVisibleAdd(true)}}>Add</button>
                </div>
                {listData.length > 0 && 
                    <Table range={20} columns={col} data={listData} />
                }
            </div>
        </div> 
    )
}

export default SeriesEditAds;
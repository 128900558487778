import React, { useEffect, useState } from "react" ;
import {ToastContainer} from "react-toastify";
import Toast_noty from '../../../widget/Toast_noty'
import Loader from "../../../widget/Loader/Loader";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import {Get_All_Artists , Add_Album_three} from '../../../widget/API'
import {Admin_Add_Album_three} from '../../../widget/API_Admin'
import {Redirect} from 'react-router-dom';
import { Select , Space, Form , Input ,InputNumber} from 'antd';
import TrackAutoFields from '../TrackAutoFields';
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from "@fortawesome/free-solid-svg-icons";




function TrackAdd(props) {
    const[dispaly , setDisplay]=useState(false);
    const [duration , setDuration]=useState(0);
    const[fileMusic , setFileMusic]=useState(null);
    const[feats , setFeats]=useState(false);
    const[listArtists , setListArtists]=useState([]);
    const { Option } = Select;
    


    const selectFile= async(e)=>{
        await setFileMusic(e.target.files[0])
        const audio = await document.getElementsByTagName('audio')[0] ;
        audio.onloadedmetadata = ()=>{
            setDuration(parseInt(audio.duration));
        }
    }


    const getArtists=()=>{
        const returndata =  Get_All_Artists();
        returndata.then((res)=>{
        setDisplay(false);
        if(res.result == 'ok'){
            setListArtists(res.data)
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
         returndata.catch((er)=>{
            setDisplay(false);
            Toast_noty('please try again', 5000, "error");
        })
    }



    const onFinish=(values)=>{
        if(values && values.labels){
            values['labels'] =  Object.entries(values.labels).map(([key , value])=>{
                if(value && value._d){
                    return {
                        "id" : key,
                        "value" : String(moment(value._d).format('YYYY-MM-DD hh:mm')),
                    }
                }
                else {
                    return {
                        "id" : key,
                        "value" : String(value),
                    }
                }
                
            })
        }
        setDisplay(true);
        const returndata =  Admin_Add_Album_three(props.match.params.id ,values , false , 'album' , fileMusic , duration , props.match.params.artistID);
        returndata.then((res)=>{
        setDisplay(false)
        if(res.result == 'ok'){
            props.history.push(`/panel/album/detail/${props.match.params.id}`);  
        }
        else 
        {
            Toast_noty(res.error, 5000, "error");
        }
        })
        returndata.catch((er)=>{
            setDisplay(false);
            Toast_noty('please try again', 5000, "error");
        })
    }


    useEffect(()=>{
        getArtists()
    },[])

    return (
        <>
            <div className="addalbum-cont">
                {dispaly && <Loader/>}
                {/* <div className="addalbum-cont-head">
                    <h3>Add Track</h3>
                </div> */}
                <Form onFinish={onFinish} id="addalbum-cont-form" className="addalbum-cont-form">
                        <div className="addalbum-cont-form-section">

                            <div className="addalbum-cont-form-section-item">
                                <span>Track name</span>
                                <Form.Item  name="name" rules={[{ required: true}]}>
                                    <Input />
                                </Form.Item>
                            </div>

                            <div className="addalbum-cont-form-section-item">
                                <span>Lyric</span>
                                <Form.Item  name="lyric" rules={[{ required: true}]}>
                                    <Input />
                                </Form.Item>
                            </div>

                            <div className="addalbum-cont-form-section-item">
                                <span>Producer</span>
                                <Form.Item  name="producer" rules={[{ required: true}]}>
                                    <Input />
                                </Form.Item>
                            </div>

                            <TrackAutoFields/>

                            <div className="addalbum-cont-form-section-item">
                                <span>Music producer</span>
                                <Form.Item  name="music_producer" rules={[{ required: true}]}>
                                    <Input />
                                </Form.Item>
                            </div>

                            <div className="addalbum-cont-form-section-item">
                                <span>Feats</span>
                                <Form.Item name="isFeats" >
                                    <Select
                                        allowClear
                                        onChange={(value)=>{setFeats(value)}}
                                        >
                                            <Option value={true}>Yes</Option>
                                            <Option value={false}>No</Option>
                                    </Select>
                                </Form.Item>
                            </div>

                            {feats && 
                                <div className="addalbum-cont-form-section-feat">
                                    <Form.List name="feats">
                                        {(fields, { add, remove }) => (
                                        <>
                                            {fields.map(({ key, name, fieldKey, ...restField }) => (
                                            <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                                <Form.Item
                                                {...restField}
                                                name={[name, 'artist_id']}
                                                fieldKey={[fieldKey, 'artist_id']}
                                                rules={[{ required: true, }]}
                                                >
                                                    <Select
                                                        placeholder="Select artist"
                                                        allowClear
                                                        >
                                                            {listArtists.map(item=>
                                                                <Option value={item.id}>{item.name}</Option>
                                                            )}
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item
                                                {...restField}
                                                name={[name, 'percentage']}
                                                fieldKey={[fieldKey, 'percentage']}
                                                rules={[{ required: true}]}
                                                >
                                                <InputNumber placeholder="percentage" />
                                                </Form.Item>
                                                <FontAwesomeIcon icon={faMinus} onClick={() => remove(name)} />
                                            </Space>
                                            ))}
                                            <Form.Item>
                                            <div className="addalbum-cont-form-section-feat-add-btn"  onClick={() => add()}>
                                                Add feats
                                            </div>
                                            </Form.Item>
                                        </>
                                        )}
                                    </Form.List>
                                </div>
                            }

                            <div className="addalbum-cont-form-section-item-file">
                                {fileMusic == null && 
                                    <div className="addalbum-cont-form-section-item-file-inner">
                                        <label>
                                            <span>+ Select music file</span>
                                            <input accept=".mp3" onChange={(e)=>selectFile(e)} type="file" required />
                                        </label>
                                    </div>
                                }
                                {fileMusic != null &&
                                    <div className="addalbum-cont-form-section-item-file-player">
                                        <div className="addalbum-cont-form-section-item-file-player-cover">
                                            {/* <img src={URL.createObjectURL(props.coverFile[0].originFileObj)} alt="cover" /> */}
                                        </div>
                                        <div className="addalbum-cont-form-section-item-file-player-main">
                                            <AudioPlayer
                                                src={URL.createObjectURL(fileMusic)}
                                            // other props here
                                            />
                                        </div>
                                    </div>
                                }
                            </div>

                        </div>

                        {fileMusic != null &&
                            <div className="addalbum-cont-form-footer">
                                <button className="addalbum-cont-form-footer-btn-next">Add Track</button>
                                <button type="button" onClick={()=>{setFileMusic(null)}} className="addalbum-cont-form-footer-btn-del">Delete</button>
                            </div>
                        }

                </Form>
            </div>
        </>    
    )
}

export default TrackAdd;
import React, { useEffect, useState } from "react" ;
import {ToastContainer} from "react-toastify";
import Toast_noty from '../../../../widget/Toast_noty'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import Loader from '../../../../widget/Loader/Loader';
import Table from "../../../../widget/Table/Table";
import CModal from '../../../../widget/CModal/CModal';
import {Form , Select , InputNumber , Space} from 'antd'
import {Get_All_Artists } from '../../../../widget/API';
import {Admin_Edit_MusicVideo_Fits} from '../../../../widget/API_Admin';


function MusicVideoDetailFeat(props) {
    const[dispaly , setDisplay]=useState(false);
    const [visibleEditFits , setVisibleEditFits]=useState(false);
    const [listArtists , setListArtists]=useState([])
    const [listFeats , setListFeats]=useState([])
    const {Option} = Select ;


    const col = [
        {
            title:"Name" ,
            render:(i) => (<>{i.name != null && i.name}</>)
        },
        {
            title:"Percentage" ,
            render:(i) => (<>{i.pivot != null && i.pivot.percentage	}</>)
        },
    ]

    const openModal=()=>{
        setDisplay(true);
        const returndata =  Get_All_Artists();
        returndata.then((res)=>{
        setDisplay(false);
        if(res.result == 'ok'){
            setListArtists(res.data)
            setVisibleEditFits(true)
        }
        })
    }


    const onfinishEditFits=(values )=>{
        if(values.feats || values.feats.length > 0){
            setDisplay(true)
            const returndata =  Admin_Edit_MusicVideo_Fits(values , props.musicVideo.id , props.musicVideo.artist_id);
            returndata.then((res)=>{
            setDisplay(false);
            if(res.result == 'ok'){
                setVisibleEditFits(false)
                props.getDetailMusiVideo()
            }
            })
        }
        else {
            Toast_noty("List empty", 5000, "error");
        }
    }

    // useEffect(()=>{
    //     if(props.musicVideo && props.musicVideo.feats){
    //         setListFeats(props.musicVideo.feats)
    //     }

    // },[])


    return (
        <>
            {dispaly && <Loader />}
            <CModal  visible={visibleEditFits} setVisible={setVisibleEditFits} title="EditFits"> 
                <div className="addmusicvideo-cont-form-section-feat">
                    <Form onFinish={onfinishEditFits}>                        
                        <Form.List name="feats">
                            {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, fieldKey, ...restField }) => (
                                <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                    <Form.Item
                                    {...restField}
                                    name={[name, 'artist_id']}
                                    fieldKey={[fieldKey, 'artist_id']}
                                    rules={[{ required: true, }]}
                                    >
                                        <Select
                                            placeholder="Select artist"
                                            allowClear
                                            >
                                                {listArtists.map((itemArtist , indexArtist)=>
                                                    <Option key={indexArtist} value={itemArtist.id}>{itemArtist.name}</Option>
                                                )}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                    {...restField}
                                    name={[name, 'percentage']}
                                    fieldKey={[fieldKey, 'percentage']}
                                    rules={[{ required: true}]}
                                    >
                                    <InputNumber placeholder="percentage" />
                                    </Form.Item>
                                    <FontAwesomeIcon icon={faMinus} onClick={() => remove(name)} />
                                </Space>
                                ))}
                                <button type="button" className="addmusicvideo-cont-form-section-feat-add-btn"  onClick={() => add()}>
                                    Add feats
                                </button>
                            </>
                            )}
                        </Form.List>
                        <button  style={{marginTop:"10px"}} className="addmusicvideo-cont-form-section-feat-add-btn">
                            Edit
                        </button>
                    </Form>
                </div>
            </CModal>
            <div className="fullPage">   
            <div className="pagelist">
                <div className="pagelist-head">
                    <span>Feats</span>
                    <button onClick={openModal} className="pagelist-head-button">Edit Feats</button>
                </div>
                {props.musicVideo && props.musicVideo.feats &&
                    <Table range={10} columns={col} data={props.musicVideo.feats} />  
                }
            </div>
            </div>  
        </>
    )
}

export default MusicVideoDetailFeat;
import React, { useEffect, useState } from "react" ;
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV , faEdit , faTrash , faUpload, faMinus} from "@fortawesome/free-solid-svg-icons";
import Toast_noty from '../../../../widget/Toast_noty'
import Loader from '../../../../widget/Loader/Loader'
import '../../manageFieldStyle.css';
import { Input , Select , Form , Space} from "antd";

import {Admin_Get_All_Init_Properties , Admin_Add_Peroperty} from '../../../../widget/API_Admin';





function PropertiesAdd(props) {
    const[display , setDisplay]=useState(false);
    const[listInit , setListInit]=useState(null);
    const[selectType , setSelectType]=useState(null)
    const[typeOfEnum , setTypeOfEnum]=useState(null)
    const {Option}=Select ;






    const onFinishAdd=(values)=>{
        setDisplay(true);
        const returndata =  Admin_Add_Peroperty(values);
        returndata.then(async(res)=>{
        setDisplay(false);
        if(res.result == 'ok'){
            await props.getData()
            props.setVisibleAdd(false)
            Toast_noty("success", 10000, "success");
        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
         returndata.catch((er)=>{
            setDisplay(false);
            Toast_noty('please try again', 5000, "error");
        })
    }





    const addInitValue=()=>{
        setDisplay(true)
        const returndata =  Admin_Get_All_Init_Properties();
        returndata.then((res)=>{
        setDisplay(false)
        if(res.result == 'ok'){
            setListInit(res.data)
        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
         returndata.catch((er)=>{
            Toast_noty('please try again', 5000, "error");
        })
    }


    useEffect(()=>{
        addInitValue()
    },[])


    return (
        <>
                {display && <Loader/>}
                    <Form onFinish={onFinishAdd} className="manageField-modal-form">

                        <div className="manageField-modal-form-item">
                            <span>name</span>
                            <Form.Item name="name" >
                                <Input type="text" rules={[{ required: true}]} />
                            </Form.Item>
                        </div>

                        <div className="manageField-modal-form-item">
                            <span>Type</span>
                            <Form.Item name="type" >
                                <Select
                                    onChange={(values)=>{setSelectType(values)}}
                                >
                                    {listInit != null && listInit.access_type.map(item=>
                                        <Option value={item}>{item}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </div>

                        {selectType == 'enum' &&                 
                            <div className="manageField-modal-form-item">
                                <span>Type of enum</span>
                                <Form.Item name="typeOfEnum" >
                                    <Select
                                        onChange={(values)=>{setTypeOfEnum(values)}}
                                    >
                                            <Option value={'table'}>Table</Option>
                                            <Option value={'multi'}>Multi Select</Option>
                                    </Select>
                                </Form.Item>
                            </div>
                        }

                        {typeOfEnum == 'table' && selectType == 'enum' &&                 
                            <div className="manageField-modal-form-item">
                                <span>Table</span>
                                <Form.Item name="table" >
                                    <Select>
                                        {listInit != null && listInit.tables.map(item=>
                                            <Option value={item}>{item.substring(11)}</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </div>
                        }

                        {typeOfEnum == 'multi' && selectType == 'enum' &&                   
                            <div className="manageField-modal-form-item">
                                <span>Custom item</span>
                                <Form.List
                                    name="multi"
                                    
                                >
                                    {(fields, { add, remove }) => (
                                    <>
                                        {fields.map((field, index) => (
                                        <Form.Item
                                            required={false}
                                            key={field.key}
                                        >
                                            <Form.Item
                                            {...field}
                                            validateTrigger={['onChange', 'onBlur']}
                                            rules={[{required: true,},
                                            ]}
                                            noStyle
                                            >
                                            <Input placeholder="Item name" style={{ width: '60%' }} />
                                            </Form.Item>
                                            {fields.length > 0 ? (
                                            <FontAwesomeIcon
                                                icon={faMinus}
                                                onClick={() => remove(field.name)}
                                            />
                                            ) : null}
                                        </Form.Item>
                                        ))}
                                        <Form.Item>
                                        <button
                                            type="button"
                                            onClick={() => add()}
                                            style={{ width: '60%' }}
                                        >
                                            Add field
                                        </button>
                                        </Form.Item>
                                    </>
                                    )}
                                </Form.List>
                            </div>
                        }
                        
                        {selectType == 'enum' &&  
                            <div className="manageField-modal-form-item">
                                <span>Select enum</span>
                                <Form.Item name="selectEnum" >
                                    <Select>
                                            <Option value={'single'}>Single</Option>
                                            <Option value={'multi'}>Multi</Option>
                                    </Select>
                                </Form.Item>
                            </div>
                        }

                        <div className="manageField-modal-form-item">
                            <span>Description</span>
                            <Form.Item name="description" >
                                <Input type="text" rules={[{ required: true}]} />
                            </Form.Item>
                        </div>
                        <div className="manageField-modal-form-item">
                            <button>Submit</button>
                        </div>
                    </Form>
        </>
    )
}

export default PropertiesAdd;
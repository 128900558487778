import React, { useEffect, useRef, useState } from "react" ;
import Toast_noty from '../../../widget/Toast_noty'
import Loader from "../../../widget/Loader/Loader";

import {Get_Album , Get_Album_Comment} from '../../../widget/API';
import AlbumDetailHead from './widget/AlbumDetailHead';
import { useLocation } from 'react-router-dom'
import { cssTransition } from "react-toastify";
import {Link} from 'react-router-dom';
import Table from "../../../widget/Table/Table";
import TablePage from '../../../widget/Table/TablePage';
import AlbumDetailComment from "./widget/AlbumDetailComment";
import AlbumDetailTrack from "./widget/AlbumDetailTrack";
import AlbumDetailViews from './widget/AlbumDetailViews';
import SkeletonSection from '../../../widget/skeleton/SkeletonSection'
import '../albumStyle.css';




function AlbumDetail(props) {
    const[dispaly , setDisplay]=useState(false);
    const [album , setAlbum]=useState(null);





    const getDetailTrack=()=>{
        if(props.match.params.id){
            // setDisplay(true)
            const returndata =  Get_Album(props.match.params.id);
            returndata.then((res)=>{
            setDisplay(false)
            if(res.result == 'ok'){
                setAlbum(res.data)
            }
            else if(res.error == 'unauthenticated') {
                localStorage.clear();
                props.history.push('/');  
            }
            else {
                Toast_noty(res.error, 5000, "error");
            }
            })
             returndata.catch((er)=>{
                setDisplay(false)
                Toast_noty('please try again', 5000, "error");
            })
        }
    }


    useEffect(()=>{
        getDetailTrack();
      },[])

    return (
        <div className="albumdetail">
            {dispaly && <Loader/>}
            {album == null && <SkeletonSection/>}
            {album == null && <SkeletonSection/>}
            {album != null && <AlbumDetailHead album={album} /> }
            {album != null && <AlbumDetailTrack album={album} getDetailTrack={getDetailTrack} />}
            {album != null &&  <AlbumDetailComment albumId={album.id} album={album}  />}
            {album != null &&  <AlbumDetailViews albumId={album.id} album={album}  />}

        </div>
    )
}

export default AlbumDetail;
import React, { useEffect, useState } from "react" ;
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import TablePage from "../../../../widget/Table/TablePage";
import {Admin_Get_All_Data , Admin_Change_Status_Data} from '../../../../widget/API_Admin';
import Toast_noty from '../../../../widget/Toast_noty'
import Loader from '../../../../widget/Loader/Loader'
import { Select , DatePicker, Form , Input ,InputNumber} from 'antd';
import CModal from '../../../../widget/CModal/CModal'
import { Link } from "react-router-dom";
import '../podcastStyle.css';
import moment from "moment";



function Podcast(props) {
    const[display , setDisplay]=useState(false);
    const[visibleReject , setVisibleReject]=useState(false);
    const [idAlbumRejected , setIdAlbumRejected]=useState(0);
    const[listData , setListData]=useState([]);
    const [currentPage , setCurrentPage]=useState(1);
    const [lastPage , setLastPage]=useState(0);
    const [sort , setSort]=useState(null);
    const [type , setType]=useState(null);
    const { Option } = Select;

    const changePage=(status)=>{
        if(status){
            if(lastPage >= currentPage + 1){
                getData(currentPage + 1 , sort , type)
            }
        }
        else {
            if(currentPage > 1){
                getData(currentPage - 1 , sort , type)
            }
        }
    }

    const filterList=async(value , item)=>{
        if(item == 'sort' ){
            await setSort(value)
            getData(currentPage , value , type)
        }
        else if (item == 'type'){
            await setType(value)
            getData(currentPage , sort , value)
        }
    }

    const selectStatusAlbum=async(value, id)=>{
        if(value != ""){
            if(value == 'rejected'){
                setVisibleReject(true);
                setIdAlbumRejected(id);
            }
            else {
                changeStatusAlbum(id , value);
            }
        }
    }


    const onFinishStatus=(e)=>{
        e.preventDefault();
        changeStatusAlbum(idAlbumRejected , 'rejected' , e.target[0].value)
    }

    const changeStatusAlbum=async(id ,typeOfStatus,reson)=>{
        setDisplay(true)
        const returndata =  Admin_Change_Status_Data(id , typeOfStatus , reson , 'Podcast');
        returndata.then(async(res)=>{
        setDisplay(false)
        if(res.result == 'ok'){
            setVisibleReject(false);
            await   getData(currentPage , sort , type)
            Toast_noty("Changes saved successfully", 10000, "success");
        }
        })
    }

    const colLike = [
        {
            title:"id" ,
            render:(i) => (<>{i.id != null && i.id}</>)
        },
        {
            title:"Name" ,
            render:(i) => (<Link to={`/panel/podcast/detail/${i.id}/${i.artist_id}`}>{i.name != null && i.name}</Link>)
        },
        {
            title:"Release Time" ,
            render:(i) => (<>{i.release_time != null && moment(i.release_time).format('YYYY-MM-DD hh:mm')}</>)
        },
        {
            title:"Status" ,
            render:(i) => (
                <>
                    {[ 
                        i.status == 'pending' && <div className="table-tag table-tag-1">pending</div> ,
                        i.status == 'rejected' && <div className="table-tag table-tag-2">rejected</div> ,
                        i.status == 'accepted' && <div className="table-tag table-tag-3">accepted</div> ,
                    ]}
                </>
            )
        },
        {
            title:"Action" ,
            render:(i) => (
            <select className="table-select" onChange={(e)=>selectStatusAlbum(e.target.value ,i.id)}>
                <option value={""}>--</option>
                <option value={"accepted"}>{"accepted"}</option>
                <option value={"rejected"}>{"rejected"}</option>
                <option value={"pending"}>{"pending"}</option>
            </select>
            )
        },
        {
            title:"Reject reason" ,
            render:(i) => (<>{i.reject_message != null && i.status == 'rejected' && i.reject_message}</>)
        },
    ]


    const getData=(current , sortBy , typeOf)=>{
        setDisplay(true)
        const returndata =  Admin_Get_All_Data(current , sortBy , typeOf , 'Podcast');
        returndata.then((res)=>{
        setDisplay(false)
        if(res.result == 'ok'){
            setListData(res.data.data)
            setCurrentPage(res.data.current_page)
            setLastPage(res.data.last_page)

        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
         returndata.catch((er)=>{
            Toast_noty('please try again', 5000, "error");
        })
    }


    useEffect(()=>{
        getData(currentPage)
    },[])


    return (
        <>
            <div className="search-list">
                <CModal visible={visibleReject} setVisible={setVisibleReject} title="Reson reject"> 
                    <form onSubmit={onFinishStatus} className="confirmation-modal-form">
                        <div className="confirmation-modal-form-item">
                            <span>Reson reject</span>
                            <input type="text" required />
                        </div>
                        <div className="confirmation-modal-form-item">
                            <button>Submit</button>
                        </div>
                    </form>
                </CModal>
                <div className="search-list-head"><span>Search</span></div>
                <div className="search-list-main">
                    <div className="search-list-main-item" >
                        <span>Sort by</span>
                        <Select
                            allowClear
                            onChange={(value)=>filterList(value , 'sort')}
                            >
                                <Option value={"name"}>{"name"}</Option>
                                <Option value={"cover_producer"}>{"cover producer"}</Option>
                                <Option value={"artist_name"}>{"artist name"}</Option>
                                <Option value={"gender_name"}>{"gender name"}</Option>
                                <Option value={"release_time"}>{"release time"}</Option>
                                <Option value={"created_at"}>{"created"}</Option>
                                <Option value={"updated_at"}>{"updated"}</Option>
                        </Select>
                    </div>
                    <div className="search-list-main-item" >
                        <span>Type</span>
                        <Select
                            allowClear
                            onChange={(value)=>filterList(value , 'type')}
                            >
                                <Option value={"accepted"}>{"accepted"}</Option>
                                <Option value={"rejected"}>{"rejected"}</Option>
                                <Option value={"pending"}>{"pending"}</Option>
                        </Select>
                    </div>
                </div>
            </div>
            <div className="fullPage">
                {display && <Loader/>}
                {listData.length > 0 && 
                <div className="pagelist">
                    <div className="pagelist-head">
                        <span>Podcast</span>
                        <Link to='/panel/podcast/add'><button  className="pagelist-head-button">Add Podcast </button></Link>
                    </div>
                    <TablePage columns={colLike} data={listData}>
                        {currentPage != 1 && 
                            <div className="table-counter-main-item" onClick={()=>changePage(false)} ><FontAwesomeIcon icon={faChevronLeft} /></div>
                        }
                            <div className="table-counter-main-item">{currentPage} </div>
                        {lastPage >= currentPage + 1 && 
                            <div className="table-counter-main-item" onClick={()=>changePage(true)} ><FontAwesomeIcon icon={faChevronRight} /></div>
                        }
                    </TablePage>
                </div>
                }
            </div> 
        </>
    )
}

export default Podcast;
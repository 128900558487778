import React, { useState , useEffect } from "react" ;
import { faMailBulk, faUser , faEye, faEyeSlash, faSign, faSignInAlt, faCode, faSortDown, faSortNumericDown } from "@fortawesome/free-solid-svg-icons";
import { faTelegram, faWhatsapp , faFacebook , faInstagram , faTwitter, faGoogle, faApple  } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from '../static/img/logo/m-n-logo.png'
import {ForgetPass} from '../widget/API';
import LoaderBtn from '../widget/Loader/LoaderBtn';
import {ToastContainer} from "react-toastify";
import Toast_noty from '../widget/Toast_noty';
import { Link } from "react-router-dom";
import './authStyle.css';

function Forget(props) {
    const [display , setDisplay]=useState(false);





    const forgetpassword=async(e)=>{
        e.preventDefault();
        setDisplay(true);
        sessionStorage.setItem('veri_email' ,e.target[0].value  )
        const returndata =  ForgetPass(e);
        returndata.then((res)=>{
        setDisplay(false)
        if(res.result == 'ok'){
            props.history.push('/verifyforget');  
        }
        else 
        {
            Toast_noty(res.error, 5000, "error");
        }
        })
        returndata.catch((er)=>{
            setDisplay(false);
            Toast_noty('please try again', 5000, "error");
        })
    }



    return (
        <div className="auth" >
            <div className="auth-main">
                <img className="auth-main-logo" src={Logo} alt="logo" />
                {/* <h1>Login</h1> */}
                <form onSubmit={forgetpassword}>
                    <div className="auth-main-item">
                        <span>Email</span>
                        <div className="auth-main-item-inner">
                            <input type="email" placeholder="Email" required/>
                            <div className="auth-main-item-inner-icon">
                                <FontAwesomeIcon icon={faMailBulk} />
                            </div>
                        </div>    
                    </div>
                        {display ?
                            <button className="auth-main-btn-after-submit" disabled>
                                <LoaderBtn />
                            </button>
                        : 
                            <button>
                                <FontAwesomeIcon icon={faSignInAlt} />
                                <span>Verify</span>
                            </button>
                        }
                </form>
                {/* <div className="auth-main-social auth-main-social-fb">
                    <div className="auth-main-social-icon">
                        <FontAwesomeIcon icon={faFacebook} />
                    </div>
                    <span>Login with FaceBook</span>
                </div> */}
                {/* <div className="auth-main-social">
                    <div className="auth-main-social-icon">
                        <FontAwesomeIcon icon={faGoogle} />
                    </div>
                    <span>Login with Google</span>
                </div> */}
                {/* <div className="auth-main-social">
                    <div className="auth-main-social-icon">
                        <FontAwesomeIcon icon={faApple} />
                    </div>
                    <span>Login with Apple</span>
                </div> */}
                <div className="auth-main-social">
                    <div className="auth-main-social-icon">
                        <FontAwesomeIcon icon={faUser} />
                    </div>
                    <span>Login</span>
                    <Link to="/" />
                </div>
            </div>
            <ToastContainer position="top-right"
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={true}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    )
}

export default Forget;
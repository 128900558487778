import { faCheck, faUser , faChevronRight , faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react" ;
import {ToastContainer} from "react-toastify";
import Loader from '../../../../widget/Loader/Loader';
import Toast_noty from "../../../../widget/Toast_noty";
import {Image, Input , Form , Select} from 'antd'
import CModal from '../../../../widget/CModal/CModal';
import {Admin_Add_Dynamic_List , Admin_Get_All_Dynamic_List} from '../../../../widget/API_Admin';
import Placeholder from '../../../../static/img/placeholder.png'

import {BASE_Medai_URL} from '../../../../widget/BASE_Medai_URL'
import { Redirect} from "react-router-dom";



function AddDynamicAlbumResult(props) {
    const[dispaly , setDisplay]=useState(false);
    const[visible , setVisible]=useState(false);
    const[listData , setListData]=useState([]);
    const[listAllData , setListAllData]=useState([]);
    const[listSelect , setListSelect]=useState([]);
    const[current , setCurrent]=useState(1);
    const [redirect , setRedirect]=useState(false)
    const [activeTab , setActiveTab]=useState(0);
    const[lastDaynamicList , setLastDaynamicList]=useState([])
    const[dynamicListSelect , setDynamicListSelect]=useState(null)
    const {Option} = Select ;




    const checkItemExistInList=(id , type)=>{
        for(let j in listSelect){
            if(listSelect[j].data_id == id && listSelect[j].data_type == type){
                return true
            }
        }
        return false ;
    }


    const selectAll=()=>{
        if(listSelect.length == listData.length){
            setListSelect([])
        }
        else {
            let listselect = [] ;
            for(let i of listData){
                listselect.push({
                    'data_id': i.id ,
                    'data_type':i.type_model ,
                })
            }
            setListSelect(listselect)
        }
        
    }

    const changeExistList=(id , type)=>{
        let mylist = [...listSelect] ;
        for(let j in mylist){
            if(mylist[j]['data_id'] == id && mylist[j]['data_type'] == type){
                mylist.splice(j , 1);
                setListSelect(mylist)
                return
            }
        }
            mylist.push({
                    'data_id': id ,
                    'data_type':type ,
            })
            setListSelect(mylist)
    }


    const showMore=()=>{
        for(let i=listData.length  ; i<(current + 1)*12 ; i++){
            if(listAllData[i]){
                setListData(prev=>[...prev , listAllData[i]])
            }
        }
        setCurrent(current + 1)
    }


    const onFinishAdd=(values)=>{
        if(listSelect.length > 0){
            setDisplay(true);
            setVisible(false)
            const returndata =  Admin_Add_Dynamic_List(values , listSelect , 'album' , activeTab , dynamicListSelect);
            returndata.then(async(res)=>{
            setDisplay(false);
            if(res.result == 'ok'){
                Toast_noty("success", 10000, "success");
                setRedirect(true)
            }
            else if(res.error == 'unauthenticated') {
                localStorage.clear();
                props.history.push('/');  
            }
            else {
                Toast_noty(res.error, 5000, "error");
            }
            })
            returndata.catch((er)=>{
                setDisplay(false);
                Toast_noty('please try again', 5000, "error");
            })
        }
        else{
            Toast_noty('not select', 5000, "error");
        }
    }


    const getData=()=>{
        setDisplay(true)
        const returndata =  Admin_Get_All_Dynamic_List('album');
        returndata.then((res)=>{
        setDisplay(false)
        if(res.result == 'ok'){
            setLastDaynamicList(res.data)
        }
        })
    }


    useEffect(()=>{
        if(props.resultFilter){
            setListAllData(props.resultFilter);
            setListData(props.resultFilter.slice(0 , 12))
            getData()
        }
    },[props.resultFilter])

    return (
        <>
        {dispaly && <Loader/>}
        {redirect && <Redirect to={'/panel/dynamic/all/list'} />}
        <CModal visible={visible} setVisible={setVisible} title="Add"> 
            <div className="dynamic-modal-tab">
                <div onClick={()=>{setActiveTab(0)}} className={`dynamic-modal-tab-item ${activeTab == 0 && 'dynamic-modal-tab-item-active'}`}>New List</div>
                <div onClick={()=>{setActiveTab(1)}} className={`dynamic-modal-tab-item ${activeTab == 1 && 'dynamic-modal-tab-item-active'}`}>Add to old</div>
            </div>

            {activeTab == 0 && 
                <Form onFinish={onFinishAdd} className="dynamic-modal-form" style={{marginTop:"20px"}}>
                    <div className="dynamic-modal-form-item">
                        <span>name</span>
                        <Form.Item name="name" rules={[{ required: true}]}>
                            <Input />
                        </Form.Item>
                    </div>
                    <div className="dynamic-modal-form-item">
                        <button>Create new</button>
                    </div>
                </Form>
            }

            {activeTab == 1 && 
                <Form onFinish={onFinishAdd} className="dynamic-modal-form" style={{marginTop:"20px"}}>
                    <div className="dynamic-modal-form-item">
                        <span>select list</span>
                        <Form.Item name="list" rules={[{ required: true}]}>
                            <Select  onChange={(value)=>{setDynamicListSelect(value)}}>
                                {lastDaynamicList.map(item=>
                                    <Option value={item.id}>{item.name}</Option>
                                )}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="dynamic-modal-form-item">
                        <button>Add to old</button>
                    </div>
                </Form>
            }

        </CModal>

        <div className={`dynamic-result ${props.resultFilter.length > 0 && 'dynamic-result-active'}`}>

            <div className="dynamic-result-head">
                <button onClick={selectAll}>select all</button>
                <button onClick={()=>{setVisible(true)}}>Add List</button>
            </div>
            {listData.map((item , index)=>
                <div onClick={()=>changeExistList(item.id , item.type_model)} className="dynamic-result-active-item">
                    <div className="dynamic-result-active-item-inner">
                        <div className="dynamic-result-active-item-inner-head">
                                <div>
                                    <Image 
                                        src={`${BASE_Medai_URL}/storage/cover/artist/${item.artist_id}/album/${item.id}`}
                                        fallback = {Placeholder}
                                        preview={false}
                                    />
                                </div>
                            {checkItemExistInList(item.id ,  item.type_model) ?
                                <div className="dynamic-result-active-item-inner-head-icon dynamic-result-active-item-inner-head-icon-active">
                                    <FontAwesomeIcon icon={faCheck} />
                                </div>    
                                :
                                <div className="dynamic-result-active-item-inner-head-icon">
                                    <FontAwesomeIcon icon={faCheck} />
                                </div> 
                            }
                        </div>
                        <div className="dynamic-result-active-item-inner-des">
                            <h3>{item.name}</h3>
                            <span>{item.type_model.substring(11)}</span>
                        </div>
                    </div>
                </div>
            )}

            {listAllData.length > listData.length && 
                <div className="pagining">
                    <div className="pagining-inner">
                            <div onClick={showMore} className="pagining-inner-more">Show more</div>
                    </div>
                </div>
            }
        {/* <button onClick={()=>{console.log(listSelect);}}>fff</button> */}
        </div>
        </>
    )
}

export default AddDynamicAlbumResult;
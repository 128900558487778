import React, { useEffect, useRef, useState } from "react" ;
import '../dynamicStyle.css';
import {Select} from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar , faHeart , faMusic, faTrash} from "@fortawesome/free-solid-svg-icons";
import Loader from "../../../widget/Loader/Loader";
import Toast_noty from "../../../widget/Toast_noty";
import {Admin_Get_All_Dynamic_List , Admin_Delete_Dynamic_List , Admin_Delete_Item_From_List} from '../../../widget/API_Admin'
import Placeholder from '../../../static/img/placeholder.png'
import CModal from "../../../widget/CModal/CModal";
import Flickity from 'react-flickity-component';
import Profile from '../../../static/img/profile.png'
import {Image} from 'antd'
import {BASE_Medai_URL} from '../../../widget/BASE_Medai_URL'
import {Link} from 'react-router-dom'








function DynamicAllList(props) {
    const[dispaly , setDisplay]=useState(false);
    const[listData , setListData]=useState([])
    const[visibleDelete , setVisibleDelete]=useState(false);
    const[deleteId , setDeleteId]=useState(false);
    const[listType , setListType]=useState('all')
    const[visibleDeleteItem , setVisibleDeleteItem]=useState(false);
    const[deleteItemId , setDeleteItemId]=useState(null);

    const {Option} = Select ;

    const flickityOptions = {
        initialIndex: 22,
        pageDots: false,
        accessibility: true,
        contain: true,
        wrapAround: true
    }

    const filterList=(values)=>{
        setListType(values)
        getData(values)
        
    }


    const openDeleteItemModal=(id)=>{
        setVisibleDeleteItem(true)
        setDeleteItemId(id)
    }

    const onFinishDeleteItem=()=>{
        setDisplay(true)
        const returndata =  Admin_Delete_Item_From_List(deleteItemId);
        returndata.then((res)=>{
        setDisplay(false)
        if(res.result == 'ok'){
            getData(listType)
            Toast_noty('success', 5000, "success");
            setVisibleDeleteItem(false)
        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
         returndata.catch((er)=>{
            Toast_noty('please try again', 5000, "error");
        })
    }

    const openDeleteModal=(id)=>{
        setVisibleDelete(true)
        setDeleteId(id)
    }
    

    const onFinishDeleteList=()=>{
        setDisplay(true)
        const returndata =  Admin_Delete_Dynamic_List(deleteId);
        returndata.then((res)=>{
        setDisplay(false)
        if(res.result == 'ok'){
            getData(listType)
            Toast_noty('success', 5000, "success");
            setVisibleDelete(false)
        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
         returndata.catch((er)=>{
            Toast_noty('please try again', 5000, "error");
        })
    }


    const getData=(listName)=>{
        setDisplay(true)
        setListData([])
        const returndata =  Admin_Get_All_Dynamic_List(listName);
        returndata.then((res)=>{
        setDisplay(false)
        if(res.result == 'ok'){
            setListData(res.data)
        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
         returndata.catch((er)=>{
            Toast_noty('please try again', 5000, "error");
        })
    }


    useEffect(()=>{
        getData(listType)
    },[])


    return (
        <>
            {dispaly && <Loader/>}

            <CModal visible={visibleDelete} setVisible={setVisibleDelete} title="Delete"> 
                <p>Are you sure delete list ?</p>
                <div className="CModel-Bottom">
                    <button className="CModel-Bottom-btn-ok" onClick={onFinishDeleteList} >Ok</button>
                    <button className="CModel-Bottom-btn-cancel" onClick={()=>{setVisibleDelete(false)}}>Cansel</button>
                </div>
            </CModal>

            <CModal visible={visibleDeleteItem} setVisible={setVisibleDeleteItem} title="DeleteItem"> 
                <p>Are you sure delete item from list ?</p>
                <div className="CModel-Bottom">
                    <button className="CModel-Bottom-btn-ok" onClick={onFinishDeleteItem} >Ok</button>
                    <button className="CModel-Bottom-btn-cancel" onClick={()=>{setVisibleDeleteItem(false)}}>Cansel</button>
                </div>
            </CModal>

            <div className="dynamic-bar">
                <div className="dynamic-bar-inner">
                    <div className="dynamic-bar-inner-head">Filters</div>
                    <div className="dynamic-bar-inner-main">
                        <div onClick={()=>{filterList('all')}} className={`dynamic-bar-inner-main-item ${listType == 'all' && 'dynamic-bar-inner-main-item-active'} `}>All</div>
                        <div onClick={()=>{filterList('album')}} className={`dynamic-bar-inner-main-item ${listType == 'album' && 'dynamic-bar-inner-main-item-active'} `}>Album</div>
                        <div onClick={()=>{filterList('music_video')}} className={`dynamic-bar-inner-main-item ${listType == 'music_video' && 'dynamic-bar-inner-main-item-active'} `}>Music Video</div>
                        <div onClick={()=>{filterList('podcast')}} className={`dynamic-bar-inner-main-item ${listType == 'podcast' && 'dynamic-bar-inner-main-item-active'} `}>Podcast</div>
                        <div onClick={()=>{filterList('movie')}} className={`dynamic-bar-inner-main-item ${listType == 'movie' && 'dynamic-bar-inner-main-item-active'} `}>Movies</div>
                        <div onClick={()=>{filterList('series')}} className={`dynamic-bar-inner-main-item ${listType == 'series' && 'dynamic-bar-inner-main-item-active'} `}>Series</div>
                        <div onClick={()=>{filterList('artist')}} className={`dynamic-bar-inner-main-item ${listType == 'artist' && 'dynamic-bar-inner-main-item-active'} `}>Artist</div>

                    </div>
                </div>
            </div>


            {listData.map(item=>
                <div className="dynamic-tab">
                    <div className="dynamic-tab-head">
                        <span>{item.name}</span>
                        <div className="dynamic-tab-head-sec">

                            {item.type == 'all' && 
                                <Link to={'/panel/dynamic/all/add' } >
                                    <button className="dynamic-tab-head-sec-btn-2">Add item</button>
                                </Link>
                            }

                            {item.type == 'album' && 
                                <Link to={'/panel/dynamic/album/add' } >
                                    <button className="dynamic-tab-head-sec-btn-2">Add item</button>
                                </Link>
                            }

                            {item.type == 'podcast' && 
                                <Link to={'/panel/dynamic/podcast/add' } >
                                    <button className="dynamic-tab-head-sec-btn-2">Add item</button>
                                </Link>
                            }

                            {item.type == 'music_video' && 
                                <Link to={'/panel/dynamic/musicvideo/add' } >
                                    <button className="dynamic-tab-head-sec-btn-2">Add item</button>
                                </Link>
                            }

                            {item.type == 'artist' && 
                                <Link to={'/panel/dynamic/artist/add' } >
                                    <button className="dynamic-tab-head-sec-btn-2">Add item</button>
                                </Link>
                            }

                            {item.type == 'movie' && 
                                <Link to={'/panel/dynamic/movie/add' } >
                                    <button className="dynamic-tab-head-sec-btn-2">Add item</button>
                                </Link>
                            }

                            {item.type == 'series' && 
                                <Link to={'/panel/dynamic/series/add' } >
                                    <button className="dynamic-tab-head-sec-btn-2">Add item</button>
                                </Link>
                            }

                            <button onClick={()=>openDeleteModal(item.id)} className="dynamic-tab-head-sec-btn-1">Delete List</button>

                        </div>
                    </div>
                    <div className="dynamic-tab-cont">
                            <Flickity
                                className={'carousel'} // default ''
                                elementType={'div'} // default 'div'
                                options={flickityOptions} // takes flickity options {}
                                disableImagesLoaded={false} // default false
                                reloadOnUpdate // default false
                                static // default false
                            >
                                {item.data.map(items=>
                                    <div className="dynamic-tab-cont-item">
                                        {items.data && 
                                            <div className="dynamic-tab-cont-item-head">

                                                {items.data_type.substring(11) == "Album" && 
                                                    <div>
                                                        <Image 
                                                            src={`${BASE_Medai_URL}/storage/cover/artist/${items.data.artist_id}/album/${items.data.id}`}
                                                            fallback = {Placeholder}
                                                            preview={false}
                                                        />
                                                    </div>
                                                }

                                                {items.data_type.substring(11) == "Artist" && 
                                                    <div>
                                                        <Image 
                                                            style={{borderRadius : "100%"}}
                                                            src={`${BASE_Medai_URL}/storage/avatar/artist/${items.data.id}/profile`}
                                                            fallback = {Profile}
                                                            preview={false}
                                                        />
                                                    </div>
                                                }

                                                {items.data_type.substring(11) == "Podcast" && 
                                                    <div >
                                                        <Image 
                                                            src={`${BASE_Medai_URL}/storage/cover/artist/${items.data.artist_id}/podcast/${items.data.id}`}
                                                            fallback = {Placeholder}
                                                            preview={false}
                                                        />
                                                    </div>
                                                }

                                                {items.data_type.substring(11) == "MusicVideo" && 
                                                    <div >
                                                        <Image 
                                                            src={`${BASE_Medai_URL}/storage/cover/artist/${items.data.artist_id}/music_video/${items.data.id}`}
                                                            fallback = {Placeholder}
                                                            preview={false}
                                                        />
                                                    </div>
                                                }

                                                {items.data_type.substring(11) == "Movie" && 
                                                    <div >
                                                        <Image 
                                                            src={`${BASE_Medai_URL}/storage/movies/covers/${items.data.id}`}
                                                            fallback = {Placeholder}
                                                            preview={false}
                                                        />
                                                    </div>
                                                }

                                                {items.data_type.substring(11) == "Series" && 
                                                    <div >
                                                        <Image 
                                                            src={`${BASE_Medai_URL}/storage/series/covers/${items.data.id}`}
                                                            fallback = {Placeholder}
                                                            preview={false}
                                                        />
                                                    </div>
                                                }

                                            </div>
                                        }
                                        
                                        <div className="dynamic-tab-cont-item-des">
                                            {items.data && <h3>{items.data.name}</h3> }
                                            {items.data && <span>{items.data_type.substring(11)}</span> }
                                        </div>
                                        <div className="dynamic-tab-cont-item-bottom">
                                            {items.data &&
                                                <button onClick={()=>openDeleteItemModal(items.id)}>Delete</button>
                                            }
                                        </div>
                                    </div>
                                )}

                                
                            </Flickity>
                    </div>
                </div>
           )}
            
        </>
    )
}

export default DynamicAllList;
import React, { useState } from "react" ;
import {Admin_Get_Movies} from './API_Admin';
import ReactPlayer from 'react-player/lazy'

function FileMovies(props) {
    const [film , setFilm]=useState(null);



    React.useEffect(() => {
        if(props.movies){
            const returndata =  Admin_Get_Movies(props.movies);
            returndata.then((res)=>{
                setFilm(res)
            })
        }
        
    }, [props.movies])



    return (
        <video  controls>
            {film != null && 
                <source src={film} type="video/mp4" />
            }
        </video>

    )
}

export default FileMovies;
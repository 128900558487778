import React, { useEffect, useState } from "react" ;
import {ToastContainer} from "react-toastify";
import Toast_noty from '../../../../widget/Toast_noty'
import Loader from "../../../../widget/Loader/Loader";
import {Admin_Add_Series_two , Admin_Get_All_Episode , Admin_Sereas_Add_Ads} from '../../../../widget/API_Admin';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faUpload  } from "@fortawesome/free-solid-svg-icons";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import {Redirect} from 'react-router-dom';
import SeriesAddStepTwoListEpisode from './SeriesAddStepTwoListEpisode'
import { Select , TimePicker, Form , Input ,InputNumber} from 'antd';
import moment from "moment";




function SeriesAddStepTwo(props) {
    const[listEpisode , setListEpisode]=useState([]);
    const[dispaly , setDisplay]=useState(false);
    const[fileEpisode , setFileEpisode]=useState(null);
    const[name , setName]=useState(null);
    const [duration , setDuration]=useState(0);
    const [adsList , setAdsList]=useState([{
        'time':"" ,
        "file":"" ,
        "duration":"",
        "ads_duration":"",
    }])


    const selectFileAds=(e, id)=>{
        let listAds = [...adsList] ;
        let video = document.createElement('video');
        let fileURL = URL.createObjectURL(e);
        video.src = fileURL ;
        video.ondurationchange = function() {
            listAds[id]['ads_duration']= parseInt(this.duration) ;
        };
        listAds[id]['file']= e ;
        setAdsList(listAds)
    }

    const selectTimeAds=(e , id)=>{
        if(e != null){
            let listAds = [...adsList] ;
            listAds[id]['time']=moment(e._d).format('hh:mm:ss') ;
            listAds[id]['duration']=moment(e._d , 'HH:mm:ss').diff(moment().startOf('day'), 'seconds');
            setAdsList(listAds)
        }
    }

    const incrementAds=()=>{
        setAdsList(prev=>[...prev , {'time':"" ,"file":""}])
    }

    const DecreaseAds=(index)=>{
        let listAds = [...adsList] ;
        listAds.splice(index , 1);
        setAdsList(listAds)
    }




    const selectFile= async(e)=>{
        await setFileEpisode(e.target.files[0])
        let video = document.createElement('video');
        let fileURL = URL.createObjectURL(e.target.files[0]);
        video.src = fileURL ;
        video.ondurationchange = function() {
            setDuration(parseInt(this.duration));
        };
    }


    const getEpisodeList=()=>{
        const returndata =  Admin_Get_All_Episode(props.seriesId);
        returndata.then((res)=>{
        if(res.result == 'ok'){
            setListEpisode(res.data);
        }
        })
    }



    const onFinish=(values)=>{
        setDisplay(true);
        const returndata =  Admin_Add_Series_two(props.seriesId ,values , fileEpisode );
        returndata.then((res)=>{
        setDisplay(false)
        if(res.result == 'ok'){
            setFileEpisode(null);
            setDuration(0)
            document.getElementById('addseries-cont-form').reset()
            // Toast_noty('Add episode success', 5000, "success");
            onFinishAddAds(props.seriesId , res.data.id )
        }
        else 
        {
            Toast_noty(res.error, 5000, "error");
        }
        })
        returndata.catch((er)=>{
            setDisplay(false);
            Toast_noty('please try again', 5000, "error");
        })
    }


    const onFinishAddAds=(seriesId , episodeId)=>{
        setDisplay(true);
        const returndata =  Admin_Sereas_Add_Ads(seriesId , episodeId , adsList);
        returndata.then((res)=>{
        setDisplay(false);
        if(res.result == 'ok'){
            Toast_noty("Success", 5000, "seuccess");
            getEpisodeList()
        }
        })
    }


    const emptyFields=()=>{
        setFileEpisode(null)
        setAdsList([{
            'time':"" ,
            "file":"" ,
            "duration":"",
            "ads_duration":"",
        }])
    }


    return (
        <>
            <div className="addseries-cont">
                {dispaly && <Loader/>}
                {/* <div className="addalbum-cont-head">
                    <h3>Add Track</h3>
                </div> */}
                <Form onFinish={onFinish} id="addseries-cont-form"  className="addseries-cont-form">
                        <div className="addseries-cont-form-section">

                            <div className="addseries-cont-form-section-item">
                                <span>Description</span>
                                <Form.Item name="description" rules={[{ required: true}]}>
                                    <Input />
                                </Form.Item>
                            </div>

                            <div className="addmovies-cont-form-section-item">
                                <span>Duration</span>
                                <Form.Item name="duration" rules={[{ required: true}]}>
                                    <TimePicker />
                                </Form.Item>
                            </div>

                            <div className="addseries-cont-form-section-item">
                                <span>Episode Number</span>
                                <Form.Item  name="episode_number" rules={[{ required: true}]}>
                                    <InputNumber />
                                </Form.Item>
                            </div>

                            <div className="addseries-cont-form-section-item">
                                <span>Season Number</span>
                                <Form.Item  name="season_number" rules={[{ required: true}]}>
                                    <InputNumber />
                                </Form.Item>
                            </div>

                            <div className="addseries-cont-form-section-item-file">
                                {fileEpisode == null && 
                                    <div className="addseries-cont-form-section-item-file-inner">
                                        <label>
                                            <span>+ Select episode file</span>
                                            <input  accept="video/*"  onChange={(e)=>setFileEpisode(e.target.files[0])} type="file" required />
                                        </label>
                                    </div>
                                }
                                {fileEpisode != null &&
                                    <>
                                    {fileEpisode.type == "video/mp4" ? 
                                        <div className="addseries-cont-form-section-item-file-player">
                                            <div className="addseries-cont-form-section-item-file-player-cover">
                                                <img src={URL.createObjectURL(props.coverFile[0].originFileObj)} alt="cover" />
                                                <span>{props.seriesName}</span>
                                            </div>
                                            <div className="addseries-cont-form-section-item-file-player-main">
                                                <video  controls>
                                                    <source src={URL.createObjectURL(fileEpisode)} type="video/mp4" />
                                                </video> 
                                            </div>
                                        </div>
                                    :
                                        <div className="addseries-cont-form-section-item-file-inner">
                                            <label>
                                                <span>{fileEpisode.name}</span>
                                            </label>
                                        </div>
                                    }
                                    </>
                                }
                            </div>

                            {fileEpisode != null &&
                            <div className="addseries-cont-form-section-ads">
                                <div className="addseries-cont-form-section-ads-inner">
                                    {adsList.map((items , indexs)=>
                                        <div key={indexs} className="addseries-cont-form-section-ads-item">
                                            <div className="addseries-cont-form-section-ads-item-sec">
                                                <label>
                                                    <input onChange={(e)=>{selectFileAds(e.target.files[0] , indexs)}} type="file" accept="video/*"  required/>
                                                    <span>{items.file.name ? items.file.name : "Select ads file"}</span>
                                                </label>
                                            </div>
                                            <div className="addseries-cont-form-section-ads-item-sec">
                                                <TimePicker defaultValue={items.time} onChange={(e)=>{selectTimeAds(e , indexs)}} required/>
                                            </div>
                                            <FontAwesomeIcon onClick={()=>DecreaseAds(indexs)} icon={faMinus} />
                                        </div>
                                    )}
                                    <div className="addseries-cont-form-section-ads-plus" onClick={incrementAds} >Add Advertising</div>
                                </div>
                            </div>
                            }

                        </div>
                        {fileEpisode != null &&
                            <div className="addseries-cont-form-footer">
                                <button className="addseries-cont-form-footer-btn-next">Upload Episode</button>
                                <button type="button" onClick={emptyFields} className="addseries-cont-form-footer-btn-del">Delete</button>
                            </div>
                        }
                </Form>
            </div>
            {listEpisode.length > 0 && 
                <SeriesAddStepTwoListEpisode seriesId={props.seriesId} listEpisode={listEpisode} />
            }
        </>    
    )
}

export default SeriesAddStepTwo;
import React, { useEffect, useRef, useState } from "react" ;
import Toast_noty from '../../../widget/Toast_noty'
import Loader from "../../../widget/Loader/Loader";

import { Admin_Get_Movies_Single} from '../../../widget/API_Admin';
import {Link} from 'react-router-dom';
import MoviesDetailHead from "./widget/MoviesDetailHead";
import MoviesDetailCont from './widget/MoviesDetailCont';
import MoviesDetailAds from './widget/MoviesDetailAds';
import '../moviesStyle.css';


function MoviesDetail(props) {
    const[dispaly , setDisplay]=useState(false);
    const [movies , setMovies]=useState(null);





    const getDetailMovies=()=>{
        if(props.match.params.id){
            setDisplay(true)
            const returndata =  Admin_Get_Movies_Single(props.match.params.id);
            returndata.then((res)=>{
            setDisplay(false)
            if(res.result == 'ok'){
                setMovies(res.data)
            }
            else if(res.error == 'unauthenticated') {
                localStorage.clear();
                props.history.push('/');  
            }
            else {
                Toast_noty(res.error, 5000, "error");
            }
            })
             returndata.catch((er)=>{
                setDisplay(false)
                Toast_noty('please try again', 5000, "error");
            })
        }
    }


    useEffect(()=>{
        getDetailMovies();
      },[])

    return (
        <div className="moviesdetail">
            {dispaly && <Loader/>}
            {movies != null && <MoviesDetailHead movies={movies} /> }
            <MoviesDetailCont movies={movies} />
            <MoviesDetailAds getDetailMovies={getDetailMovies} movies={movies}/>
        </div>
    )
}

export default MoviesDetail;
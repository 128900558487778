import React, { useEffect, useState } from "react" ;
import {ToastContainer} from "react-toastify";
import Toast_noty from '../../../widget/Toast_noty'
import Loader from "../../../widget/Loader/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faUpload  } from "@fortawesome/free-solid-svg-icons";
import { Select , DatePicker, Space , Upload , Form , Input, TimePicker} from 'antd';
import ImgCrop from 'antd-img-crop';
import moment from "moment";
import { Redirect } from "react-router-dom";
import {Admin_Add_Movies , Admin_Movies_Add_Ads} from '../../../widget/API_Admin';
import MoviesAutoFields from '../MoviesAutoFields';
import '../moviesStyle.css';




function MoviesAdd(props) {
    const[dispaly , setDisplay]=useState(false);
    const[redirect , setRedirect]=useState(false);
    const[fileList , setFileList]=useState([]);
    const[fileMovies , setFileMovies]=useState(null);
    const [duration , setDuration]=useState(0);
    const [adsList , setAdsList]=useState([{
        'time':"" ,
        "file":"" ,
        "duration":"",
        "ads_duration":"",
    }])


    // Function For Upload Handel

    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
        };
    
    const onPreview = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
        };

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
        };

    // End Function For Upload 



    const selectFileAds=(e, id)=>{
        let listAds = [...adsList] ;
        let video = document.createElement('video');
        let fileURL = URL.createObjectURL(e);
        video.src = fileURL ;
        video.ondurationchange = function() {
            listAds[id]['ads_duration']= parseInt(this.duration) ;
        };
        listAds[id]['file']= e ;
        setAdsList(listAds)
    }

    const selectTimeAds=(e , id)=>{
        if(e != null){
            let listAds = [...adsList] ;
            listAds[id]['time']=moment(e._d).format('hh:mm:ss') ;
            listAds[id]['duration']=moment(e._d , 'HH:mm:ss').diff(moment().startOf('day'), 'seconds');
            setAdsList(listAds)
        }
    }

    const incrementAds=()=>{
        setAdsList(prev=>[...prev , {'time':"" ,"file":""}])
    }

    const DecreaseAds=(index)=>{
        let listAds = [...adsList] ;
        listAds.splice(index , 1);
        setAdsList(listAds)
    }

    const selectFile= async(e)=>{
        await setFileMovies(e.target.files[0])
        let video = document.createElement('video');
        let fileURL = URL.createObjectURL(e.target.files[0]);
        video.src = fileURL ;
        video.ondurationchange = function() {
            setDuration(parseInt(this.duration));
        };
    }



    const onFinish=(values)=>{
        if(fileList.length != 0 && fileMovies != null){
            if(values && values.labels){
                values['labels'] =  Object.entries(values.labels).map(([key , value])=>{
                    if(value && value._d){
                        return {
                            "id" : key,
                            "value" : String(moment(value._d).format('YYYY-MM-DD hh:mm')),
                        }
                    }
                    else {
                        return {
                            "id" : key,
                            "value" : String(value),
                        }
                    }
                    
                })
            }
            setDisplay(true);
            const returndata =  Admin_Add_Movies(values , fileList , fileMovies );
            returndata.then((res)=>{
            setDisplay(false)
            if(res.result == 'ok'){
                onFinishAddAds(res.data.id)
            }
            else 
            {
                Toast_noty(res.error, 5000, "error");
            }
            })
            returndata.catch((er)=>{
                setDisplay(false);
                Toast_noty('please try again', 5000, "error");
            })
        }
        else {
            Toast_noty('please select video and cover', 5000, "error");

        }
    }

    const onFinishAddAds=(moviesId)=>{
        setDisplay(true);
        const returndata =  Admin_Movies_Add_Ads(moviesId , adsList);
        returndata.then((res)=>{
        setDisplay(false);
        if(res.result == 'ok'){
            Toast_noty("Success", 5000, "seuccess");
            setRedirect(true)
        }
        })
    }


    const emptyFields=()=>{
        setFileMovies(null)
        setAdsList([{
            'time':"" ,
            "file":"" ,
            "duration":"",
            "ads_duration":"",
        }])
    }


    return (
        <div className="moviesdetail">
            {dispaly && <Loader/>}
            {redirect && <Redirect to='/panel/movies/list' />}
            <div className="addmovies-cont">
                <Form onFinish={onFinish} className="addmovies-cont-form">
                    <div className="addmovies-cont-form-section">

                        <div className="addmovies-cont-form-section-cover-head">
                            <div className="addmovies-cont-form-section-cover-head-inner">
                                <ImgCrop>
                                    <Upload
                                    className="mt-2"
                                    accept=".png , .jpg , .jpeg , .pdf "
                                    listType="picture-card"
                                    fileList={fileList}
                                    onChange={onChange}
                                    onPreview={onPreview}
                                    customRequest={dummyRequest}
                                    showUploadList={{showPreviewIcon:false}}
                                    >
                                        {fileList.length < 1 &&
                                            <div className="addmovies-cont-form-section-cover-head-inner-upload">
                                                <span>Cover</span>
                                                <FontAwesomeIcon icon={faUpload} />
                                            </div>
                                        }
                                    </Upload>
                                </ImgCrop>
                            </div>
                        </div>

                        <div className="addmovies-cont-form-section-item">
                            <span>Movies name</span>
                            <Form.Item name="name" rules={[{ required: true}]}>
                                <Input />
                            </Form.Item>
                        </div>

                        <div className="addmovies-cont-form-section-item">
                            <span>Duration</span>
                            <Form.Item name="duration" rules={[{ required: true}]}>
                                <TimePicker />
                            </Form.Item>
                        </div>

                        <div className="addmovies-cont-form-section-item">
                            <span>description</span>
                            <Form.Item name="description" rules={[{ required: true}]}>
                                <Input />
                            </Form.Item>
                        </div>

                        <div className="addmovies-cont-form-section-item">
                            <span>Publish date</span>
                            <Form.Item name="publish_date" rules={[{ required: true}]}>
                                <DatePicker />
                            </Form.Item>
                        </div>

                        <div className="addmovies-cont-form-section-item">
                            <span>Country</span>
                            <Form.Item name="country" rules={[{ required: true}]}>
                                <Input />
                            </Form.Item>
                        </div>

                        <MoviesAutoFields/>

                        {fileMovies == null &&
                            <label className="addmovies-cont-form-section-movies">
                                <input type="file" onChange={(e)=>setFileMovies(e.target.files[0])}  accept="video/*" />
                                <div className="addmovies-cont-form-section-movies-des">
                                    <h3>click to select movie.</h3>
                                    <FontAwesomeIcon icon={faUpload} />
                                </div> 
                            </label>
                        }

                        {fileMovies != null &&
                            <>
                                {fileMovies.type == "video/mp4" ? 
                                <div className="addmovies-cont-form-section-movies-prev">
                                    <video  controls>
                                        <source src={URL.createObjectURL(fileMovies)} type="video/mp4" />
                                    </video> 
                                </div>
                                :
                                <div className="addmovies-cont-form-section-movies">
                                    <div className="addmovies-cont-form-section-movies-des">
                                        <h3>{fileMovies.name}</h3>
                                    </div> 
                                </div>
                                }
                            </>
                        }

                        {fileMovies != null &&
                            <div className="addmovies-cont-form-section-ads">
                                <div className="addmovies-cont-form-section-ads-inner">
                                    {adsList.map((items , indexs)=>
                                        <div key={indexs} className="addmovies-cont-form-section-ads-item">
                                            <div className="addmovies-cont-form-section-ads-item-sec">
                                                <label>
                                                    <input onChange={(e)=>{selectFileAds(e.target.files[0], indexs)}} type="file" accept="video/*"  required/>
                                                    <span>{items.file.name ? items.file.name : "Select ads file"}</span>
                                                </label>
                                            </div>
                                            <div className="addmovies-cont-form-section-ads-item-sec">
                                                <TimePicker defaultValue={items.time} onChange={(e)=>{selectTimeAds(e , indexs)}} required/>
                                            </div>
                                            <FontAwesomeIcon onClick={()=>DecreaseAds(indexs)} icon={faMinus} />
                                        </div>
                                    )}
                                    <div className="addmovies-cont-form-section-ads-plus" onClick={incrementAds} >Add Advertising</div>
                                </div>
                            </div>
                        }

                    </div>
                    <div className="addmovies-cont-form-footer">
                        <button type="submit" className="addmovies-cont-form-footer-btn-next">Add</button>
                        {fileMovies != null &&
                            <button onClick={emptyFields} type="button" className="addmovies-cont-form-footer-btn-del">Delete</button>
                        }
                    </div>

                </Form>
            </div>
        </div>
    )
}

export default MoviesAdd;
import { faChevronRight, faCog, faPlay, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

import React, { useEffect, useState } from "react" ;
import { 
    Admin_Get_Episode_File ,
    Admin_Delete_Episode ,
    Admin_Add_Series_two ,
    Admin_Edit_Episode
} from '../../../../widget/API_Admin'
import CModal from "../../../../widget/CModal/CModal";
import Loader from "../../../../widget/Loader/Loader";
import Toast_noty from '../../../../widget/Toast_noty'
import {Form , Input, InputNumber} from 'antd'
import {Link} from 'react-router-dom'
import {BASE_Medai_Video} from '../../../../widget/BASE_Medai_Video'

function SeriesDetailCont(props) {
    const[dispaly , setDisplay]=useState(false)
    const[openSeason , setOpenSeason]=useState(-1);
    const[maxHeightPx , setMaxHeightPx]=useState(0);
    const[seasonCount , setSeasonCount]=useState([]);

    const[visiblePlay , setVisiblePlay]=useState(false);
    const[episodeIDPlay , setEpisodeIdPlay]=useState(null)

    const[visibleDelete , setVisibleDelete]=useState(false)
    const[deleteId , setDeleteId]=useState(0);



    const openDeleteModal=(id)=>{
        setDeleteId(id);
        setVisibleDelete(true)
    }

    const onFinishDeleteEpisode=()=>{
        setDisplay(true)
        const returndata =  Admin_Delete_Episode(props.series.id , deleteId);
        returndata.then((res)=>{
            setDisplay(false)
            setVisibleDelete(false);
            setDeleteId(0)
            props.getDetailSeries()
        })
    }





    const changeOpenSeason=(id)=>{
        if(id == openSeason){
            setOpenSeason(-1)
        }
        else {
            let inner = document.getElementById(`seriesdetail-season-item-cont-inner${id}`)
            setMaxHeightPx(inner.scrollHeight + 50)
            setOpenSeason(id)
        }
    }

    const playEpisode=(val)=>{
        setEpisodeIdPlay(val)
        setVisiblePlay(true);
    }

    const emptyFields=()=>{
        setEpisodeIdPlay(null)
    }


    useEffect(()=>{
        if(props.series){
            setSeasonCount([])
            for(let i =1 ; i<=props.series.season_count ; i++){
                setSeasonCount(prev=>[...prev , i])
            }
        }
    },[props.series])

    return (
        <>
            <div className="fullPage">   
            {dispaly && <Loader/>}

            <CModal onScap={emptyFields} visible={visiblePlay} setVisible={setVisiblePlay} title="play"> 
                {episodeIDPlay != null && 
                    <video src={`${BASE_Medai_Video}${episodeIDPlay.url}`}  controls/>
                }
            </CModal>

            <CModal onScap={emptyFields} visible={visibleDelete} setVisible={setVisibleDelete} title="Delete Episode"> 
                    <p>Are you sure delete episode ?</p>
                    <div className="CModel-Bottom">
                        <button className="CModel-Bottom-btn-ok" onClick={onFinishDeleteEpisode} >Ok</button>
                        <button className="CModel-Bottom-btn-cancel" onClick={()=>{setVisibleDelete(false)}}>Cansel</button>
                    </div>
            </CModal>

            {props.series != null && 
            <div className="pagelist">
                <div className="pagelist-head">
                    <span>Season and Epesoid</span>
                    <Link to={`/panel/episodeadd/${props.series.id}`}>
                        <button  className="pagelist-head-button">Add Episode</button>
                    </Link>

                </div>
                <div className="seriesdetail-season">
                    {seasonCount.map((item , index)=>
                        <div className="seriesdetail-season-item">
                            <div className="seriesdetail-season-item-head">
                                <div onClick={()=>changeOpenSeason(item)} className={`seriesdetail-season-item-head-icon ${openSeason == item && 'seriesdetail-season-item-head-icon-active'}`}>
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </div>
                                <h4>Season : {item}</h4>
                            </div>
                            <div className={`seriesdetail-season-item-cont`} style={{maxHeight : openSeason == item && maxHeightPx}}>
                                <div id={`seriesdetail-season-item-cont-inner${item}`} className="seriesdetail-season-item-cont-inner">
                                    {props.series.episodes.map(val =>
                                        <>
                                            {val.season_number == item && 
                                                <div className="seriesdetail-season-item-cont-inner-episode">
                                                    <h6>Episode {val.episode_number}</h6>
                                                    <div className="seriesdetail-season-item-cont-inner-episode-right">
                                                        <button onClick={()=>playEpisode(val)}>
                                                            <FontAwesomeIcon icon={faPlay} />
                                                            <span>Play</span>
                                                        </button>
                                                        <Link  to={{
                                                            pathname : `/panel/episodeedit/${props.series.id}/${val.id}` ,
                                                            state : val
                                                            }}>
                                                            <button >
                                                                <FontAwesomeIcon icon={faEdit} />
                                                                <span>Edit</span>
                                                            </button>
                                                        </Link>
                                                        <button onClick={()=>openDeleteModal(val.id)}>
                                                            <FontAwesomeIcon icon={faTrash} />
                                                            <span>Delete</span>
                                                        </button>
                                                        <Link to={`/panel/serieseditads/${props.series.id}/${val.id}`}>
                                                            <button>
                                                                <FontAwesomeIcon icon={faCog} />
                                                                <span>ADS</span>
                                                            </button>
                                                        </Link>

                                                    </div>
                                                </div>
                                            }
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            }
            </div>  
        </>
    )
}

export default SeriesDetailCont;
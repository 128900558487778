import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react" ;
import {ToastContainer} from "react-toastify";
import Loader from '../../../../widget/Loader/Loader';
import { Select } from 'antd';
import Toast_noty from '../../../../widget/Toast_noty'
import {Admin_Filter_Dynamic} from '../../../../widget/API_Admin';


function AddDynamicMovieFooter(props) {
    const[dispaly , setDisplay]=useState(false);
    const [listSelect , setListSelect]=useState([]);
    const [sortSelect , setSortSelect]=useState(null);
    const { Option } = Select;


    const filterDynamic=()=>{
        if(sortSelect != null){
            setDisplay(true);
            const returndata =  Admin_Filter_Dynamic(props.mainState , sortSelect);
            returndata.then((res)=>{
            setDisplay(false);
            if(res.result == 'ok'){
                props.setResultFilter(res.data)
            }
            else if(res.error == 'unauthenticated') {
                localStorage.clear();
                props.history.push('/');  
            }
            else {
                Toast_noty(res.error, 5000, "error");
            }
            })
                returndata.catch((er)=>{
                setDisplay(false);
                Toast_noty('please try again', 5000, "error");
            })
        }
        else{
            Toast_noty('please select type sort', 5000, "error");
        }
    }



    useEffect(()=>{
        if(props.listData && props.listData.length > 0){
            setListSelect(props.listData[0].data)
        }
    },[props.listData])


    return (
        <>
        {dispaly && <Loader/>}
        <div className={`dynamic-footer ${props.activeTab  && 'dynamic-footer-active'}`}>
            <div className="dynamic-footer-active-select">
                <span>sort by : </span>
                <Select
                    allowClear
                    onChange={(e)=>{setSortSelect(e)}}
                    >
                        {listSelect.map((item , index)=>
                            <Option value={item.name}>{item.name}</Option>
                        )}
                </Select>
                <button onClick={filterDynamic}>Filter</button>
            </div>
        </div>
        </>
    )
}

export default AddDynamicMovieFooter;
import React, { useEffect, useRef, useState } from "react" ;
import Toast_noty from '../../../widget/Toast_noty'
import Loader from "../../../widget/Loader/Loader";

import { Admin_Get_Series_Single} from '../../../widget/API_Admin';
import {Link} from 'react-router-dom';
import SeriesDetailHead from "./widget/SeriesDetailHead";
import SeriesDetailCont from './widget/SeriesDetailCont';
import '../seriesStyle.css';


function SeriesDetail(props) {
    const[dispaly , setDisplay]=useState(false);
    const [series , setSeries]=useState(null);





    const getDetailSeries=()=>{
        if(props.match.params.id){
            setDisplay(true)
            const returndata =  Admin_Get_Series_Single(props.match.params.id);
            returndata.then((res)=>{
            setDisplay(false)
            if(res.result == 'ok'){
                setSeries(res.data)
            }
            else if(res.error == 'unauthenticated') {
                localStorage.clear();
                props.history.push('/');  
            }
            else {
                Toast_noty(res.error, 5000, "error");
            }
            })
             returndata.catch((er)=>{
                setDisplay(false)
                Toast_noty('please try again', 5000, "error");
            })
        }
    }


    useEffect(()=>{
        getDetailSeries();
      },[])

    return (
        <div className="seriesdetail">
            {dispaly && <Loader/>}
            {series != null && <SeriesDetailHead series={series} /> }
            <SeriesDetailCont series={series} getDetailSeries={getDetailSeries} />
        </div>
    )
}

export default SeriesDetail;
import React, { useEffect, useRef, useState } from "react" ;
import {ToastContainer} from "react-toastify";
import Toast_noty from '../../../widget/Toast_noty'
import Loader from "../../../widget/Loader/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faSearch, faTable , faStar, faHeart, faMusic, faChevronRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import {Admin_Get_All_Series} from '../../../widget/API_Admin';
import {BASE_Medai_URL} from '../../../widget/BASE_Medai_URL';
import moment from "moment";
import { Link } from "react-router-dom";
import '../seriesStyle.css';




function SeriesList(props) {
    const[dispaly , setDisplay]=useState(false);
    const[rowDisplay , setRowDisplay]=useState(false);
    const[listSeries , setListSeries]=useState([]);
    const[listSeriesKeeper , setListSeriesKeeper]=useState([]);
    const [currentPage , setCurrentPage]=useState(1);
    const [lastPage , setLastPage]=useState(0);



    const changePage=(status)=>{
        if(status){
            if(lastPage >= currentPage + 1){
                getData(currentPage + 1)
            }
        }
        else {
            if(currentPage > 1){
                getData(currentPage - 1)
            }
        }
    }
 

    const checkResizePage=()=>{
        if(window.innerWidth < 770){
            setRowDisplay(false)
        }
    }


    const searchManual=(value)=>{
        setListSeries(
            listSeriesKeeper.filter(value1 => {
               return !!( value1.name.toUpperCase().includes(value.toUpperCase()));
            })
        )
    }



    React.useEffect(() => {
        window.addEventListener("resize", checkResizePage);
        return () => window.removeEventListener("resize" , checkResizePage);
      });


    const getData=(current)=>{
        setDisplay(true);
        const returndata =  Admin_Get_All_Series(current);
        returndata.then((res)=>{
        setDisplay(false);
        if(res.result == 'ok'){
            if(res.data.data.length > 0){
                setListSeries(res.data.data);
                setListSeriesKeeper(res.data.data)
                setCurrentPage(res.data.current_page)
                setLastPage(res.data.last_page)
                window.scrollTo({top: 0 })
            }
            else {
                Toast_noty("data not found", 5000, "error");
            }
            
        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
            returndata.catch((er)=>{
            setDisplay(false);
            Toast_noty('please try again', 5000, "error");
        })
    }  


    useEffect(()=>{
        getData(currentPage)
    },[])

      

    return (
        <div className="series">
            {dispaly && <Loader/>}
            <div className="series-head">
                <div className="series-head-search">
                    <FontAwesomeIcon icon={faSearch} />
                    <input onChange={(e)=>searchManual(e.target.value)} type="text" placeholder="Search"/>
                </div>
                <div className="series-head-line">
                    <div onClick={()=>{setRowDisplay(true)}} className={`series-head-line-item ${rowDisplay && 'series-head-line-item-active'}`}>
                        <FontAwesomeIcon icon={faList} />
                    </div>
                    <div onClick={()=>{setRowDisplay(false)}} className={`series-head-line-item ${!rowDisplay && 'series-head-line-item-active'}`}>
                        <FontAwesomeIcon icon={faTable} />
                    </div>
                </div>
            </div>
            <div className="series-cont">
                {listSeries.map((item , index)=>
                        <div className={`series-cont-item ${rowDisplay && 'series-cont-item-row'}`}>
                            <Link to={`/panel/seriesdetail/${item.id}`}>
                            <div className="series-cont-item-inner">
                                <div className="series-cont-item-inner-img">
                                    <img src={`${BASE_Medai_URL}/storage/series/covers/${item.id}`} />
                                </div>
                                <div className="series-cont-item-inner-cont">
                                    <div className="series-cont-item-inner-head">
                                        <div className="series-cont-item-inner-head-rate">
                                            <FontAwesomeIcon icon={faStar} />
                                            <FontAwesomeIcon icon={faStar} />
                                            <FontAwesomeIcon icon={faStar} />
                                            <FontAwesomeIcon icon={faStar} />
                                            <FontAwesomeIcon icon={faStar} />
                                        </div>
                                    </div>
                                    <div className="series-cont-item-inner-title">
                                        <h3>{item.name}</h3>
                                    </div>
                                    <div className="series-cont-item-inner-des">
                                        <span>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
                                        </span>
                                    </div>
                                    <div className="series-cont-item-inner-time">
                                        <span>release : {moment(item.publish_time).format('YYYY-MM-DD hh:mm')}</span>
                                    </div>
                                </div>
                                <div className="series-cont-item-inner-bottom">
                                    {/* <div className="series-cont-item-inner-bottom-item">
                                        <FontAwesomeIcon icon={faHeart} />
                                        <span>{55}</span>
                                    </div> */}
                                    {/* <div className="series-cont-item-inner-bottom-item">
                                        <FontAwesomeIcon icon={faMusic} />
                                        <span>{item.tracks_count}</span>
                                    </div> */}
                                </div>
                            </div>
                            </Link>
                        </div>
                )}
            </div>

            <div className="pagining">
               <div className="pagining-inner">
                    <div onClick={()=>changePage(false)} className="pagining-inner-side"><FontAwesomeIcon icon={faChevronLeft} /></div>
                    <div className="pagining-inner-center">{currentPage}</div>
                    {lastPage >= currentPage + 1 && 
                        <div onClick={()=>changePage(true)} className="pagining-inner-side"><FontAwesomeIcon icon={faChevronRight} /></div>
                    }
               </div>
            </div>

        </div>
    )
}

export default SeriesList;
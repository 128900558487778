import { faChevronDown, faCog, faMinus, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react" ;
import {ToastContainer} from "react-toastify";
import Loader from '../../../../widget/Loader/Loader';
import { Select } from 'antd';
import { DatePicker, Space } from 'antd';
import moment from "moment";


function AddDynamicMusicVideoMain(props) {
    const[dispaly , setDisplay]=useState(false);
    const[listData , setListData]=useState([]);
    const { Option } = Select;
    const { RangePicker } = DatePicker;

    const SelectStatusEnum=(values , tab , field)=>{
        let mainState = {...props.mainState} ;
        mainState[tab][field]['list'] = values ;
        props.setMainState(mainState)
    }

    const changeIntTab= (id , tab)=>{
        props.setMainState(prev=>({...prev , ...prev[tab]['id']['list_type'] = id}))
        if(id == 1){
            props.setMainState(prev=>({...prev , ...prev[tab]['id']['list'] = []}))
        }

    }

    const changeRangeInt=(value , tab , minOrmax)=>{
        let mainState =  {...props.mainState} ;
        mainState[tab]['id'][minOrmax] = value ;
        let max = parseInt(mainState[tab]['id']['maxRange']) ;
        let min = parseInt(mainState[tab]['id']['minRange']) ;
        if( max > min ){
            let listNumber = [];
            while(max >= min){
                listNumber.push(min);
                min += 1 ;
            }
            mainState[tab]['id']['list'] = listNumber ;
        }
        props.setMainState(mainState)
    }

    const removeIntField=(index , tab)=>{
        let mainState =  {...props.mainState };
        mainState[tab]['id']['list'].splice(index , 1);
        props.setMainState(mainState)
    }

    const addIntField=(tab)=>{
        let mainState =  {...props.mainState} ;
        mainState[tab]['id']['list'].push(0);
        props.setMainState(mainState)
    }

    const changeValurInputInt=(value , tab , index)=>{
        let mainState =  {...props.mainState} ;
        mainState[tab]['id']['list'][index] = parseInt(value) ;
        props.setMainState(mainState)
    }

    const changeValueInputString=(value , tab , name , index)=>{
        let mainState =  {...props.mainState} ;
        mainState[tab][name]['list'][index]['value'] = value ;
        props.setMainState(mainState)
    }

    const changeValueExeString=( tab , name , index)=>{
        let mainState =  {...props.mainState} ;
        mainState[tab][name]['list'][index]['exactly'] = !mainState[tab][name]['list'][index]['exactly'];
        props.setMainState(mainState)
    }

    const removeStringField=(index , tab , name)=>{
        let mainState =  {...props.mainState} ;
        mainState[tab][name]['list'].splice(index , 1);
        props.setMainState(mainState)
    }

    const addStringField=(tab , name)=>{
        let mainState =  {...props.mainState} ;
        mainState[tab][name]['list'].push(
            {
                'value':0 ,
                'exactly':false ,
            }
        );
        props.setMainState(mainState)
    }

    const changeDateTab=(id , tab , name)=>{
        props.setMainState(prev=>({...prev , ...prev[tab][name]['date_type'] = id}))
    }

    const changeDateValue=(value , tab , name , type)=>{
        let mainState =  {...props.mainState} ;
        if(type == 0){
            mainState[tab][name]['list'] =  moment(value._d).format('YYYY-MM-DD');
            props.setMainState(mainState)

        }
        else if(type == 1){
            if(value[1]){
                mainState[tab][name]['list'] = [moment(value[0]._d).format('YYYY-MM-DD') ,moment(value[1]._d).format('YYYY-MM-DD')];
                props.setMainState(mainState)
            }
        }
    }

    const mainStateDefualt=()=>{
        let mainState = props.mainState ;
        for(let i of props.listData){
            for(let j of i['data']){
                if(!mainState[i.name.substring(11)]){
                    mainState[i.name.substring(11)] = {};
                }    
                if(j['type'] == 'number'){
                    mainState[i.name.substring(11)][j.name] = {
                        'type':'number' ,
                        'list':[0] ,
                        'minRange':-1 ,
                        'maxRange':-1 ,
                        'list_type':-1 ,
                    }
                }
                if(j['type'] == 'string'){
                    mainState[i.name.substring(11)][j.name] = {
                        'type':"string" ,
                        'list':[]
                    }
                }
                if(j['type'] == 'date_time'){
                    mainState[i.name.substring(11)][j.name] = {
                        'date_type':-1 ,
                        'list':"" ,
                        'type':"date_time",
                    }
                }
                if(j['type'] == 'enum'){
                    mainState[i.name.substring(11)][j.name] = {
                        'type':'enum' ,
                        'list':[] ,
                    }
                }
            }
        }
        props.setMainState(mainState)
    }



    useEffect(()=>{
        if(props.listData){
            setListData(props.listData);
            mainStateDefualt()
        }
    },[props.listData])

    return (
        <div className="dynamic-main">
            {dispaly && <Loader/>}
            <div className="dynamic-main-inner">
                {/* <div className="dynamic-main-inner-head">{props.activeTab != -1 && props.activeTab.substring(11)}</div> */}
                {listData.map(item=>
                    <>
                        {item.data.map(val=>
                            <div className={`dynamic-main-inner-item ${props.activeTab == item.name && 'dynamic-main-inner-item-active'}`}>
                                <div className="dynamic-main-inner-item-inner">
                                    <div className="dynamic-main-inner-item-inner-head">
                                        <FontAwesomeIcon icon={faCog} />
                                        <span>{val.description} </span> 
                                        <span className="dynamic-main-inner-item-inner-head-type">({val.name})</span>
                                    </div>
                                    <div className="dynamic-main-inner-item-inner-main">
                                        {/* <span className="dynamic-main-inner-item-inner-main-title">{val.name}</span> */}
                                        {val.type == 'enum' && 
                                            <div className="dynamic-main-inner-item-inner-main-item" >
                                                <Select
                                                    mode="multiple"
                                                    allowClear
                                                    onChange={(e)=>SelectStatusEnum(e , props.activeTab.substring(11) , val.name)}
                                                    >
                                                    {val.values.map(listItem=>
                                                        <Option value={listItem.id}>{listItem.name}</Option>
                                                    )}
                                                </Select>
                                            </div>
                                        }
                                        {val.type == 'number' && 
                                            <div className="dynamic-main-inner-item-inner-main-item" >
                                                <div className="dynamic-main-inner-item-inner-main-item-intCat">
                                                    <div onClick={()=>changeIntTab(0 , props.activeTab.substring(11))} className={`dynamic-main-inner-item-inner-main-item-intCat-sec ${props.mainState[props.activeTab.substring(11)] && props.mainState[props.activeTab.substring(11)]['id']['list_type'] == 0 && 'album-head-line-item-active'}`}>Range</div>
                                                    <div onClick={()=>changeIntTab(1 , props.activeTab.substring(11))} className={`dynamic-main-inner-item-inner-main-item-intCat-sec ${props.mainState[props.activeTab.substring(11)] && props.mainState[props.activeTab.substring(11)]['id']['list_type'] == 1 && 'album-head-line-item-active'}`}>Number</div>
                                                </div>
                                                {props.mainState[props.activeTab.substring(11)] && props.mainState[props.activeTab.substring(11)]['id']['list_type'] == 0 &&
                                                    <div className="dynamic-main-inner-item-inner-main-item-main">
                                                        <div className="dynamic-main-range">
                                                            <div className="dynamic-main-range-item">
                                                                <span>Min</span>
                                                                <input placeholder="Enter number" onChange={(e)=>changeRangeInt(e.target.value , props.activeTab.substring(11) , 'minRange')} type="number" />
                                                            </div>
                                                            <div className="dynamic-main-range-item">
                                                                <span>Max</span>
                                                                <input placeholder="Enter number"  onChange={(e)=>changeRangeInt(e.target.value , props.activeTab.substring(11) , 'maxRange')} type="number" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {props.mainState[props.activeTab.substring(11)] && props.mainState[props.activeTab.substring(11)]['id']['list_type'] == 1 &&
                                                    <div   className="dynamic-main-inner-item-inner-main-item-main">
                                                        <div className="dynamic-main-multi-input">
                                                            {props.mainState[props.activeTab.substring(11)] &&  props.mainState[props.activeTab.substring(11)]['id']['list'].map((fieldItem , index)=>
                                                                <div key={index} className="dynamic-main-multi-input-item">
                                                                    <input onChange={(e)=>changeValurInputInt(e.target.value ,props.activeTab.substring(11) , index )} placeholder="Enter number"  type="number" />
                                                                    {index != 0 &&
                                                                        <div onClick={()=>removeIntField(index , props.activeTab.substring(11) )} className="dynamic-main-multi-input-item-btn">
                                                                            <FontAwesomeIcon icon={faMinus} />
                                                                        </div>
                                                                    }
                                                                </div>
                                                            )}
                                                            <div onClick={()=>addIntField(props.activeTab.substring(11))} className="dynamic-main-multi-input-footer">Add Feild</div>
                                                        </div>
                                                    </div>
                                                }
                                                
                                            </div>
                                        }
                                        {val.type == 'string' && 
                                            <div className="dynamic-main-inner-item-inner-main-item-main">
                                                <div className="dynamic-main-multi-input">
                                                    {props.mainState[props.activeTab.substring(11)] && props.mainState[props.activeTab.substring(11)][val.name] && props.mainState[props.activeTab.substring(11)][val.name]['list'].map((fieldItem , index)=>
                                                        <div key={index} className="dynamic-main-multi-input-item">
                                                            <div onClick={()=>changeValueExeString(props.activeTab.substring(11) , val.name , index )} className="dynamic-main-multi-input-item-exe">
                                                                {
                                                                    props.mainState[props.activeTab.substring(11)][val.name]['list'][index]['exactly'] == true &&
                                                                    <div className="dynamic-main-multi-input-item-exe-togel dynamic-main-multi-input-item-exe-togel-false">
                                                                        <span>yse</span>
                                                                        <div></div>
                                                                    </div>
                                                                }
                                                                {
                                                                    props.mainState[props.activeTab.substring(11)][val.name]['list'][index]['exactly'] == false &&
                                                                    <div className="dynamic-main-multi-input-item-exe-togel">
                                                                        <span>no</span>
                                                                        <div></div>
                                                                    </div>
                                                                }
                                                            </div>
                                                            <input 
                                                                value={props.mainState[props.activeTab.substring(11)][val.name]['list'][index]['value'] && props.mainState[props.activeTab.substring(11)][val.name]['list'][index]['value']} 
                                                                onChange={(e)=>changeValueInputString(e.target.value ,props.activeTab.substring(11) , val.name , index )} 
                                                                placeholder="Enter text"  type="text" 
                                                            />
                                                                <div onClick={()=>removeStringField(index , props.activeTab.substring(11) , val.name )} className="dynamic-main-multi-input-item-btn">
                                                                    <FontAwesomeIcon icon={faMinus} />
                                                                </div>
                                                        </div>
                                                    )}
                                                    <div onClick={()=>addStringField(props.activeTab.substring(11) , val.name)} className="dynamic-main-multi-input-footer">Add Feild</div>
                                                </div>
                                            </div>
                                        }
                                        {val.type == 'date_time' && 
                                            <div className="dynamic-main-inner-item-inner-main-item" >
                                                <div className="dynamic-main-inner-item-inner-main-item-intCat">
                                                    <div onClick={()=>changeDateTab(0 , props.activeTab.substring(11), val.name)} className={`dynamic-main-inner-item-inner-main-item-intCat-sec ${props?.mainState[props.activeTab.substring(11)] && props.mainState[props.activeTab.substring(11)][val.name] && props.mainState[props.activeTab.substring(11)][val.name]['date_type'] == 0 && 'album-head-line-item-active'}`}>One date</div>
                                                    <div onClick={()=>changeDateTab(1 , props.activeTab.substring(11), val.name)} className={`dynamic-main-inner-item-inner-main-item-intCat-sec ${props.mainState[props.activeTab.substring(11)] && props.mainState[props.activeTab.substring(11)][val.name] && props.mainState[props.activeTab.substring(11)][val.name]['date_type'] == 1 && 'album-head-line-item-active'}`}>Range date</div>
                                                </div>
                                                {props.mainState[props.activeTab.substring(11)] && props.mainState[props.activeTab.substring(11)][val.name] && props.mainState[props.activeTab.substring(11)][val.name]['date_type'] == 0 &&
                                                    <div className="dynamic-main-inner-date">
                                                        <DatePicker showTime onOk={(e)=>changeDateValue(e , props.activeTab.substring(11) , val.name , 0)} />
                                                    </div>
                                                }
                                                {props.mainState[props.activeTab.substring(11)] && props.mainState[props.activeTab.substring(11)][val.name] && props.mainState[props.activeTab.substring(11)][val.name]['date_type'] == 1 &&
                                                    <div className="dynamic-main-inner-date">
                                                        <RangePicker
                                                            showTime={{ format: 'HH:mm' }}
                                                            format="YYYY-MM-DD HH:mm"
                                                            onOk={(e)=>changeDateValue(e , props.activeTab.substring(11) , val.name , 1)}
                                                        /> 
                                                    </div> 
                                                }
                                            </div>
                                        }
                                    </div>
                                </div> 
                            </div>
                        )}
                    </>
                )}
            </div>           
        </div>
    )
}

export default AddDynamicMusicVideoMain;
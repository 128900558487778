import React, { useEffect, useState } from "react" ;
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronLeft, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import TablePage from "../../../../widget/Table/TablePage";
import {Admin_Change_Status_User , Admin_Get_All_User , Admin_Add_User} from '../../../../widget/API_Admin';
import Toast_noty from '../../../../widget/Toast_noty'
import Loader from '../../../../widget/Loader/Loader'
import { Select , DatePicker, Form , Input ,InputNumber} from 'antd';
import CModal from '../../../../widget/CModal/CModal'


function User(props) {
    const[display , setDisplay]=useState(false);
    const [listMessage , setListMessage]=useState([]);
    const[visibleAdd , setVisibleAdd]=useState(false);
    const[visibleReject , setVisibleReject]=useState(false);
    const [idUserRejected , setIdUserRejected]=useState(0);
    const[listData , setListData]=useState([]);
    const [currentPage , setCurrentPage]=useState(1);
    const [lastPage , setLastPage]=useState(0);
    const [type , setType]=useState(null);
    const { Option } = Select;

    const changePage=(status)=>{
        if(status){
            if(lastPage >= currentPage + 1){
                getData(currentPage + 1 ,type)
            }
        }
        else {
            if(currentPage > 1){
                getData(currentPage - 1 ,type)
            }
        }
    }


    const validationFields=(e)=>{
        setListMessage([]);
        if(e.target[0].value.length < 3 || e.target[2].value.length < 8 || e.target[3].value.length < 10){
            if(e.target[0].value.length < 3 ){
                Toast_noty("Name must be longer than 3 characters", 5000, "info");
            }
            if(e.target[2].value.length < 8 ){
                Toast_noty("Password must be longer than 8 characters", 9000, "info");

            }
            if(e.target[3].value.length < 10 ){
                Toast_noty("About  must be longer than 10 characters", 9000, "info");

            }
            return false ;
        }
        else {
            return true;
        }
    }

    const registerUser=async(e)=>{
        e.preventDefault();
        if(await validationFields(e)){
            setDisplay(true);
            const returndata =  Admin_Add_User(e);
            returndata.then((res)=>{
            setDisplay(false)
            if(res.result == 'ok'){
                getData()
                setVisibleAdd(false);
                Toast_noty("Registration was successful. Wait for admin approval", 9000, "success");
            }
            else 
                {
                Toast_noty(res.error, 5000, "error");
                }
            })
            returndata.catch((er)=>{
                setVisibleAdd(false);
                setDisplay(false);
                Toast_noty('Please try again', 5000, "error");
            })
        }
    }


    const filterList=async(value)=>{
            await setType(value)
            getData(currentPage , value)
    }

    const selectStatusUser=async(value, id)=>{
        if(value != ""){
            if(value == 'rejected'){
                setVisibleReject(true);
                setIdUserRejected(id);
            }
            else {
                changeStatusUser(id , value);
            }
        }
    }


    const onFinishStatus=(e)=>{
        e.preventDefault();
        changeStatusUser(idUserRejected , 'rejected' , e.target[0].value)
    }

    const changeStatusUser=async(id ,typeOfStatus,reson)=>{
        setDisplay(true)
        const returndata =  Admin_Change_Status_User(id , typeOfStatus , reson);
        returndata.then(async(res)=>{
        setDisplay(false)
        if(res.result == 'ok'){
            setVisibleReject(false);
            await   getData(currentPage , type)
            Toast_noty("Changes saved successfully", 10000, "success");
        }
        })
    }

    const colLike = [
        {
            title:"id" ,
            render:(i) => (<>{i.id != null && i.id}</>)
        },
        {
            title:"Name" ,
            render:(i) => (<>{i.name != null && i.name}</>)
        },
        {
            title:"Ads number" ,
            render:(i) => (<>{i.ad != null && i.ad}</>)
        },
        {
            title:"Status" ,
            render:(i) => (
                <>
                    {[ 
                        i.statusName == 'pending' && <div className="table-tag table-tag-1">pending</div> ,
                        i.statusName == 'rejected' && <div className="table-tag table-tag-2">rejected</div> ,
                        i.statusName == 'accepted' && <div className="table-tag table-tag-3">accepted</div> ,
                        i.statusName == 'verified' && <div className="table-tag table-tag-1">verified</div> ,
                        i.statusName == 'invited' && <div className="table-tag table-tag-1">invited</div> ,
                    ]}
                </>
            )
        },
        {
            title:"Action" ,
            render:(i) => (
            <select className="table-select" onChange={(e)=>selectStatusUser(e.target.value ,i.id)}>
                <option value={""}>--</option>
                <option value={"accepted"}>{"accepted"}</option>
                <option value={"rejected"}>{"rejected"}</option>
                <option value={"pending"}>{"pending"}</option>
                <option value={"verified"}>{"verified"}</option>
                <option value={"invited"}>{"invited"}</option>
            </select>
            )
        },
        {
            title:"Reject reason" ,
            render:(i) => (<>{i.rejected_message != null && i.statusName == 'rejected' && i.rejected_message}</>)
        },
        {
            title:"redirect to artist" ,
            render:(i) => (<>
            {i.statusName == 'accepted' && 
            <a target="_blank" 
                href={`http://188.121.110.53:3008/al?name=${i.name}&id=${i.id}&email=${JSON.parse(localStorage.getItem('userInfo')).email}&token=${JSON.parse(localStorage.getItem('userInfo')).token}`}
            >
                <FontAwesomeIcon icon={faExternalLinkAlt} />
            </a>
            }</>)
        },
    ]


    const getData=(current ,typeOf)=>{
        setDisplay(true)
        const returndata =  Admin_Get_All_User(current ,typeOf);
        returndata.then((res)=>{
        setDisplay(false)
        if(res.result == 'ok'){
            setListData(res.data.data)
            setCurrentPage(res.data.current_page)
            setLastPage(res.data.last_page)

        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
         returndata.catch((er)=>{
            Toast_noty('please try again', 5000, "error");
        })
    }


    useEffect(()=>{
        getData(currentPage)
    },[])


    return (
        <>
            <div className="search-list">
                <CModal visible={visibleReject} setVisible={setVisibleReject} title="Reson reject"> 
                    <form onSubmit={onFinishStatus} className="confirmation-modal-form">
                        <div className="confirmation-modal-form-item">
                            <span>Reson reject</span>
                            <input type="text" required/>
                        </div>
                        <div className="confirmation-modal-form-item">
                            <button>Submit</button>
                        </div>
                    </form>
                </CModal>
                <CModal visible={visibleAdd} setVisible={setVisibleAdd} title="Add user"> 
                    <form onSubmit={registerUser} className="confirmation-modal-form">
                        <div className="confirmation-modal-form-item">
                            <span>Name</span>
                            <input type="text" required/>
                        </div>
                        <div className="confirmation-modal-form-item">
                            <span>Email</span>
                            <input type="email" required/>
                        </div>
                        <div className="confirmation-modal-form-item">
                            <span>Password</span>
                            <input type="text" required/>
                        </div>
                        <div className="confirmation-modal-form-item">
                            <span>About</span>
                            <input type="text" required/>
                        </div>
                        <div className="confirmation-modal-form-item">
                            <button>Submit</button>
                        </div>
                    </form>
                </CModal>
                <div className="search-list-head"><span>Search</span></div>
                <div className="search-list-main">
                    <div className="search-list-main-item" >
                        <span>Type</span>
                        <Select
                            allowClear
                            onChange={(value)=>filterList(value)}
                            >
                                <Option value={""}>--</Option>
                                <Option value={"accepted"}>{"accepted"}</Option>
                                <Option value={"rejected"}>{"rejected"}</Option>
                                <Option value={"pending"}>{"pending"}</Option>
                                <Option value={"verified"}>{"verified"}</Option>
                                <Option value={"invited"}>{"invited"}</Option>

                        </Select>
                    </div>
                </div>
            </div>
            <div className="fullPage">
                {display && <Loader/>}
                {listData.length > 0 && 
                <div className="pagelist">
                    <div className="pagelist-head">
                        <span>Users</span>
                        <button onClick={()=>{setVisibleAdd(true)}} className="pagelist-head-button">Add User </button>
                    </div>
                    <TablePage columns={colLike} data={listData}>
                        {currentPage != 1 && 
                            <div className="table-counter-main-item" onClick={()=>changePage(false)} ><FontAwesomeIcon icon={faChevronLeft} /></div>
                        }
                            <div className="table-counter-main-item">{currentPage} </div>
                        {lastPage >= currentPage + 1 && 
                            <div className="table-counter-main-item" onClick={()=>changePage(true)} ><FontAwesomeIcon icon={faChevronRight} /></div>
                        }
                    </TablePage>
                </div>
                }
            </div> 
        </>
    )
}

export default User;
import React, { useEffect, useState } from "react" ;
import {ToastContainer} from "react-toastify";
import Toast_noty from '../../../widget/Toast_noty'
import Loader from "../../../widget/Loader/Loader";
import {Admin_Edit_Episode} from '../../../widget/API_Admin';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faUpload  } from "@fortawesome/free-solid-svg-icons";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import {Redirect} from 'react-router-dom';
import { Select , TimePicker, Form , Input ,InputNumber} from 'antd';
import moment from "moment";




function EpisodeEdit(props) {
    const[dispaly , setDisplay]=useState(false);
    const[fileEpisode , setFileEpisode]=useState(null);
    const [duration , setDuration]=useState(0);
    const [form] = Form.useForm();


    const selectFile= async(e)=>{
        await setFileEpisode(e.target.files[0])
        let video = document.createElement('video');
        let fileURL = URL.createObjectURL(e.target.files[0]);
        video.src = fileURL ;
        video.ondurationchange = function() {
            setDuration(parseInt(this.duration));
        };
    }



    const onFinish=(values)=>{
        setDisplay(true);
        const returndata =  Admin_Edit_Episode(props.match.params.seriesId , props.match.params.id ,values , fileEpisode);
        returndata.then((res)=>{
        setDisplay(false)
        if(res.result == 'ok'){
            Toast_noty('Edit episode success', 5000, "success");
            props.history.push(`/panel/seriesdetail/${props.match.params.seriesId}`);  

        }
        })
    }

    useEffect(()=>{
        if(props.location.state){
            console.log(props.location.state.description);
            form.setFieldsValue({'description' : props.location.state.description})
            form.setFieldsValue({'episode_number' : props.location.state.episode_number})
            form.setFieldsValue({'season_number' : props.location.state.season_number})  
        }
    },[props.location.state])

    return (
        <>
            <div className="addseries-cont">
                {dispaly && <Loader/>}
                {/* <div className="addalbum-cont-head">
                    <h3>Add Track</h3>
                </div> */}
                <Form form={form} onFinish={onFinish} id="addseries-cont-form"  className="addseries-cont-form">
                        <div className="addseries-cont-form-section">
                            <div className="addseries-cont-form-section-item">
                                <span>Description</span>
                                <Form.Item name="description" rules={[{ required: true}]}>
                                    <Input />
                                </Form.Item>
                            </div>

                            <div className="addmovies-cont-form-section-item">
                                <span>Duration</span>
                                <Form.Item name="duration" >
                                    <TimePicker />
                                </Form.Item>
                            </div>


                            <div className="addseries-cont-form-section-item">
                                <span>Episode Number</span>
                                <Form.Item  name="episode_number" rules={[{ required: true}]}>
                                    <InputNumber />
                                </Form.Item>
                            </div>

                            <div className="addseries-cont-form-section-item">
                                <span>Season Number</span>
                                <Form.Item  name="season_number" rules={[{ required: true}]}>
                                    <InputNumber />
                                </Form.Item>
                            </div>

                            <div className="addseries-cont-form-section-item-file">
                                {fileEpisode == null && 
                                    <div className="addseries-cont-form-section-item-file-inner">
                                        <label>
                                            <span>+ Update episode file</span>
                                            <input  accept="video/*"  onChange={(e)=>setFileEpisode(e.target.files[0])} type="file" />
                                        </label>
                                    </div>
                                }
                                {fileEpisode != null &&
                                <>
                                    {fileEpisode.type == "video/mp4" ?
                                        <div className="addseries-cont-form-section-item-file-player">
                                                <video  controls>
                                                    <source src={URL.createObjectURL(fileEpisode)} type="video/mp4" />
                                                </video> 
                                        </div>
                                    :
                                        <div className="addseries-cont-form-section-item-file-inner">
                                            <label>
                                                <span>{fileEpisode.name}</span>
                                            </label>
                                        </div>
                                    }
                                </>    
                                }
                            </div>


                        </div>
                        
                        <div className="addseries-cont-form-footer">
                            <button className="addseries-cont-form-footer-btn-next">Update Episode</button>
                            {fileEpisode != null &&
                                <button type="button" onClick={()=>{setFileEpisode(null)}} className="addseries-cont-form-footer-btn-del">Delete</button>
                            }
                        </div>
                       
                </Form>
            </div>
        </>    
    )
}

export default EpisodeEdit;
import React, { useEffect, useState } from "react" ;
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV , faEdit , faTrash , faUpload} from "@fortawesome/free-solid-svg-icons";
import Table from "../../../widget/Table/Table";
import {
    Admin_Get_All_Properties ,
    Admin_Delete_Peroperty ,
} from '../../../widget/API_Admin';
import Toast_noty from '../../../widget/Toast_noty'
import Loader from '../../../widget/Loader/Loader'
import CModal from "../../../widget/CModal/CModal";
import '../manageFieldStyle.css';
import PropertiesAdd from './widget/PropertiesAdd';





function Properties(props) {
    const[display , setDisplay]=useState(false);
    const[visibleDelete , setVisibleDelete]=useState(false);
    const[visibleAdd , setVisibleAdd]=useState(false);
    const[deleteId , setDeleteId]=useState(null)
    const[listData , setListData]=useState([]);
    const[actionList , setActionList]=useState(-1);




    const changeDisplayActionList=(id)=>{
        if(actionList == id){
            setActionList(-1)
        }
        else {
            setActionList(id)
        }
    }


    const openDeleteModal=(id)=>{
        setDeleteId(id)
        setVisibleDelete(true)
    }

    const deleteProperties=()=>{
        setDisplay(true);
        setVisibleDelete(false)
        const returndata =  Admin_Delete_Peroperty(deleteId);
        returndata.then(async(res)=>{
        setDisplay(false);
        if(res.result == 'ok'){
            await getData()
            setVisibleDelete(false)
            Toast_noty("Delete success", 10000, "success");
        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
         returndata.catch((er)=>{
            setDisplay(false);
            Toast_noty('please try again', 5000, "error");
        })
    }



    const col = [
        {
            title:"id" ,
            render:(i) => (<>{i.id != null && i.id}</>)
        },
        {
            title:"Name" ,
            render:(i) => (<>{i.name != null && i.name}</>)
        },
        {
            title:"Type" ,
            render:(i) => (<>{i.type != null && i.type}</>)
        },
        {
            title:"Description" ,
            render:(i) => (<>{i.description != null && i.description}</>)
        },
        {
            title:"Action" ,
            render:(i) => (
            <div id="table-action" className="table-action">
                <div className="table-action-icon" onClick={()=>changeDisplayActionList(i.id)}>
                    <FontAwesomeIcon icon={faEllipsisV}  />
                </div>
                <div onClick={()=>openDeleteModal(i.id)} className={`table-action-list ${actionList == i.id && 'table-action-list-active'}`}>
                    <div  className="table-action-list-item">
                        <FontAwesomeIcon icon={faTrash} />
                        <span>Delete</span>
                    </div>
                </div>
            </div>
            )
        },
    ]


    const getData=()=>{
        setDisplay(true)
        const returndata =  Admin_Get_All_Properties();
        returndata.then((res)=>{
        setDisplay(false)
        if(res.result == 'ok'){
            setListData(res.data)
        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
         returndata.catch((er)=>{
            Toast_noty('please try again', 5000, "error");
        })
    }




    useEffect(()=>{
        getData()
    },[])


    return (
        <>
            <div className="fullPage">
                {display && <Loader/>}

                <CModal visible={visibleDelete} setVisible={setVisibleDelete} title="Delete"> 
                    <p>Are you sure delete properties {deleteId} ?</p>
                    <div className="CModel-Bottom">
                        <button className="CModel-Bottom-btn-ok" onClick={deleteProperties}>Ok</button>
                        <button className="CModel-Bottom-btn-cancel" onClick={()=>{setVisibleDelete(false)}}>Cansel</button>
                    </div>
                </CModal>

                <CModal visible={visibleAdd} setVisible={setVisibleAdd} title="Add"> 
                   <PropertiesAdd setVisibleAdd={setVisibleAdd} getData={getData} />
                </CModal>


                
                <div className="pagelist">
                    <div className="pagelist-head">
                        <span>Properties</span>
                        <button onClick={()=>{setVisibleAdd(true)}} className="pagelist-head-button">Add properties</button>
                    </div>
                    {listData.length > 0 && 
                        <Table range={10} columns={col} data={listData} />
                    }
                </div>
            </div> 
        </>
    )
}

export default Properties;
import React, { useEffect, useState } from "react" ;
import {ToastContainer} from "react-toastify";
import Toast_noty from '../../../../widget/Toast_noty'
import Loader from "../../../../widget/Loader/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faSearch, faTable , faStar, faHeart, faMusic, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import {Get_Category_Album , Get_Gender_Album , Add_Album_One} from '../../../../widget/API';
import {Admin_Get_Data_Add_Banner_List , Admin_Add_Album_One} from '../../../../widget/API_Admin'
import { Select , DatePicker, Form , Input ,InputNumber} from 'antd';
import moment from "moment";
import AlbumAutoFields from '../../AlbumAutoFields';




function AlbumAddStepOne(props) {
    const[dispaly , setDisplay]=useState(false);
    const[listCat , setListCat]=useState([]);
    const[listGender , setListGender]=useState([]);
    const[listArtist , setListArtist]=useState([]);
    const { Option } = Select;


    const onFinish=(values)=>{
        if(values && values.labels){
            values['labels'] =  Object.entries(values.labels).map(([key , value])=>{
                if(value && value._d){
                    return {
                        "id" : key,
                        "value" : String(moment(value._d).format('YYYY-MM-DD hh:mm')),
                    }
                }
                else {
                    return {
                        "id" : key,
                        "value" : String(value),
                    }
                }
                
            })
        }
        setDisplay(true);
        const returndata =  Admin_Add_Album_One(values);
        returndata.then((res)=>{
        setDisplay(false)
        if(res.result == 'ok'){
            props.setStep(2);
            props.setAlbumId(res.data.id);
            props.setArtistID(res.data.artist_id)
            props.setAlbumName(res.data.name);
        }
        else 
        {
            Toast_noty(res.error, 5000, "error");
        }
        })
        returndata.catch((er)=>{
            setDisplay(false);
            Toast_noty('please try again', 5000, "error");
        })
    }


    const getCategory=()=>{
        const returndata =  Get_Category_Album();
        returndata.then((res)=>{
        setDisplay(false);
        if(res.result == 'ok'){
            setListCat(res.data)
        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
         returndata.catch((er)=>{
            setDisplay(false);
            Toast_noty('please try again', 5000, "error");
        })
    }

    const getGender=()=>{
        const returndata =  Get_Gender_Album();
        returndata.then((res)=>{
        setDisplay(false);
        if(res.result == 'ok'){
            setListGender(res.data)
        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
         returndata.catch((er)=>{
            setDisplay(false);
            Toast_noty('please try again', 5000, "error");
        })
    }

    const getArtist=()=>{
        const returndata =  Admin_Get_Data_Add_Banner_List('artist');
        returndata.then((res)=>{
        if(res.result == 'ok'){
            setListArtist(res.data)
        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
         returndata.catch((er)=>{
            setDisplay(false);
            Toast_noty('please try again', 5000, "error");
        })
    }
 
    useEffect(()=>{
        getCategory();
        getGender();
        getArtist()
    },[])


    return (
            <div className="addalbum-cont">
                {dispaly && <Loader/>}
                {/* <div className="addalbum-cont-head">
                    <h3>Add album</h3>
                </div> */}
                <Form onFinish={onFinish} className="addalbum-cont-form">
                    <div className="addalbum-cont-form-section">

                        <div className="addalbum-cont-form-section-item">
                            <span>Artist</span>
                            <Form.Item name="artist" rules={[{ required: true}]}>
                                <Select
                                    allowClear
                                    >
                                    {listArtist.map(item=>
                                        <Option value={item.id}>{item.name}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </div>

                        <div className="addalbum-cont-form-section-item">
                            <span>Album name</span>
                            <Form.Item name="name" rules={[{ required: true}]}>
                                <Input />
                            </Form.Item>
                        </div>

                        <div className="addalbum-cont-form-section-item">
                            <span>Cover producer</span>
                            <Form.Item name="cover_producer" rules={[{ required: true}]}>
                                <Input />
                            </Form.Item>
                        </div>

                        <AlbumAutoFields/>

                        <div className="addalbum-cont-form-section-item">
                            <span>Release time</span>
                            <Form.Item name="release_time" rules={[{ required: true}]}>
                                <DatePicker  />
                            </Form.Item>
                        </div>

                        <div className="addalbum-cont-form-section-item">
                            <span>Category</span>
                            <Form.Item name="category" rules={[{ required: true}]}>
                                <Select
                                    mode="multiple"
                                    allowClear
                                    >
                                    {listCat.map(item=>
                                        <Option value={item.id}>{item.name}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </div>

                        <div className="addalbum-cont-form-section-item">
                            <span>Genre</span>
                            <Form.Item name="gender" rules={[{ required: true}]}>
                                <Select
                                    >
                                    {listGender.map(item=>
                                        <Option value={item.id}>{item.name}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </div>

                        <div className="addalbum-cont-form-section-item">
                            <span>Pay status</span>
                            <Form.Item name="pay" rules={[{ required: true}]}>
                                <Select
                                    allowClear
                                    onChange={(value)=>{props.setAlbumPay(value)}}
                                    >
                                        <Option value={true}>Yes</Option>
                                        <Option value={false}>No</Option>
                                </Select>
                            </Form.Item>
                        </div>

                        {props.albumPay && 
                        <div className="addalbum-cont-form-section-item">
                            <span>Rial price</span>
                            <Form.Item name="Rial_price" rules={[{ required: true}]}>
                                <InputNumber />
                            </Form.Item>
                        </div>
                        }

                        {props.albumPay && 
                        <div className="addalbum-cont-form-section-item">
                            <span>Dollar price</span>
                            <Form.Item name="Dollar_price" rules={[{ required: true}]}>
                                <InputNumber />
                            </Form.Item>
                        </div>
                        }

                        {props.albumPay && 
                        <div className="addalbum-cont-form-section-item">
                            <span>Vip</span>
                            <Form.Item name="vip" rules={[{ required: true}]}>
                                <Select
                                    allowClear
                                    >
                                        <Option value={true}>Yes</Option>
                                        <Option value={false}>No</Option>
                                </Select>
                            </Form.Item>
                        </div>
                        }


                    </div>
                    <div className="addalbum-cont-form-footer"><button className="addalbum-cont-form-footer-btn-next">Next Step</button></div>
                </Form>
            </div>
    )
}

export default AlbumAddStepOne;
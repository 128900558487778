import React, { useEffect, useState } from "react" ;
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV , faEdit , faTrash , faUpload} from "@fortawesome/free-solid-svg-icons";
import Table from "../../../widget/Table/Table";
import {
    Admin_Get_All_Label ,
    Admin_Add_Label ,
    Admin_Delete_Label , 
    Admin_Get_All_Properties ,
    Admin_Edit_Label
} from '../../../widget/API_Admin';
import Toast_noty from '../../../widget/Toast_noty'
import Loader from '../../../widget/Loader/Loader'
import CModal from "../../../widget/CModal/CModal";
import '../manageFieldStyle.css';
import {Select , Form} from "antd";




function Label(props) {
    const[display , setDisplay]=useState(false);
    const[visibleDelete , setVisibleDelete]=useState(false);
    const[visibleAdd , setVisibleAdd]=useState(false);
    const[visibleEdit , setVisibleEdit]=useState(false);
    const[deleteId , setDeleteId]=useState(null)
    const[editId , setEditId]=useState(null)
    const[listData , setListData]=useState([]);
    const[listPeroperty , setListPeroperty]=useState([]);
    const[actionList , setActionList]=useState(-1);
    const[typeTable , setTypeTable]=useState('all');
    const [form] = Form.useForm();
    const {Option} = Select ;




    const changeDisplayActionList=(id)=>{
        if(actionList == id){
            setActionList(-1)
        }
        else {
            setActionList(id)
        }
    }


    const openDeleteModal=(id)=>{
        setDeleteId(id)
        setVisibleDelete(true)
    }

    const deleteLabel=()=>{
        setDisplay(true);
        setVisibleDelete(false)
        const returndata =  Admin_Delete_Label(deleteId);
        returndata.then(async(res)=>{
        setDisplay(false);
        if(res.result == 'ok'){
            await getData(typeTable)
            setVisibleDelete(false)
            Toast_noty("Delete success", 10000, "success");
        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
         returndata.catch((er)=>{
            setDisplay(false);
            Toast_noty('please try again', 5000, "error");
        })
    }



    const onFinishAdd=(values)=>{
        setDisplay(true);
        const returndata =  Admin_Add_Label(values);
        returndata.then(async(res)=>{
        setDisplay(false);
        if(res.result == 'ok'){
            await getData(typeTable)
            setVisibleAdd(false)
            Toast_noty("success", 10000, "success");
        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
         returndata.catch((er)=>{
            setDisplay(false);
            Toast_noty('please try again', 5000, "error");
        })
    }

    const openEditModal=(i)=>{
        setEditId(i.id)
        form.setFieldsValue({'type' : i.type.substring(11)})
        form.setFieldsValue({'peroperty' : parseInt(i.property.id)})
        form.setFieldsValue({'requirement' : i.requirement})
        setVisibleEdit(true)
    }

    const onFinishEdit=(values)=>{
        setDisplay(true);
        const returndata =  Admin_Edit_Label(values , editId);
        returndata.then(async(res)=>{
        setDisplay(false);
        if(res.result == 'ok'){
            await getData(typeTable)
            setVisibleEdit(false)
            Toast_noty("success", 10000, "success");
        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
         returndata.catch((er)=>{
            setDisplay(false);
            Toast_noty('please try again', 5000, "error");
        })
    }

    const filterList=async(value)=>{
        await setTypeTable(value)
        getData(value)
    }

    const col = [
        {
            title:"id" ,
            render:(i) => (<>{i.id != null && i.id}</>)
        },
        {
            title:"Type" ,
            render:(i) => (<>{i.type != null && i.type.substring(11)}</>)
        },
        {
            title:"Property name" ,
            render:(i) => (<>{i.property != null && i.property.name}</>)
        },
        {
            title:"Property type" ,
            render:(i) => (<>{i.property != null && i.property.type}</>)
        },
        {
            title:"Requirement" ,
            render:(i) => (<>{i.requirement ? "Yes" : "No"}</>)
        },
        {
            title:"Action" ,
            render:(i) => (
            <div id="table-action" className="table-action">
                <div className="table-action-icon" onClick={()=>changeDisplayActionList(i.id)}>
                    <FontAwesomeIcon icon={faEllipsisV}  />
                </div>
                <div  className={`table-action-list ${actionList == i.id && 'table-action-list-active'}`}>
                    <div onClick={()=>openDeleteModal(i.id)} className="table-action-list-item">
                        <FontAwesomeIcon icon={faTrash} />
                        <span>Delete</span>
                    </div>
                    <div onClick={()=>openEditModal(i)} className="table-action-list-item">
                        <FontAwesomeIcon icon={faEdit} />
                        <span>Edit</span>
                    </div>
                </div>
            </div>
            )
        },
    ]


    const getData=(type)=>{
        setDisplay(true)
        const returndata =  Admin_Get_All_Label(type);
        returndata.then((res)=>{
        setDisplay(false)
        if(res.result == 'ok'){
            setListData(res.data)
        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
         returndata.catch((er)=>{
            Toast_noty('please try again', 5000, "error");
        })
    }


    const getListPeroperty=()=>{
        setDisplay(true)
        const returndata =  Admin_Get_All_Properties();
        returndata.then((res)=>{
        setDisplay(false)
        if(res.result == 'ok'){
            setListPeroperty(res.data)
        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
         returndata.catch((er)=>{
            Toast_noty('please try again', 5000, "error");
        })
    }



    useEffect(()=>{
        getData(typeTable)
        getListPeroperty()
    },[])


    return (
        <>
            <div className="search-list">
                <div className="search-list-head"><span>Search</span></div>
                <div className="search-list-main">
                    <div className="search-list-main-item" >
                        <span>Type</span>
                        <Select
                            allowClear
                            onChange={(value)=>filterList(value)}
                            >
                                <Option value={"all"}>All</Option>
                                <Option value={"Album"}>Album</Option>
                                <Option value={"Track"}>Track</Option>

                        </Select>
                    </div>
                </div>
            </div>
            <div className="fullPage">
                {display && <Loader/>}

                <CModal visible={visibleDelete} setVisible={setVisibleDelete} title="Delete"> 
                    <p>Are you sure delete label {deleteId} ?</p>
                    <div className="CModel-Bottom">
                        <button className="CModel-Bottom-btn-ok" onClick={deleteLabel}>Ok</button>
                        <button className="CModel-Bottom-btn-cancel" onClick={()=>{setVisibleDelete(false)}}>Cansel</button>
                    </div>
                </CModal>

                <CModal visible={visibleAdd} setVisible={setVisibleAdd} title="Add"> 
                    <Form onFinish={onFinishAdd} className="manageField-modal-form">

                        <div className="manageField-modal-form-item">
                            <span>type</span>
                            <Form.Item name="type" >
                                <Select  rules={[{ required: true}]}>
                                    <Option value="Album">Album</Option>
                                    <Option value="MusicVideo">MusicVideo</Option>
                                    <Option value="Podcast">Podcast</Option>
                                    <Option value="Track">Track</Option>
                                    <Option value="Movie">Movie</Option>
                                    <Option value="Series">Series</Option>
                                    <Option value="Episode">Episode</Option>

                                </Select>
                            </Form.Item>
                        </div>

                        <div className="manageField-modal-form-item">
                            <span>peroperty</span>
                            <Form.Item name="peroperty" >
                                <Select  rules={[{ required: true}]}>
                                    <Option value="">--</Option>
                                    {listPeroperty.map(item=>
                                        <Option value={item.id}>{item.name}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </div>

                        <div className="manageField-modal-form-item">
                            <span>Requirement</span>
                            <Form.Item name="requirement" >
                                <Select  rules={[{ required: true}]}>
                                    <Option value={true}>Yes</Option>
                                    <Option value={false}>No</Option>
                                </Select>
                            </Form.Item>
                        </div>

                        <div className="manageField-modal-form-item">
                            <button>Submit</button>
                        </div>
                    </Form>
                </CModal>


                <CModal visible={visibleEdit} setVisible={setVisibleEdit} title="Edit"> 
                    <Form form={form} onFinish={onFinishEdit} className="manageField-modal-form">

                        <div className="manageField-modal-form-item">
                            <span>type</span>
                            <Form.Item name="type" >
                                <Select  rules={[{ required: true}]}>
                                    <Option value="Album">Album</Option>
                                    <Option value="MusicVideo">MusicVideo</Option>
                                    <Option value="Podcast">Podcast</Option>
                                    <Option value="Track">Track</Option>
                                    <Option value="Movie">Movie</Option>
                                    <Option value="Series">Series</Option>
                                    <Option value="Episode">Episode</Option>
                                </Select>
                            </Form.Item>
                        </div>

                        <div className="manageField-modal-form-item">
                            <span>peroperty</span>
                            <Form.Item name="peroperty" >
                                <Select  rules={[{ required: true}]}>
                                    <Option value="">--</Option>
                                    {listPeroperty.map(item=>
                                        <Option value={item.id}>{item.name}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </div>

                        <div className="manageField-modal-form-item">
                            <span>Requirement</span>
                            <Form.Item name="requirement" >
                                <Select  rules={[{ required: true}]}>
                                    <Option value={true}>Yes</Option>
                                    <Option value={false}>No</Option>
                                </Select>
                            </Form.Item>
                        </div>

                        <div className="manageField-modal-form-item">
                            <button>Submit</button>
                        </div>
                    </Form>
                </CModal>

                
                <div className="pagelist">
                    <div className="pagelist-head">
                        <span>Label</span>
                        <button onClick={()=>{setVisibleAdd(true)}} className="pagelist-head-button">Add Label</button>
                    </div>
                    {listData.length > 0 && 
                        <Table range={10} columns={col} data={listData} />
                    }
                </div>
            </div> 
        </>
    )
}

export default Label;
import { useState } from "react";
import Table from "../../../../widget/Table/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import {Admin_Get_Episode_File} from '../../../../widget/API_Admin'
import CModal from "../../../../widget/CModal/CModal";
import Loader from "../../../../widget/Loader/Loader";


function SeriesAddStepTwoListEpisode(props) {
    const[dispaly , setDisplay]=useState(false);
    const[visible , setVisible]=useState(false);
    const[episodeFile , setEpisodeFile]=useState(null)



    const playEpisode=(id)=>{
        setDisplay(true)
        const returndata =  Admin_Get_Episode_File(props.seriesId , id);
        returndata.then((res)=>{
            setDisplay(false)
            setEpisodeFile(res)
            setVisible(true);
        })
    }


    const emptyFields=()=>{
        setEpisodeFile(null)
    }


    const col = [
        {
            title:"Episode number" ,
            sort:'episode_number' ,
            render:(i) => (<>{i.episode_number != null && i.episode_number}</>)
        },
        {
            title:"Season number" ,
            sort:'season_number' ,
            render:(i) => (<>{i.season_number  != null && i.season_number	}</>)
        },
        {
            title:"Play" ,
            render:(i) => (<div className="table-play" onClick={()=>playEpisode(i.id)}><FontAwesomeIcon icon={faPlay} /></div>)
        },
        
    ]


    return (
        <div className="fullPage">
            {dispaly && <Loader/>}
            <CModal onScap={emptyFields} visible={visible} setVisible={setVisible} title="episode"> 
                {episodeFile != null && 
                    <video  controls>
                        <source src={episodeFile} type="video/mp4" />
                    </video> 
                }
            </CModal>
            <div className="pagelist">
                <Table range={10} columns={col} data={props.listEpisode} />
            </div>
        </div>    
    )
}

export default SeriesAddStepTwoListEpisode;
import React, { useEffect, useState } from "react" ;
import {ToastContainer} from "react-toastify";
import Toast_noty from '../../../../widget/Toast_noty'
import Profile from '../../../../static/img/profile.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faFileImage, faHeart, faMailBulk, faMoneyBill, faPenAlt, faPencilAlt, faPhone, faUser } from "@fortawesome/free-solid-svg-icons";
import CModal from "../../../../widget/CModal/CModal";
import { Link , Redirect} from "react-router-dom";
import Loader from '../../../../widget/Loader/Loader';
import ImagePodcast from "../../../../widget/ImagePodcast";
import moment from "moment";
import ImgCrop from 'antd-img-crop';
import { Upload , Form , Input ,DatePicker} from 'antd';
import {Admin_Delete_Podcast , Admin_Update_Cover_Podcast} from '../../../../widget/API_Admin'
import Placeholder from '../../../../static/img/placeholder.png'
import {Image} from 'antd'
import {BASE_Medai_URL} from '../../../../widget/BASE_Medai_URL'


function PodcastDetailHead(props) {
    const[dispaly , setDisplay]=useState(false);
    const[redirect , setRedirect]=useState(false);
    const[visibleDelete , setVisibleDelete]=useState(false);
    const [fileList, setFileList] = useState([]);


    // Function For Upload Handel

    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
      };
    
    const onPreview = async file => {
        let src = file.url;
        if (!src) {
          src = await new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
          });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
      };

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
          onSuccess("ok");
        }, 0);
      };

    // End Function For Upload 


    const uploadCoverPodcast=()=>{
        if(fileList.length > 0){
            setDisplay(true);
            const returndata =  Admin_Update_Cover_Podcast(props.podcast.id, fileList , props.podcast.artist_id);
            returndata.then(async(res)=>{
            setDisplay(false);
            if(res.result == 'ok'){
                Toast_noty("Changes saved successfully", 10000, "success");
            }
            else if(res.error == 'unauthenticated') {
                localStorage.clear();
                props.history.push('/');  
            }
            else {
                Toast_noty(res.error, 5000, "error");
            }
            })
            returndata.catch((er)=>{
                setDisplay(false);
                Toast_noty('please try again', 5000, "error");
            })
        }
    }


    const onFinishDeletePodcast=()=>{
        const returndata =  Admin_Delete_Podcast(props.podcast.id , props.podcast.artist_id );
        returndata.then((res)=>{
        setDisplay(false);
        if(res.result == 'ok'){
            setVisibleDelete(false)
            setRedirect(true)
        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
            returndata.catch((er)=>{
            Toast_noty('please try again', 5000, "error");
        })
    }

    return (
              <div className="podcastdetail-head">
                    {dispaly && <Loader/>}
                    {redirect && <Redirect to='/panel/confirmation/podcast' />}
                    <CModal visible={visibleDelete} setVisible={setVisibleDelete} title="Delete"> 
                        <p>Are you sure delete podcast ?</p>
                        <div className="CModel-Bottom">
                            <button className="CModel-Bottom-btn-ok" onClick={onFinishDeletePodcast} >Ok</button>
                            <button className="CModel-Bottom-btn-cancel" onClick={()=>{setVisibleDelete(false)}}>Cansel</button>
                        </div>
                    </CModal>
                  <div className="podcastdetail-head-right">
                      <div className="podcastdetail-head-right-profile">
                          <div className="podcastdetail-head-right-profile-crop">
                            <ImgCrop aspect={2/1}>
                                <Upload
                                className="mt-2"
                                accept=".png , .jpg , .jpeg , .pdf "
                                listType="picture-card"
                                fileList={fileList}
                                onChange={onChange}
                                onPreview={onPreview}
                                customRequest={dummyRequest}
                                showUploadList={{showPreviewIcon:false}}
                                >
                                    {fileList.length < 1 &&
                                        <Image 
                                            src={`${BASE_Medai_URL}storage/cover/artist/${props.podcast.artist_id}/podcast/${props.podcast.id}`}
                                            fallback = {Placeholder}
                                            preview={false}
                                        />
                                    }
                                </Upload>
                            </ImgCrop>
                          </div>
                          
                          <div className="podcastdetail-head-right-profile-info" >
                              <h3>{props.podcast.name} </h3>
                              <span>{moment(props.podcast.release_time).format('YYYY-MM-DD hh:mm')}</span>
                              <div>
                                        <>
                                        {fileList.length > 0 && 
                                        <button onClick={uploadCoverPodcast} className="podcastdetail-head-right-profile-div-edit-btn">Upload</button>
                                        }
                                        </>
                                        <button onClick={()=>{setVisibleDelete(true)}} className="podcastdetail-head-right-profile-div-del-btn">Delete</button>
                              </div>
                          </div>
                      </div>
                      <div className="podcastdetail-head-right-bottom">
                          <div className="podcastdetail-head-right-bottom-item">
                              <div className="podcastdetail-head-right-bottom-item-icon">
                                  <FontAwesomeIcon icon={faHeart} />
                              </div>
                              <div className="podcastdetail-head-right-bottom-item-des">
                                  <h5>{props.podcast.likes_count}</h5>
                                  <span>likes count</span>
                              </div>
                          </div>
                          {/* <div className="podcastdetail-head-right-bottom-item">
                              <div className="podcastdetail-head-right-bottom-item-icon">
                                  <FontAwesomeIcon icon={faHeart} />
                              </div>
                              <div className="podcastdetail-head-right-bottom-item-des">
                                  <h5>{props.podcast.fake_like} </h5>
                                  <span>fake like</span>
                              </div>
                          </div> */}
                      </div>
                  </div>
                  <div className="podcastdetail-head-left">
                  <div className="podcastdetail-head-left-item"> 
                            <Link to={`/panel/podcast/edit/${props.podcast.id}/${props.podcast.artist_id}`}>
                                <div className="podcastdetail-head-left-item-left" >
                                    <FontAwesomeIcon icon={faEdit} />
                                    <span>Edit </span>
                                </div>
                            </Link>
                          {/* <span>{props.podcast.creator.name}</span> */}
                      </div>
                      <div className="podcastdetail-head-left-item">
                          <div className="podcastdetail-head-left-item-left">
                              <FontAwesomeIcon icon={faHeart} />
                              <span>Creator </span>
                          </div>
                          <span>{props.podcast.creator.name}</span>
                      </div>
                      <div className="podcastdetail-head-left-item">
                          <div className="podcastdetail-head-left-item-left">
                              <FontAwesomeIcon icon={faUser} />
                              <span>Cover producer </span>
                          </div>
                          <span>{props.podcast.cover_producer}</span>
                      </div>
                      <div className="podcastdetail-head-left-item">
                          <div className="podcastdetail-head-left-item-left">
                              <FontAwesomeIcon icon={faUser} />
                              <span>Editor </span>
                          </div>
                          <span>{props.podcast.editor}</span>
                      </div>
                      
                      <div className="podcastdetail-head-left-item">
                          <div className="podcastdetail-head-left-item-left">
                              <FontAwesomeIcon icon={faHeart} />
                              <span>Status </span>
                          </div>
                          <span>{props.podcast.status}</span>
                      </div>
                     
                      {props.podcast.status == 'rejected' && 
                        <div className="podcastdetail-head-left-item">
                            <div className="podcastdetail-head-left-item-left">
                                <FontAwesomeIcon icon={faPencilAlt} />
                                <span>Reson </span>
                            </div>
                            <span>{props.podcast.reject_message}</span>
                        </div>
                      }

                  </div>
              </div>     
    )
}

export default PodcastDetailHead;
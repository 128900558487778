import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react" ;
import {ToastContainer} from "react-toastify";
import Toast_noty from "../../../widget/Toast_noty";
import Loader from '../../../widget/Loader/Loader';
import AddDynamicAllMain from "./widget/AddDynamicAllMain";
import AddDynamicAllBar from './widget/AddDynamicAllBar'
import AddDynamicAllFooter from "./widget/AddDynamicAllFooter";
import AddDynamicAllResult from './widget/AddDynamicAllResult';
import {Admin_Get_List_Main_Dynamic} from '../../../widget/API_Admin';
import '../dynamicStyle.css';

function DynamicAllAdd(props) {
    const[dispaly , setDisplay]=useState(false);
    const[listData , setListData]=useState([]);
    const[activeTab , setActiveTab]=useState(-1);
    const[mainState , setMainState]=useState({});
    const[resultFilter , setResultFilter]=useState([]);



    useEffect(()=>{
        setDisplay(true);
        const returndata =  Admin_Get_List_Main_Dynamic();
        returndata.then((res)=>{
        setDisplay(false);
        if(res.result == 'ok'){
            setListData(res.data)
            if(res.data.length > 0){
                setActiveTab(res.data[0].name.substring(11))
            }
        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
            returndata.catch((er)=>{
            setDisplay(false);
            Toast_noty('please try again', 5000, "error");
        })
    },[])


    return (
        <div className="dynamic">
            {dispaly && <Loader/>}
            {/* <button onClick={()=>{console.log(mainState);}}>mainState</button> */}

            {resultFilter.length == 0 && 
                <>
                    <AddDynamicAllBar activeTab={activeTab} setActiveTab={setActiveTab} listData={listData} />
                    <AddDynamicAllMain mainState={mainState} setMainState={setMainState} activeTab={activeTab} listData={listData} />
                    <AddDynamicAllFooter setResultFilter={setResultFilter} listData={listData}  mainState={mainState} activeTab={activeTab} />
                </>
            }
            <AddDynamicAllResult resultFilter={resultFilter}/>  
        </div>
    )
}

export default DynamicAllAdd;
import React, { useEffect, useState } from "react" ;
import FileMovies from '../../../../widget/FileMovies'
import { BASE_Medai_URL } from "../../../../widget/BASE_Medai_URL";
import { BASE_Medai_Video } from "../../../../widget/BASE_Medai_Video";



function MoviesDetailCont(props) {


    return (
        <>
            <div className="fullPage">   
            {props.movies != null && 
            <div className="pagelist">
                <div className="pagelist-head">
                    <span>Video</span>
                </div>
                <div className="musicvideodetail-prev-video">
                    {/* <FileMovies movies={props.movies.id} /> */}
                    <video  controls>
                        <source src={`${BASE_Medai_Video}${props.movies.url}`} type="video/mp4" />
                    </video>
                </div>
            </div>
            }
            </div>  
        </>
    )
}

export default MoviesDetailCont;
import React, { useEffect, useRef, useState } from "react" ;
import Toast_noty from '../../../widget/Toast_noty'
import Loader from "../../../widget/Loader/Loader";
import { Select , DatePicker, Space , InputNumber , Form , Input} from 'antd';
import moment from "moment";
import {Get_Category_Album , Get_Gender_Album , Get_Album , Edit_Album} from '../../../widget/API';
import {Admin_Edit_Album} from '../../../widget/API_Admin'
import AlbumAutoFields from '../AlbumAutoFields';
import '../albumStyle.css';




function AlbumEdit(props) {
    const[dispaly , setDisplay]=useState(false);
    const[listCat , setListCat]=useState([]);
    const[listGender , setListGender]=useState([]);
    const [album , setAlbum]=useState(null);
    const [payment , setPayment]=useState(false);


    const { Option } = Select;
    const [form] = Form.useForm();


    const onFinishEdit=(values)=>{
        if(values && values.labels){
            values['labels'] =  Object.entries(values.labels).map(([key , value])=>{
                if(value && value._d){
                    return {
                        "id" : key,
                        "value" : String(moment(value._d).format('YYYY-MM-DD hh:mm')),
                    }
                }
                else {
                    return {
                        "id" : key,
                        "value" : String(value),
                    }
                }
                
            })
        }
        values.release_time = moment(values.release_time._d).format('YYYY-MM-DD hh:mm')
        setDisplay(true);
        const returndata =  Admin_Edit_Album(values , props.match.params.id , album.artist_id , payment);
        returndata.then((res)=>{
        setDisplay(false);
        if(res.result == 'ok'){
            props.history.push(`/panel/album/detail/${props.match.params.id}`);  
        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
         returndata.catch((er)=>{
            setDisplay(false);
            Toast_noty('please try again', 5000, "error");
        })
    }


    const getCategory=()=>{
        setDisplay(true);
        const returndata =  Get_Category_Album();
        returndata.then((res)=>{
        setDisplay(false);
        if(res.result == 'ok'){
            setListCat(res.data)
        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
         returndata.catch((er)=>{
            setDisplay(false);
            Toast_noty('please try again', 5000, "error");
        })
    }

    const getGender=()=>{
        const returndata =  Get_Gender_Album();
        returndata.then((res)=>{
        setDisplay(false);
        if(res.result == 'ok'){
            setListGender(res.data)
        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
         returndata.catch((er)=>{
            setDisplay(false);
            Toast_noty('please try again', 5000, "error");
        })
    }


    const getDetailAlbum=()=>{
        if(props.match.params.id){
            const returndata =  Get_Album(props.match.params.id);
            returndata.then((res)=>{
            if(res.result == 'ok'){
                setAlbum(res.data)
                form.setFieldsValue({'name' : res.data.name})
                form.setFieldsValue({'cover_producer' : res.data.cover_producer})
                form.setFieldsValue({'release_time' : moment(res.data.release_time)})
                form.setFieldsValue({'gender_id' : res.data.gender_id})
                form.setFieldsValue({'categories' : res.data.categories.map(item=>{
                    return parseInt(item.id)
                })})
                if(res.data.product != undefined && res.data.product != null){
                    setPayment(true);
                    form.setFieldsValue({'pay' : true})
                    form.setFieldsValue({'Rial_price' : res.data.product.price_rial})
                    form.setFieldsValue({'Dollar_price' : res.data.product.price_dollar})
                    form.setFieldsValue({'vip' : res.data.product.vip})
                }
                if(res.data.labels){
                    for(let i of res.data.labels){
                        if(i.label && i.label != null && i.label.property.type == 'enum' && i.label.property.choose == 'single'){
                            form.setFieldsValue({ 'labels' : {[i.label_value_id] : parseInt(i.value)}})
                        }
                        else if(i.label && i.label != null && i.label.property.type == 'enum' && i.label.property.choose == 'multi'){
                            form.setFieldsValue({ 'labels' : {[i.label_value_id] : 
                                i.value.split(",").map(item=>{
                                    return parseInt(item)
                                })
                            }})
                        }
                        else if (i.label && i.label != null && i.label.property.type == 'date_time') {
                            form.setFieldsValue({ 'labels' : {[i.label_value_id] : moment(i.value)}})
                        }
                        else if(i.label && i.label != null ) {
                            form.setFieldsValue({ 'labels' : {[i.label_value_id] : i.value}})
                        }
                    }
                }

            }
            else if(res.error == 'unauthenticated') {
                localStorage.clear();
                props.history.push('/');  
            }
            else {
                Toast_noty(res.error, 5000, "error");
            }
            })
             returndata.catch((er)=>{
                Toast_noty('please try again', 5000, "error");
            })
        }
    }

    useEffect(()=>{
        getCategory();
        getGender();
        if(props.match.params.id){
            getDetailAlbum()
        }
    },[props.match.params.id])

    return (
        <div className="albumdetail">
            {dispaly && <Loader/>}
            <div className="addalbum-cont">
                <Form form={form} onFinish={onFinishEdit} className="addalbum-cont-form">
                    <div className="addalbum-cont-form-section">
                        <div className="addalbum-cont-form-section-item">
                            <span>Album name</span>
                            <Form.Item name="name" >
                                <Input />
                            </Form.Item>
                        </div>
                        <div className="addalbum-cont-form-section-item">
                            <span>Cover producer</span>
                            <Form.Item name="cover_producer" >
                                <Input />
                            </Form.Item>
                        </div>

                        <AlbumAutoFields/>

                        <div className="addalbum-cont-form-section-item">
                            <span>Date Time</span>
                            <Form.Item name="release_time">
                                <DatePicker />
                            </Form.Item>
                        </div> 

                        <div className="addalbum-cont-form-section-item">
                            <span>Category</span>
                            <Form.Item name="categories" >
                                <Select
                                    mode="multiple"
                                    allowClear
                                    >
                                    {listCat.map(item=>
                                        <Option value={item.id}>{item.name}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </div>

                        <div className="addalbum-cont-form-section-item">
                            <span>Gender</span>
                            <Form.Item name="gender_id" >
                                <Select
                                    allowClear
                                    >
                                    {listGender.map(item=>
                                        <Option value={item.id}>{item.name}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </div>

                        <div className="addalbum-cont-form-section-item">
                            <span>Pay status</span>
                            <Form.Item name="pay" rules={[{ required: true}]}>
                                <Select
                                    allowClear
                                    onChange={(value)=>{setPayment(value)}}
                                    >
                                        <Option value={true}>Yes</Option>
                                        <Option value={false}>No</Option>
                                </Select>
                            </Form.Item>
                        </div>

                        {payment == true && 
                        <div className="addalbum-cont-form-section-item">
                            <span>Rial price</span>
                            <Form.Item name="Rial_price" rules={[{ required: true}]}>
                                <InputNumber />
                            </Form.Item>
                        </div>
                        }

                        {payment == true && 
                        <div className="addalbum-cont-form-section-item">
                            <span>Dollar price</span>
                            <Form.Item name="Dollar_price" rules={[{ required: true}]}>
                                <InputNumber />
                            </Form.Item>
                        </div>
                        }

                        {payment == true && 
                        <div className="addalbum-cont-form-section-item">
                            <span>Vip</span>
                            <Form.Item name="vip" rules={[{ required: true}]}>
                                <Select
                                    allowClear
                                    >
                                        <Option value={true}>Yes</Option>
                                        <Option value={false}>No</Option>
                                </Select>
                            </Form.Item>
                        </div>
                        }

                    </div>
                    <div className="addalbum-cont-form-footer"><button className="addalbum-cont-form-footer-btn-next">Edit</button></div>
                </Form>
            </div>
        </div>
    )
}

export default AlbumEdit;
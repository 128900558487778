import {  faTachometerAlt, faCircleNotch, faCheck, faImage, faTh,faImages,  faListAlt, faList, faTasks, faFilm} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { render } from "@testing-library/react";
import React  from "react" ;


const NavData = [
    {
        'name':"Dashboard" ,
        'isSubmenu': false ,
        'link':'/panel' ,
        "icon":(<FontAwesomeIcon icon={faTachometerAlt} />),
    },
    {
        'name':"Dynamic" ,
        'isSubmenu': true ,
        "icon":(<FontAwesomeIcon icon={faTh} />),
        'submenu':[
            {
                'name':"Dynamic All Add" ,
                'link':'/panel/dynamic/all/add' ,
                "icon":(<FontAwesomeIcon icon={faCircleNotch} />),
            },
            {
                'name':"Dynamic Album Add" ,
                'link':'/panel/dynamic/album/add' ,
                "icon":(<FontAwesomeIcon icon={faCircleNotch} />),
            },
            {
                'name':"Dynamic Artist Add" ,
                'link':'/panel/dynamic/artist/add' ,
                "icon":(<FontAwesomeIcon icon={faCircleNotch} />),
            },
            {
                'name':"Dynamic Podcast Add" ,
                'link':'/panel/dynamic/podcast/add' ,
                "icon":(<FontAwesomeIcon icon={faCircleNotch} />),
            },
            {
                'name':"Dynamic Music Video Add" ,
                'link':'/panel/dynamic/musicvideo/add' ,
                "icon":(<FontAwesomeIcon icon={faCircleNotch} />),
            },
            {
                'name':"Dynamic Movie Add" ,
                'link':'/panel/dynamic/movie/add' ,
                "icon":(<FontAwesomeIcon icon={faCircleNotch} />),
            },
            {
                'name':"Dynamic Series Add" ,
                'link':'/panel/dynamic/series/add' ,
                "icon":(<FontAwesomeIcon icon={faCircleNotch} />),
            },
            {
                'name':"Dynamic List" ,
                'link':'/panel/dynamic/all/list' ,
                "icon":(<FontAwesomeIcon icon={faCircleNotch} />),
            },     
        ]
    },
    {
        'name':"Confirmation" ,
        'isSubmenu': true ,
        "icon":(<FontAwesomeIcon icon={faCheck} />),
        'submenu':[
            {
                'name':"Albums" ,
                'link':'/panel/confirmation/album' ,
                "icon":(<FontAwesomeIcon icon={faCircleNotch} />),
            },
            {
                'name':"Users" ,
                'link':'/panel/confirmation/user' ,
                "icon":(<FontAwesomeIcon icon={faCircleNotch} />),
            },
            {
                'name':"Podcast" ,
                'link':'/panel/confirmation/podcast' ,
                "icon":(<FontAwesomeIcon icon={faCircleNotch} />),
            },
            {
                'name':"MusicVideo" ,
                'link':'/panel/confirmation/musicvideo',
                "icon":(<FontAwesomeIcon icon={faCircleNotch} />),
            },
        ]
    },
    {
        'name':"Manage Fields" ,
        'isSubmenu': true ,
        "icon":(<FontAwesomeIcon icon={faTasks} />),
        'submenu':[
            {
                'name':"Properties" ,
                'link':'/panel/properties' ,
                "icon":(<FontAwesomeIcon icon={faCircleNotch} />),
            },
            {
                'name':"Label" ,
                'link':'/panel/label' ,
                "icon":(<FontAwesomeIcon icon={faCircleNotch} />),
            },
        ]
    },
    {
        'name':"Movies & Series" ,
        'isSubmenu': true ,
        "icon":(<FontAwesomeIcon icon={faFilm} />),
        'submenu':[
            {
                'name':"Add Movies" ,
                'link':'/panel/movies/add' ,
                "icon":(<FontAwesomeIcon icon={faCircleNotch} />),
            },
            {
                'name':"List Movies" ,
                'link':'/panel/movies/list' ,
                "icon":(<FontAwesomeIcon icon={faCircleNotch} />),
            },
            {
                'name':"List Series" ,
                'link':'/panel/series/list' ,
                "icon":(<FontAwesomeIcon icon={faCircleNotch} />),
            },
            {
                'name':"Add Series" ,
                'link':'/panel/series/add' ,
                "icon":(<FontAwesomeIcon icon={faCircleNotch} />),
            },
        ]
    },
    {
        'name':"Banner" ,
        'isSubmenu': false ,
        'link':'/panel/banner' ,
        "icon":(<FontAwesomeIcon icon={faImage} />),
    },
    {
        'name':"Genre" ,
        'isSubmenu': false ,
        'link':'/panel/gender' ,
        "icon":(<FontAwesomeIcon icon={faListAlt} />),
    },
    {
        'name':"Categories" ,
        'isSubmenu': false ,
        'link':'/panel/categories',
        "icon":(<FontAwesomeIcon icon={faList} />),
    },
]

export default NavData ;
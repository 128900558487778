import React, { useEffect, useState } from "react" ;
import {ToastContainer} from "react-toastify";
import Toast_noty from '../../../widget/Toast_noty'
import Loader from "../../../widget/Loader/Loader";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import {Get_Track_Single } from '../../../widget/API'
import {Admin_Edit_Track} from '../../../widget/API_Admin'
import {Redirect} from 'react-router-dom';
import { Select , Space, Form , Input ,InputNumber} from 'antd';
import TrackAutoFields from '../TrackAutoFields';
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from "@fortawesome/free-solid-svg-icons";




function TrackEdit(props) {
    const[dispaly , setDisplay]=useState(false);
    const[track , setTrack]=useState(null)
    const [form] = Form.useForm();
    


    const onFinish=(values)=>{
        if(values && values.labels){
            values['labels'] =  Object.entries(values.labels).map(([key , value])=>{
                if(value && value._d){
                    return {
                        "id" : key,
                        "value" : String(moment(value._d).format('YYYY-MM-DD hh:mm')),
                    }
                }
                else {
                    return {
                        "id" : key,
                        "value" : String(value),
                    }
                }
                
            })
        }
        setDisplay(true);
        const returndata =  Admin_Edit_Track(props.match.params.id ,values , props.match.params.artistID  );
        returndata.then((res)=>{
        setDisplay(false)
        if(res.result == 'ok'){
            Toast_noty("Success", 5000, "success");
        }
        else 
        {
            Toast_noty(res.error, 5000, "error");
        }
        })
        returndata.catch((er)=>{
            setDisplay(false);
            Toast_noty('please try again', 5000, "error");
        })
    }




    const getDetailTrack=()=>{
        if(props.match.params.id){
            setDisplay(true)
            const returndata =  Get_Track_Single(props.match.params.id);
            returndata.then((res)=>{
            setDisplay(false)
            if(res.result == 'ok'){
                setTrack(res.data)
                form.setFieldsValue({'name' : res.data.name})
                form.setFieldsValue({'lyric' : res.data.lyric})
                form.setFieldsValue({'producer' : res.data.producer})
                form.setFieldsValue({'music_producer' : res.data.music_producer})
                if(res.data.labels){
                    for(let i of res.data.labels){
                        if(i.label && i.label != null && i.label.property.type == 'enum' && i.label.property.choose == 'single'){
                            form.setFieldsValue({ 'labels' : {[i.label_value_id] : parseInt(i.value)}})
                        }
                        else if(i.label && i.label != null && i.label.property.type == 'enum' && i.label.property.choose == 'multi'){
                            form.setFieldsValue({ 'labels' : {[i.label_value_id] : 
                                i.value.split(",").map(item=>{
                                    return parseInt(item)
                                })
                            }})
                        }
                        else if (i.label && i.label != null && i.label.property.type == 'date_time') {
                            form.setFieldsValue({ 'labels' : {[i.label_value_id] : moment(i.value)}})
                        }
                        else if(i.label && i.label != null ) {
                            form.setFieldsValue({ 'labels' : {[i.label_value_id] : i.value}})
                        }
                    }
                }
            }
            else if(res.error == 'unauthenticated') {
                localStorage.clear();
                props.history.push('/');  
            }
            else {
                Toast_noty(res.error, 5000, "error");
            }
            })
             returndata.catch((er)=>{
                setDisplay(false)
                Toast_noty('please try again', 5000, "error");
            })
        }
    }


    useEffect(()=>{
        if(props.match.params.id){
            getDetailTrack()
        }
    },[props.match.params.id])


    return (
        <>
            <div className="addalbum-cont">
                {dispaly && <Loader/>}
                {/* <div className="addalbum-cont-head">
                    <h3>Add Track</h3>
                </div> */}
                <Form onFinish={onFinish} form={form} id="addalbum-cont-form" className="addalbum-cont-form">
                        <div className="addalbum-cont-form-section">

                            <div className="addalbum-cont-form-section-item">
                                <span>Track name</span>
                                <Form.Item  name="name" rules={[{ required: true}]}>
                                    <Input />
                                </Form.Item>
                            </div>

                            <div className="addalbum-cont-form-section-item">
                                <span>Lyric</span>
                                <Form.Item  name="lyric" rules={[{ required: true}]}>
                                    <Input />
                                </Form.Item>
                            </div>

                            <div className="addalbum-cont-form-section-item">
                                <span>Producer</span>
                                <Form.Item  name="producer" rules={[{ required: true}]}>
                                    <Input />
                                </Form.Item>
                            </div>

                            <TrackAutoFields/>

                            <div className="addalbum-cont-form-section-item">
                                <span>Music producer</span>
                                <Form.Item  name="music_producer" rules={[{ required: true}]}>
                                    <Input />
                                </Form.Item>
                            </div>



                        </div>
                        <div className="addalbum-cont-form-footer">
                            <button className="addalbum-cont-form-footer-btn-next">Edit</button>
                        </div>
                </Form>
            </div>
        </>    
    )
}

export default TrackEdit;
import React, { useEffect, useState } from "react" ;
import {ToastContainer} from "react-toastify";
import Toast_noty from '../../../widget/Toast_noty'
import Loader from "../../../widget/Loader/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import SeriesAddStepOne from './widget/SeriesAddStepOne';
import SeriesAddStepTwo from './widget/SeriesAddStepTwo';
import '../seriesStyle.css'





function SeriesAdd(props) {
    const[dispaly , setDisplay]=useState(false);
    const[step , setStep]=useState(1);
    const[seriesId , setSeriesId]=useState(0);
    const[seriesName , seSeriesName]=useState(0);
    const[coverFile , setCoverFile]=useState(null);





 


    return (
        <div className="addseries">
            {dispaly && <Loader/>}
            <div className="addseries-head">
                <div className={`addseries-head-item ${step == 1 && 'addseries-head-item-active'}`}>
                    <div className="addseries-head-item-cont">1</div>
                    <div className="addseries-head-item-des">
                        <span>Add Series</span>
                    </div>
                    <div className="addseries-head-item-end"><FontAwesomeIcon icon={faChevronRight} /></div>
                </div>
                <div className={`addseries-head-item ${step == 2 && 'addseries-head-item-active'}`}>
                    <div className="addseries-head-item-cont">2</div>
                    <div className="addseries-head-item-des">
                        <span>Add Epesode</span>
                    </div>
                </div>
            </div>
            {step == 1 && <SeriesAddStepOne setStep={setStep} setCoverFile={setCoverFile} setSeriesId={setSeriesId} seSeriesName={seSeriesName}   /> }
            {step == 2 && <SeriesAddStepTwo setStep={setStep} seriesName={seriesName} seriesId={seriesId} coverFile={coverFile}    /> }
        </div>
    )
}

export default SeriesAdd;
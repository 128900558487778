import {BASE_URL} from './BASE_URL';
import moment from 'moment';


function LoginAuth(e) {
  return fetch(`${BASE_URL}/auth/login` , {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'email':e.target[0].value ,
      'password':e.target[1].value ,
    }) 
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {LoginAuth};



function RegisterAuth(e) {
  return fetch(`${BASE_URL}/auth/register` , {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'artist':{
        "name":e.target[0].value ,
        "about":e.target[3].value ,
      } ,
      "admin":{
        'email':e.target[1].value ,
        'password':e.target[2].value ,
      }
    }) 
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {RegisterAuth};




function VerifyAuth(e) {
  return fetch(`${BASE_URL}/auth/validation` , {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      "validation_type" : "email",
      "validation_text" : sessionStorage.getItem('veri_email'),
      "validation_code" : e.target[0].value ,
      "password":sessionStorage.getItem('veri_pas') ,
    }) 
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {VerifyAuth};




function ForgetPass(e) {
  return fetch(`${BASE_URL}/auth/forget_password` , {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      "validation_type" : "email",
      "validation_text" : e.target[0].value,
    }) 
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {ForgetPass};



function VerifForgetPassword(e) {
  return fetch(`${BASE_URL}/auth/validation` , {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      "validation_type" : "email",
      "validation_text" : sessionStorage.getItem('veri_email'),
      "validation_code" : e.target[0].value ,
    }) 
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {VerifForgetPassword};




function AuthResetPassword(e) {
  return fetch(`${BASE_URL}/auth/pass_rest` , {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      "token" : sessionStorage.getItem('veri_token'),
      "password" : e.target[0].value ,
    }) 
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {AuthResetPassword};




function Get_Dashboard() {
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/dashboard` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json' ,
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Get_Dashboard};



function Get_Users () {
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/accessibility/users` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json' ,
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Get_Users};




function Add_User(e) {
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/accessibility/users` , {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: JSON.stringify({
      'email':e.target[0].value ,
      'name':e.target[1].value ,
      'password':e.target[2].value ,
      "role_id" : e.target[3].value ,
      "phone" : e.target[4].value ,
    }) 
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Add_User};




function Delete_User(id) {
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/accessibility/users/${id}` , {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Delete_User};



function Get_Single_Users(id) {
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/accessibility/users/${id}` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Get_Single_Users};




function Edit_User(id , name , email , phone , role) {
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/accessibility/users/${id}` , {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: JSON.stringify({
      'email':email ,
      'name':name ,
      "role_id" : role,
      "phone" : phone ,
    })
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Edit_User};



function Add_Role(listid , e) {
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/accessibility` , {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: JSON.stringify({
      'name':e.target[0].value ,
      'permissions':listid
    })
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Add_Role};





function Get_Roles() {
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/accessibility` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Get_Roles};




function Delete_Roles(id) {
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/accessibility/${id}` , {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Delete_Roles};




function Change_Available(id) {
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/accessibility/users/${id}/update_available` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Change_Available};




function Get_Category_Album() {
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/categories` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Get_Category_Album};





function Get_Gender_Album() {
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/genders` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Get_Gender_Album};




function Get_All_Artists() {
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/artists` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Get_All_Artists};




function Add_Album_One(values) {
  let postData = {
    "album" : {
     "name" : values.name,
     "cover_producer":  values.cover_producer,
     "gender_id" :  values.gender,
     "release_time" :  moment(values.release_time._d).format('YYYY-MM-DD hh:mm') ,
    },
     "categories_id" : values.category,
  }
  if(values.pay){
    postData['product'] = {
      "price_rial" : values.Rial_price,
      "price_dollar" : values.Dollar_price,
      "vip" : values.vip
    }
  }
  if(values.labels){
    postData['labels'] = JSON.stringify(values.labels) ;
  }
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/albums` , {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: JSON.stringify(postData)
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Add_Album_One};




function Add_Album_Two(albumId , profileImage ) {
  var formdata = new FormData();
  formdata.append("image", profileImage[0].originFileObj);
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/albums/${albumId}/cover` , {
    method: 'POST',
    headers: {
      // 'Accept': 'application/json',
      // "Content-type": "multipart/form-data" ,
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: formdata ,
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Add_Album_Two};




function Add_Album_three(albumId ,values , albumPay , typeAlbum , fileMusic , duration) {
  var formdata = new FormData();
  var postData = {}
  if(typeAlbum == 'album'){
    postData = {
      "name" : values.name,
      "lyric" : values.lyric,
      "producer" : values.producer,
      "music_producer" : values.music_producer,
      "duration" : duration,
    }
    if(albumPay){
      postData['product'] = JSON.stringify({
        "price_rial" : values.Rial_price,
        "price_dollar" : values.Dollar_price,
        "vip" : values.vip
      })
    }
  }
  else {
    postData = {
      "lyric" : values.lyric,
      "producer" : values.producer,
      "music_producer" : values.music_producer,
      "duration" : duration,
    }
  }
  if(values.isFeats){
    formdata.append("feats", JSON.stringify(values.feats));
  }
  if(values.labels){
    formdata.append("labels" ,JSON.stringify(values.labels))
  }
  formdata.append("audio", fileMusic);
  formdata.append("type", typeAlbum);
  formdata.append("track" ,JSON.stringify(postData))

  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/albums/${albumId}/tracks` , {
    method: 'POST',
    headers: {
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: formdata ,
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Add_Album_three};




function Get_Album(albumId) {
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/albums/${albumId}/single` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Get_Album};




function Get_Album_Comment(albumId , current) {
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/albums/${albumId}/likes?page=${current}` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Get_Album_Comment};



function Get_Album_Views(albumId , current) {
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/albums/${albumId}/views?page=${current}` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Get_Album_Views};



function Edit_Album(values , id) {
  let postData = {'album':values}
  if (values.labels){
    postData['labels'] = values.labels ;
  }
  else {
    postData['labels'] = [] ;
  }
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/albums/${id}` , {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body:JSON.stringify(postData) ,

    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Edit_Album};


function Get_Track(albumId) {
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/albums/${albumId}/tracks` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Get_Track};


function Get_Track_Single(trackId) {
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/albums/0/tracks/${trackId}` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Get_Track_Single};





function Get_Track_Comments(albumId , trackID , current) {
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/albums/${albumId}/tracks/${trackID}/comments?page=${current}` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Get_Track_Comments};



function Get_Track_Likes(albumId , trackID , current) {
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/albums/${albumId}/tracks/${trackID}/likes?page=${current}` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Get_Track_Likes};


function Get_Track_Views(albumId , trackID , current) {
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/albums/${albumId}/tracks/${trackID}/views?page=${current}` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Get_Track_Views};



function Get_All_Album(currentPage) {
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/albums?page=${currentPage}` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Get_All_Album};


function Get_All_Album_List() {
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/albums/all` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Get_All_Album_List};







function Get_Cover_Image(id) {
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/albums/${id}/cover` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.blob())
    .then((res) => {
      return URL.createObjectURL(res); 
    })
    .catch((er)=>{
      return er ;
    })
}
export {Get_Cover_Image};




function Get_User_Cover_Image(id) {
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/accessibility/users/${id}/avatar` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.blob())
    .then((res) => {
      return URL.createObjectURL(res); 
    })
    .catch((er)=>{
      return er ;
    })
}
export {Get_User_Cover_Image};



function Update_Cover_User(id , profileImage ) {
  var formdata = new FormData();
  formdata.append("image", profileImage[0].originFileObj);
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/accessibility/users/${id}/avatar` , {
    method: 'POST',
    headers: {
      // 'Accept': 'application/json',
      // "Content-type": "multipart/form-data" ,
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: formdata ,
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Update_Cover_User};




function Update_Cover_Artist(profileImage ) {
  var formdata = new FormData();
  formdata.append("image", profileImage[0].originFileObj);
  formdata.append("token",JSON.parse(localStorage.getItem('userInfo')).token );
  return fetch(`${BASE_URL}/auth/upload` , {
    method: 'POST',
    headers: {
      // 'Accept': 'application/json',
      // "Content-type": "multipart/form-data" ,
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: formdata ,
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Update_Cover_Artist};




function Update_Artist(name , about) {
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}` , {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: JSON.stringify({
      'name':name ,
      "about" :about,
    })
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Update_Artist};



function Get_All_Track(current , sort) {
  let Url = `${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/tracks?page=${current}`;
  if(sort != null){
    Url = `${Url}&sort=${sort}`;
  }
  return fetch(Url , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Get_All_Track};


function Get_Followers(current) {
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/followers?page=${current}` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Get_Followers};



function Play_Track(albumId ,trackId) {
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/albums/${albumId}/tracks/${trackId}/audio` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.blob())
    .then((res) => {
      return URL.createObjectURL(res); ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Play_Track};




function Edit_Track(trackId , values) {
  let postData = {'track':values}
  if (values.labels){
    postData['labels'] = values.labels ;
  } 
  else {
    postData['labels'] = [] ;
  }
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/albums/0/tracks/${trackId}` , {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: JSON.stringify(postData)
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Edit_Track};



function Move_Track_To_Album(albumId , trackId , newAlbum ) {
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/albums/${albumId}/tracks/${trackId}` , {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: JSON.stringify({
      'album_id':newAlbum ,
    })
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Move_Track_To_Album};




function Update_Track_Audio(albumId , trackId ,duration , audio ) {
  var formdata = new FormData();
  formdata.append("audio", audio);
  formdata.append("duration", duration);
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/albums/${albumId}/tracks/${trackId}/audio` , {
    method: 'POST',
    headers: {
      // 'Accept': 'application/json',
      // "Content-type": "multipart/form-data" ,
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: formdata ,
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Update_Track_Audio};




function Add_Track_Audio(albumId ,duration , fileMusic , e) {
  var postData = {
    "name" : e.target[0].value,
    "lyric" : e.target[1].value,
    "producer" : e.target[2].value,
    "music_producer" : e.target[3].value,
    "duration" : duration,
  }
  var formdata = new FormData();
  formdata.append("audio", fileMusic);
  formdata.append("type", 'album');
  formdata.append("track" ,JSON.stringify(postData))
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/albums/${albumId}/tracks` , {
    method: 'POST',
    headers: {
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: formdata ,
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Add_Track_Audio};




function Delete_Track(albumId ,trackId) {
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/albums/${albumId}/tracks/${trackId}` , {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Delete_Track};





function Edit_Track_Fits(values, albumId ,trackId) {
  let postdata = {'feats':{}}
  for(let i of values.feats){
    postdata['feats'][String(i.artist_id)] = {
      'percentage' : i.percentage
    }
  }
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/albums/${albumId}/tracks/${trackId}/feats` , {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: JSON.stringify(postdata) ,
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Edit_Track_Fits};



function Delete_Album(albumId) {
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/albums/${albumId}` , {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Delete_Album};



function Update_Cover_Album(id , cover ) {
  var formdata = new FormData();
  formdata.append("image", cover[0].originFileObj);
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/albums/${id}/cover` , {
    method: 'POST',
    headers: {
      // 'Accept': 'application/json',
      // "Content-type": "multipart/form-data" ,
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: formdata ,
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Update_Cover_Album};



function Add_Music_Video(values , cover , music) {
  var formdata = new FormData();
  if(values.isFeats){
    formdata.append("feats", JSON.stringify(values.feats));
  }
  if(values.pay){
    formdata.append('product' , JSON.stringify({
      "price_rial" : values.Rial_price,
      "price_dollar" : values.Dollar_price,
      "vip" : values.vip
    }))
  }
  if(values.labels){
    formdata.append("labels", JSON.stringify(values.labels));
  }
  formdata.append("name", values.name);
  formdata.append("director", values.director);
  formdata.append("release_time", moment(values.release_time._d).format('YYYY-MM-DD hh:mm'));
  formdata.append("cover", cover[0].originFileObj);
  formdata.append("video", music);
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/music_videos` , {
    method: 'POST',
    headers: {
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: formdata ,
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Add_Music_Video};



function Get_All_Music_Video(current) {
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/music_videos?page=${current}` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Get_All_Music_Video};




function Get_Music_Video_Detail(id) {
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/music_videos/${id}` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Get_Music_Video_Detail};


function Get_Music_Video_Views(musicVideoId , current) {
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/music_videos/${musicVideoId}/views?page=${current}` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Get_Music_Video_Views};


function Get_Music_Video_Likes(musicVideoId , current) {
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/music_videos/${musicVideoId}/likes?page=${current}` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Get_Music_Video_Likes};



function Delete_Music_Video(id) {
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/music_videos/${id}` , {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Delete_Music_Video};


function Edit_Music_Video(values , id) {
  let postData = {'video':values}
  if (values.labels){
    postData['labels'] = values.labels ;
  } 
  else {
    postData['labels'] = [] ;
  }
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/music_videos/${id}` , {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body:JSON.stringify(postData) ,

    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Edit_Music_Video};



function Edit_MusicVideo_Fits(values, id) {
  let postdata = {'feats':{}}
  for(let i of values.feats){
    postdata['feats'][String(i.artist_id)] = {
      'percentage' : i.percentage
    }
  }
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/music_videos/${id}/feats` , {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: JSON.stringify(postdata) ,
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Edit_MusicVideo_Fits};



function Get_Music_Video_Cover_Image(id) {
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/music_videos/${id}/cover` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.blob())
    .then((res) => {
      return URL.createObjectURL(res); ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Get_Music_Video_Cover_Image};






function Update_Cover_Music_Video(id , cover ) {
  var formdata = new FormData();
  formdata.append("cover", cover[0].originFileObj);
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/music_videos/${id}/cover` , {
    method: 'POST',
    headers: {
      // 'Accept': 'application/json',
      // "Content-type": "multipart/form-data" ,
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: formdata ,
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Update_Cover_Music_Video};



function Get_Music_Video_File_Video(id) {
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/music_videos/${id}/video` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.blob())
    .then((res) => {
      return URL.createObjectURL(res) ;

    })
    .catch((er)=>{
      return er ;
    })
}
export {Get_Music_Video_File_Video};





function Update_Music_Video(id , musicvideo ) {
  var formdata = new FormData();
  formdata.append("video", musicvideo);
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/music_videos/${id}/video` , {
    method: 'POST',
    headers: {
      // 'Accept': 'application/json',
      // "Content-type": "multipart/form-data" ,
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: formdata ,
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Update_Music_Video};





function Add_Podcast(values , cover , podcast , duration) {
  var formdata = new FormData();
  if(values.isFeats){
    formdata.append("feats", JSON.stringify(values.feats));
  }
  if(values.pay){
    formdata.append('product' , JSON.stringify({
      "price_rial" : values.Rial_price,
      "price_dollar" : values.Dollar_price,
      "vip" : values.vip
    }))
  }
  if(values.pay){
    formdata.append("price_rial", values.Rial_price);
    formdata.append("price_dollar", values.Dollar_price);
    formdata.append("vip", values.vip);
  }
  if(values.labels){
    formdata.append("labels", JSON.stringify(values.labels));
  }
  formdata.append("name", values.name);
  formdata.append("categories_id", values.category);
  formdata.append("gender_id", values.gender);
  formdata.append("duration", duration);
  formdata.append("producer", values.producer);
  formdata.append("cover_producer", values.cover_producer);
  formdata.append("release_time", moment(values.release_time._d).format('YYYY-MM-DD hh:mm'));
  formdata.append("cover", cover[0].originFileObj);
  formdata.append("audio", podcast);
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/podcasts` , {
    method: 'POST',
    headers: {
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: formdata ,
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Add_Podcast};




function Get_All_Podcast() {
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/podcasts` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Get_All_Podcast};




function Get_Podcast_Cover_Image(id) {
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/podcasts/${id}/cover` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.blob())
    .then((res) => {
      return URL.createObjectURL(res); ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Get_Podcast_Cover_Image};





function Get_Podcast_Detail(id) {
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/podcasts/${id}` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Get_Podcast_Detail};


function Get_Podcast_Views(podcastId , current) {
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/podcasts/${podcastId}/views?page=${current}` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Get_Podcast_Views};


function Get_Podcast_Likes(podcastId , current) {
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/podcasts/${podcastId}/likes?page=${current}` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Get_Podcast_Likes};


function Edit_Podcast(values , id) {
  let postData = {'podcast':values}
  if (values.labels){
    postData['labels'] = values.labels ;
  } 
  else {
    postData['labels'] = [] ;
  }
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/podcasts/${id}` , {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body:JSON.stringify(postData) ,

    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Edit_Podcast};



function Edit_Podcast_Fits(values, id) {
  let postdata = {'feats':{}}
  for(let i of values.feats){
    postdata['feats'][String(i.artist_id)] = {
      'percentage' : i.percentage
    }
  }
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/podcasts/${id}/feats` , {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: JSON.stringify(postdata) ,
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Edit_Podcast_Fits};





function Delete_Podcast(id) {
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/podcasts/${id}` , {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Delete_Podcast};




function Update_Cover_Podcast(id , cover ) {
  var formdata = new FormData();
  formdata.append("cover", cover[0].originFileObj);
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/podcasts/${id}/cover` , {
    method: 'POST',
    headers: {
      // 'Accept': 'application/json',
      // "Content-type": "multipart/form-data" ,
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: formdata ,
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Update_Cover_Podcast};




function Update_Podcast(id , podcast ) {
  var formdata = new FormData();
  formdata.append("audio", podcast);
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/podcasts/${id}/audio` , {
    method: 'POST',
    headers: {
      // 'Accept': 'application/json',
      // "Content-type": "multipart/form-data" ,
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: formdata ,
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Update_Podcast};



function Play_Podcast(PodcastId) {
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/podcasts/${PodcastId}/audio` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.blob())
    .then((res) => {
      return URL.createObjectURL(res); 
    })
    .catch((er)=>{
      return er ;
    })
}
export {Play_Podcast};




function Get_Auto_Feilds(typeTable) {
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/labels?type=${typeTable}` , {
    method: 'GET',
    headers: {
      // 'Accept': 'application/json',
      // 'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res; 
    })
    .catch((er)=>{
      return er ;
    })
}
export {Get_Auto_Feilds};




function Get_Table_Value(table) {
  return fetch(`${BASE_URL}/artists/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/label_tables?table=${table}` , {
    method: 'GET',
    headers: {
      // 'Accept': 'application/json',
      // 'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res; 
    })
    .catch((er)=>{
      return er ;
    })
}
export {Get_Table_Value};
import React, { useEffect, useState } from "react" ;
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV , faEdit , faTrash , faUpload} from "@fortawesome/free-solid-svg-icons";
import Table from "../../widget/Table/Table";
import {
    Admin_Get_All_Banner ,
    Admin_Delete_Banner ,
    Admin_Get_Data_Add_Banner_List ,
    Admin_Add_Banner ,
    Admin_Change_Status_Banner ,
} from '../../widget/API_Admin';
import Toast_noty from '../../widget/Toast_noty'
import Loader from '../../widget/Loader/Loader'
import CModal from "../../widget/CModal/CModal";
import './bannerStyle.css';
import ImgCrop from 'antd-img-crop';
import { Upload , Form , Select , Input} from 'antd';
import {BASE_Medai_URL} from '../../widget/BASE_Medai_URL'



function Banner(props) {
    const[display , setDisplay]=useState(false);
    const[listDatafeilds , setListDataFeilds]=useState([]);
    const[visibleDelete , setVisibleDelete]=useState(false);
    const[visibleAdd , setVisibleAdd]=useState(false);
    const[visibleDisplay , setVisibleDisplay]=useState(false);
    const[imageDisplay , setImageDisplay]=useState(null);
    const[deleteId , setDeleteId]=useState(null)
    const[listData , setListData]=useState([]);
    const[actionList , setActionList]=useState(-1);
    const [fileList, setFileList] = useState([]);
    const[typeBanner , setTypeBanner]=useState(null)
    const {Option} = Select ;



    
    // Function For Upload Handel

    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
        };
    
    const onPreview = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
        };

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
        };

    // End Function For Upload 


    const changeDisplayActionList=(id)=>{
        if(actionList == id){
            setActionList(-1)
        }
        else {
            setActionList(id)
        }
    }

    const displayImage=(image)=>{
        setImageDisplay(image)
        setVisibleDisplay(true);
    }

    const openDeleteModal=(id)=>{
        setDeleteId(id)
        setVisibleDelete(true)
    }

    const deleteBanner=()=>{
        setDisplay(true);
        setVisibleDelete(false)
        const returndata =  Admin_Delete_Banner(deleteId);
        returndata.then(async(res)=>{
        setDisplay(false);
        if(res.result == 'ok'){
            await getData()
            Toast_noty("Delete user success", 10000, "success");
        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
         returndata.catch((er)=>{
            setDisplay(false);
            Toast_noty('please try again', 5000, "error");
        })
    }


    const selectTypeBanner=(value)=>{
        setTypeBanner(value);
        setDisplay(true);
        const returndata =  Admin_Get_Data_Add_Banner_List(value);
        returndata.then(async(res)=>{
        setDisplay(false);
        if(res.result == 'ok'){
            setListDataFeilds(res.data)
        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
         returndata.catch((er)=>{
            setDisplay(false);
            Toast_noty('please try again', 5000, "error");
        })
    }


    const addBanner=(values)=>{
        if(fileList.length > 0){
            setDisplay(true);
            const returndata =  Admin_Add_Banner(values , fileList);
            returndata.then(async(res)=>{
            setDisplay(false);
            if(res.result == 'ok'){
                getData()
                setVisibleAdd(false)
            }
            else if(res.error == 'unauthenticated') {
                localStorage.clear();
                props.history.push('/');  
            }
            else {
                Toast_noty(res.error, 5000, "error");
            }
            })
            returndata.catch((er)=>{
                setDisplay(false);
                Toast_noty('please try again', 5000, "error");
            })
        }
    }

    const changeActiveBanner=(id , state)=>{
        setDisplay(true);
        const returndata =  Admin_Change_Status_Banner(id , state == true ? 0 : 1);
        returndata.then(async(res)=>{
        setDisplay(false);
        if(res.result == 'ok'){
            getData()
        }
        })
    }

    const col = [
        {
            title:"id" ,
            render:(i) => (<>{i.id != null && i.id}</>)
        },
        {
            title:"image" ,
            render:(i) => (<>{i.id != null && <img onClick={()=>displayImage(i.cover_url)} className="table-img" src={`${BASE_Medai_URL}/${i.cover_url}`} />}</>)
        },
        {
            title:"type" ,
            render:(i) => (<>{i.type != null && i.type}</>)
        },
        {
            title:"value" ,
            render:(i) => (<>{i.value != null && i.value}</>)
        },
        {
            title:"Active" ,
            render:(i) => (
            <label className="table-toggle" onClick={()=>changeActiveBanner(i.id , i.active)}>
                <input type="checkbox"  checked={i.active && true}/>
                <div className="table-toggle-main" />
            </label>)
        },
        {
            title:"Action" ,
            render:(i) => (
            <div id="table-action" className="table-action">
                <div className="table-action-icon" onClick={()=>changeDisplayActionList(i.id)}>
                    <FontAwesomeIcon icon={faEllipsisV}  />
                </div>
                <div  className={`table-action-list ${actionList == i.id && 'table-action-list-active'}`}>
                    <div onClick={()=>openDeleteModal(i.id)} className="table-action-list-item">
                        <FontAwesomeIcon icon={faTrash} />
                        <span>Delete</span>
                    </div>
                    {/* <div className="table-action-list-item">
                        <FontAwesomeIcon icon={faEdit} />
                        <span>Edit</span>
                    </div> */}
                </div>
            </div>
            )
        },
    ]


    const getData=()=>{
        setDisplay(true)
        const returndata =  Admin_Get_All_Banner();
        returndata.then((res)=>{
        setDisplay(false)
        if(res.result == 'ok'){
            setListData(res.data)
        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
         returndata.catch((er)=>{
            Toast_noty('please try again', 5000, "error");
        })
    }


    useEffect(()=>{
        getData()
    },[])


    return (
        <>
            <div className="fullPage">
                {display && <Loader/>}

                <CModal visible={visibleDisplay} setVisible={setVisibleDisplay} title="View"> 
                    <img   src={`${BASE_Medai_URL}/${imageDisplay}`} alt="banner" />
                </CModal>

                <CModal visible={visibleDelete} setVisible={setVisibleDelete} title="Delete"> 
                    <p>Are you sure delete banner {deleteId} ?</p>
                    <div className="CModel-Bottom">
                        <button className="CModel-Bottom-btn-ok" onClick={deleteBanner}>Ok</button>
                        <button className="CModel-Bottom-btn-cancel" onClick={()=>{setVisibleDelete(false)}}>Cansel</button>
                    </div>
                </CModal>

                <CModal visible={visibleAdd} setVisible={setVisibleAdd} title="Add"> 
                    <div className="banner-add">
                        <ImgCrop aspect={2/1}>
                            <Upload
                            className="mt-2"
                            accept=".png , .jpg , .jpeg , .pdf "
                            listType="picture-card"
                            fileList={fileList}
                            onChange={onChange}
                            onPreview={onPreview}
                            customRequest={dummyRequest}
                            showUploadList={{showPreviewIcon:false}}
                            >
                                {fileList.length < 1 &&
                                    <div className="">
                                        <h3>Drop cover here or click button to upload.</h3>
                                        <FontAwesomeIcon icon={faUpload} />
                                    </div>
                                }
                            </Upload>
                        </ImgCrop>
                        {fileList.length > 0 && 
                            <Form onFinish={addBanner} className="banner-add-form">
                                
                                <div className="banner-add-form-item">
                                    <span>Type</span>
                                    <Form.Item name="type" rules={[{ required: true}]}>
                                        <Select
                                            allowClear
                                            onChange={selectTypeBanner}
                                            >
                                                <Option value={'link'}>{'link'}</Option>
                                                <Option value={'movie'}>{"movie"}</Option>
                                                <Option value={'series'}>{"series"}</Option>
                                                <Option value={'album'}>{"album"}</Option>
                                                <Option value={'track'}>{"track"}</Option>
                                                <Option value={'podcast'}>{"podcast"}</Option>
                                                <Option value={'music_video'}>{"music video"}</Option>
                                                <Option value={'artist'}>{"artist"}</Option>
                                        </Select>
                                    </Form.Item>
                                </div>    
                                {typeBanner != null && typeBanner != 'link' &&
                                    <div className="banner-add-form-item">
                                        <span>Value</span>
                                        <Form.Item name="value" rules={[{ required: true}]}>
                                            <Select
                                                allowClear
                                                >
                                                    {listDatafeilds.map((item , index)=>
                                                        <Option key={index} value={item.id}>{item.name}</Option>
                                                    )}
                                            </Select>
                                        </Form.Item>
                                    </div>  
                                }
                                {typeBanner != null && typeBanner == 'link' &&
                                    <div className="banner-add-form-item">
                                        <span>Link</span>
                                        <Form.Item name="value" rules={[{ required: true}]}>
                                            <Input />
                                        </Form.Item>
                                    </div>
                                }
                                <div className="banner-add-form-item">
                                    <button>Submit</button>
                                </div>
                            </Form>
                        }
                    </div>
                </CModal>

                
                <div className="pagelist">
                    <div className="pagelist-head">
                        <span>Banner</span>
                        <button onClick={()=>{setVisibleAdd(true)}} className="pagelist-head-button">Add Banner</button>
                    </div>
                    {listData.length > 0 && 
                        <Table range={10} columns={col} data={listData} />
                    }
                </div>
            </div> 
        </>
    )
}

export default Banner;
import React, { useEffect, useState } from "react" ;
import {ToastContainer} from "react-toastify";
import Toast_noty from '../../../widget/Toast_noty'
import Loader from "../../../widget/Loader/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { Select , DatePicker, Space , Upload , Form , Input, InputNumber, Button} from 'antd';
import ImgCrop from 'antd-img-crop';
import moment from "moment";
import { Redirect } from "react-router-dom";
import {Admin_Get_Series_Single , Admin_Edit_Series} from '../../../widget/API_Admin';
import SeriesAutoFields from '../SeriesAutoFields';
import '../seriesStyle.css';



function SeriesEdit(props) {
    const[dispaly , setDisplay]=useState(false);
    const[redirect , setRedirect]=useState(false);
    const [series , setSeries]=useState(null);
    const [form] = Form.useForm();
    const { Option } = Select;




    const onFinish=(values)=>{
        if(values && values.labels){
            values['labels'] =  Object.entries(values.labels).map(([key , value])=>{
                if(value && value._d){
                    return {
                        "id" : key,
                        "value" : String(moment(value._d).format('YYYY-MM-DD hh:mm')),
                    }
                }
                else {
                    return {
                        "id" : key,
                        "value" : String(value),
                    }
                }
                
            })
        }
        setDisplay(true);
        const returndata =  Admin_Edit_Series(props.match.params.id , values);
        returndata.then((res)=>{
        setDisplay(false)
        if(res.result == 'ok'){
            setRedirect(true)
        }
        else 
        {
            Toast_noty(res.error, 5000, "error");
        }
        })
        returndata.catch((er)=>{
            setDisplay(false);
            Toast_noty('please try again', 5000, "error");
        })
    }


    const getDetailSeries=()=>{
        if(props.match.params.id){
            setDisplay(true)
            const returndata =  Admin_Get_Series_Single(props.match.params.id);
            returndata.then((res)=>{
            setDisplay(false)
            if(res.result == 'ok'){
                setSeries(res.data)
                form.setFieldsValue({'name' : res.data.name})
                form.setFieldsValue({'director' : res.data.director})
                form.setFieldsValue({'publish_time' : moment(res.data.publish_time)})
                form.setFieldsValue({'season_count' : res.data.season_count})
                form.setFieldsValue({'year' : res.data.year})
                form.setFieldsValue({'country' : res.data.country})
                if(res.data.labels){
                    for(let i of res.data.labels){
                        if(i.label && i.label != null && i.label.property.type == 'enum' && i.label.property.choose == 'single'){
                            form.setFieldsValue({ 'labels' : {[i.label_value_id] : parseInt(i.value)}})
                        }
                        else if(i.label && i.label != null && i.label.property.type == 'enum' && i.label.property.choose == 'multi'){
                            form.setFieldsValue({ 'labels' : {[i.label_value_id] : 
                                i.value.split(",").map(item=>{
                                    return parseInt(item)
                                })
                            }})
                        }
                        else if (i.label && i.label != null && i.label.property.type == 'date_time') {
                            form.setFieldsValue({ 'labels' : {[i.label_value_id] : moment(i.value)}})
                        }
                        else if(i.label && i.label != null ) {
                            form.setFieldsValue({ 'labels' : {[i.label_value_id] : i.value}})
                        }
                    }
                }
            }
            else if(res.error == 'unauthenticated') {
                localStorage.clear();
                props.history.push('/');  
            }
            else {
                Toast_noty(res.error, 5000, "error");
            }
            })
             returndata.catch((er)=>{
                setDisplay(false)
                Toast_noty('please try again', 5000, "error");
            })
        }
    }


    useEffect(()=>{
        getDetailSeries();
      },[])


    return (
        <div className="seriesdetail">
            {dispaly && <Loader/>}
            {redirect && <Redirect to={`/panel/seriesdetail/${props.match.params.id}`} />}
            <div className="addseries-cont">
                <Form form={form} onFinish={onFinish} className="addseries-cont-form">
                    <div className="addseries-cont-form-section">

                        <div className="addseries-cont-form-section-item">
                            <span>Series name</span>
                            <Form.Item name="name" rules={[{ required: true}]}>
                                <Input />
                            </Form.Item>
                        </div>

                        <div className="addseries-cont-form-section-item">
                            <span>Director</span>
                            <Form.Item name="director" rules={[{ required: true}]}>
                                <Input />
                            </Form.Item>
                        </div>

                        <div className="addseries-cont-form-section-item">
                            <span>Season count</span>
                            <Form.Item name="season_count" rules={[{ required: true}]}>
                                <InputNumber />
                            </Form.Item>
                        </div>

                        <div className="addseries-cont-form-section-item">
                            <span>Publish time</span>
                            <Form.Item name="publish_time" rules={[{ required: true}]}>
                                <DatePicker  />
                            </Form.Item>
                        </div>

                        <div className="addseries-cont-form-section-item">
                            <span>Year</span>
                            <Form.Item name="year" rules={[{ required: true}]}>
                                <InputNumber  />
                            </Form.Item>
                        </div>

                        <div className="addseries-cont-form-section-item">
                            <span>Country</span>
                            <Form.Item name="country" rules={[{ required: true}]}>
                                <Input  />
                            </Form.Item>
                        </div>

                        <SeriesAutoFields/>
                        
                    </div>
                    <div className="addseries-cont-form-footer">
                        <button type="submit" className="addseries-cont-form-footer-btn-next">Edit</button>
                    </div>

                </Form>
            </div>
        </div>
    )
}

export default SeriesEdit;
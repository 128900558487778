import React, { useEffect, useState } from "react" ;
import {ToastContainer} from "react-toastify";
import Toast_noty from '../../../../widget/Toast_noty'
import Loader from "../../../../widget/Loader/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload} from "@fortawesome/free-solid-svg-icons";
import {Admin_Add_Series_One} from '../../../../widget/API_Admin';
import { Upload , DatePicker, Form , Input ,InputNumber} from 'antd';
import ImgCrop from 'antd-img-crop';
import SeriesAutoFields from '../../SeriesAutoFields'
import moment from "moment";




function SeriesAddStepOne(props) {
    const[dispaly , setDisplay]=useState(false);
    const[fileList , setFileList]=useState([]);

        // Function For Upload Handel

        const onChange = ({ fileList: newFileList }) => {
            setFileList(newFileList);
            props.setCoverFile(newFileList)

            };
        
        const onPreview = async file => {
            let src = file.url;
            if (!src) {
                src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
                });
            }
            const image = new Image();
            image.src = src;
            const imgWindow = window.open(src);
            imgWindow.document.write(image.outerHTML);
            };
    
        const dummyRequest = ({ file, onSuccess }) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
            };
    
        // End Function For Upload 



    const onFinish=(values)=>{
        if(fileList.length > 0){
            if(values && values.labels){
                values['labels'] =  Object.entries(values.labels).map(([key , value])=>{
                    if(value && value._d){
                        return {
                            "id" : key,
                            "value" : String(moment(value._d).format('YYYY-MM-DD hh:mm')),
                        }
                    }
                    else {
                        return {
                            "id" : key,
                            "value" : String(value),
                        }
                    }
                    
                })
            }
            setDisplay(true);
            const returndata =  Admin_Add_Series_One(values , fileList);
            returndata.then((res)=>{
            setDisplay(false)
            if(res.result == 'ok'){
                props.setStep(2);
                props.setSeriesId(res.data.id);
                props.seSeriesName(res.data.name);
            }
            else 
            {
                Toast_noty(res.error, 5000, "error");
            }
            })
            returndata.catch((er)=>{
                setDisplay(false);
                Toast_noty('please try again', 5000, "error");
            })
        }
        else {
            Toast_noty('Select Cover', 5000, "error");
        }
        
    }




    return (
            <div className="addseries-cont">
                {dispaly && <Loader/>}
                {/* <div className="addseries-cont-head">
                    <h3>Add series</h3>
                </div> */}
                <Form onFinish={onFinish} className="addseries-cont-form">
                    <div className="addseries-cont-form-section">

                        <div className="addseries-cont-form-section-cover-head">
                            <div className="addseries-cont-form-section-cover-head-inner">
                                <ImgCrop>
                                    <Upload
                                    className="mt-2"
                                    accept=".png , .jpg , .jpeg , .pdf "
                                    listType="picture-card"
                                    fileList={fileList}
                                    onChange={onChange}
                                    onPreview={onPreview}
                                    customRequest={dummyRequest}
                                    showUploadList={{showPreviewIcon:false}}
                                    >
                                        {fileList.length < 1 &&
                                            <div className="addseries-cont-form-section-cover-head-inner-upload">
                                                <span>Cover</span>
                                                <FontAwesomeIcon icon={faUpload} />
                                            </div>
                                        }
                                    </Upload>
                                </ImgCrop>
                            </div>
                        </div>

                        <div className="addseries-cont-form-section-item">
                            <span>Series name</span>
                            <Form.Item name="name" rules={[{ required: true}]}>
                                <Input />
                            </Form.Item>
                        </div>

                        <div className="addseries-cont-form-section-item">
                            <span>Director</span>
                            <Form.Item name="director" rules={[{ required: true}]}>
                                <Input />
                            </Form.Item>
                        </div>

                        <div className="addseries-cont-form-section-item">
                            <span>Season count</span>
                            <Form.Item name="season_count" rules={[{ required: true}]}>
                                <InputNumber />
                            </Form.Item>
                        </div>

                        <div className="addseries-cont-form-section-item">
                            <span>Publish time</span>
                            <Form.Item name="publish_time" rules={[{ required: true}]}>
                                <DatePicker  />
                            </Form.Item>
                        </div>

                        <div className="addseries-cont-form-section-item">
                            <span>Year</span>
                            <Form.Item name="year" rules={[{ required: true}]}>
                                <InputNumber  />
                            </Form.Item>
                        </div>

                        <div className="addseries-cont-form-section-item">
                            <span>Country</span>
                            <Form.Item name="country" rules={[{ required: true}]}>
                                <Input  />
                            </Form.Item>
                        </div>

                        <SeriesAutoFields/>
 


                    </div>
                    <div className="addseries-cont-form-footer"><button className="addseries-cont-form-footer-btn-next">Next Step</button></div>
                </Form>
            </div>
    )
}

export default SeriesAddStepOne;
import { faAddressBook, faTachometerAlt, faCircleNotch, faChevronRight, faShoppingBag, faList, faShoppingCart, faSearch, faEdit, faUser, faLock, faImage, faImages } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { render } from "@testing-library/react";
import React  from "react" ;


const NavDataSearch = [
    {
        'name':"Dashboard" ,
        'link':'/panel' ,
    },
    {
        'name':"Add Main List Dynamic" ,
        'link':'/panel/dynamic/all/add' ,
    },
    {
        'name':"Add Artist List Dynamic" ,
        'link':'/panel/dynamic/artist/add' ,
    },
    {
        'name':"Add Podcast List Dynamic" ,
        'link':'/panel/dynamic/podcast/add' ,
    },
    {
        'name':"Add Movie List Dynamic" ,
        'link':'/panel/dynamic/movie/add' ,
    },
    {
        'name':"Add Series List Dynamic" ,
        'link':'/panel/dynamic/series/add' ,
    },
    {
        'name':"Add Album List Dynamic" ,
        'link':'/panel/dynamic/album/add' ,
    },
    {
        'name':"All List Dynamic" ,
        'link':'/panel/dynamic/all/list' ,
    },
    {
        'name':"Confirmation Album" ,
        'link':'/panel/confirmation/album' ,
    },
    {
        'name':"Confirmation User" ,
        'link':'/panel/confirmation/user' ,
    },
    {
        'name':"Confirmation Podcast" ,
        'link':'/panel/confirmation/podcast' ,
    },
    {
        'name':"Confirmation MusicVideo" ,
        'link':'/panel/confirmation/musicvideo' ,
    },
    {
        'name':"Add & Mange Properties" ,
        'link':'/panel/properties' ,
    },
    {
        'name':"Add & Manage Label" ,
        'link':'/panel/label' ,
    },
    {
        'name':"Banner" ,
        'link':'/panel/banner' ,
    },
    {
        'name':"Genre" ,
        'link':'/panel/gender' ,
    },
    {
        'name':"Categories" ,
        'link':'/panel/categories' ,
    },
    {
        'name':"List All Movies" ,
        'link':'/panel/movies/list' ,
    },
    {
        'name':"Add Movies" ,
        'link':'/panel/movies/add' ,
    },
    {
        'name':"List All Series" ,
        'link':'/panel/series/list',
    },
    {
        'name':"Add Series" ,
        'link':'/panel/series/add' ,
    },
    {
        'name':"List Album" ,
        'link':'/panel/confirmation/album' ,
    },
    {
        'name':"Add Album" ,
        'link':'/panel/album/add' ,
    },
    {
        'name':"List MusicVideo" ,
        'link':'/panel/confirmation/musicvideo' ,
    },
    {
        'name':"Add MusicVideo" ,
        'link':'/panel/musicvideo/add' ,
    },
    {
        'name':"List Podcast" ,
        'link':'/panel/confirmation/podcast' ,
    },
    {
        'name':"Add Podcast" ,
        'link':'/panel/podcast/add' ,
    },
    {
        'name':"List User" ,
        'link':'/panel/confirmation/user' ,
    }
]

export default NavDataSearch ;
import React, { useEffect, useState } from "react" ;
import {ToastContainer} from "react-toastify";
import Toast_noty from '../../../widget/Toast_noty'
import Loader from "../../../widget/Loader/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { Select , DatePicker, TimePicker , Upload , Form , Input, InputNumber, Button} from 'antd';
import ImgCrop from 'antd-img-crop';
import moment from "moment";
import { Redirect } from "react-router-dom";
import {Admin_Get_Movies_Single , Admin_Edit_Movies} from '../../../widget/API_Admin';
import MoviesAutoFields from '../MoviesAutoFields';
import '../moviesStyle.css';



function MoviesEdit(props) {
    const[dispaly , setDisplay]=useState(false);
    const[redirect , setRedirect]=useState(false);
    const[fileMovies , setFileMovies]=useState(null);
    const [duration , setDuration]=useState(0);
    const [movies , setMovies]=useState(null);
    const [form] = Form.useForm();
    const { Option } = Select;


    const selectFile= async(e)=>{
        await setFileMovies(e.target.files[0])
        let video = document.createElement('video');
        let fileURL = URL.createObjectURL(e.target.files[0]);
        video.src = fileURL ;
        video.ondurationchange = function() {
            setDuration(parseInt(this.duration));
        };
    }


    const onFinish=(values)=>{
        if(values && values.labels){
            values['labels'] =  Object.entries(values.labels).map(([key , value])=>{
                if(value && value._d){
                    return {
                        "id" : key,
                        "value" : String(moment(value._d).format('YYYY-MM-DD hh:mm')),
                    }
                }
                else {
                    return {
                        "id" : key,
                        "value" : String(value),
                    }
                }
                
            })
        }
        setDisplay(true);
        const returndata =  Admin_Edit_Movies(props.match.params.id , values , fileMovies);
        returndata.then((res)=>{
        setDisplay(false)
        if(res.result == 'ok'){
            setRedirect(true)
        }
        else 
        {
            Toast_noty(res.error, 5000, "error");
        }
        })
        returndata.catch((er)=>{
            setDisplay(false);
            Toast_noty('please try again', 5000, "error");
        })
    }

    const getDetailMovies=()=>{
        if(props.match.params.id){
            setDisplay(true)
            const returndata =  Admin_Get_Movies_Single(props.match.params.id);
            returndata.then((res)=>{
            setDisplay(false)
            if(res.result == 'ok'){
                setMovies(res.data)
                form.setFieldsValue({'name' : res.data.name})
                form.setFieldsValue({'description' : res.data.description})
                form.setFieldsValue({'publish_date' : moment(res.data.publish_date)})
                form.setFieldsValue({'duration' : moment(res.data.duration)})
                form.setFieldsValue({'country' : res.data.country})
                if(res.data.labels){
                    for(let i of res.data.labels){
                        if(i.label && i.label != null && i.label.property.type == 'enum' && i.label.property.choose == 'single'){
                            form.setFieldsValue({ 'labels' : {[i.label_value_id] : parseInt(i.value)}})
                        }
                        else if(i.label && i.label != null && i.label.property.type == 'enum' && i.label.property.choose == 'multi'){
                            form.setFieldsValue({ 'labels' : {[i.label_value_id] : 
                                i.value.split(",").map(item=>{
                                    return parseInt(item)
                                })
                            }})
                        }
                        else if (i.label && i.label != null && i.label.property.type == 'date_time') {
                            form.setFieldsValue({ 'labels' : {[i.label_value_id] : moment(i.value)}})
                        }
                        else if(i.label && i.label != null ) {
                            form.setFieldsValue({ 'labels' : {[i.label_value_id] : i.value}})
                        }
                    }
                }
            }
            else if(res.error == 'unauthenticated') {
                localStorage.clear();
                props.history.push('/');  
            }
            else {
                Toast_noty(res.error, 5000, "error");
            }
            })
             returndata.catch((er)=>{
                setDisplay(false)
                Toast_noty('please try again', 5000, "error");
            })
        }
    }


    useEffect(()=>{
        getDetailMovies();
      },[])


    return (
        <div className="moviesdetail">
            {dispaly && <Loader/>}
            {redirect && <Redirect to={`/panel/moviesdetail/${props.match.params.id}`} />}
            <div className="addmovies-cont">
                <Form form={form} onFinish={onFinish} className="addmovies-cont-form">
                    <div className="addmovies-cont-form-section">

                        <div className="addmovies-cont-form-section-item">
                            <span>Movies name</span>
                            <Form.Item name="name" rules={[{ required: true}]}>
                                <Input />
                            </Form.Item>
                        </div>

                        <div className="addmovies-cont-form-section-item">
                            <span>description</span>
                            <Form.Item name="description" rules={[{ required: true}]}>
                                <Input />
                            </Form.Item>
                        </div>

                        <div className="addmovies-cont-form-section-item">
                            <span>Duration</span>
                            <Form.Item name="duration" rules={[{ required: true}]}>
                                <TimePicker />
                            </Form.Item>
                        </div>

                        <div className="addmovies-cont-form-section-item">
                            <span>Publish date</span>
                            <Form.Item name="publish_date" rules={[{ required: true}]}>
                                <DatePicker />
                            </Form.Item>
                        </div>

                        <div className="addmovies-cont-form-section-item">
                            <span>Country</span>
                            <Form.Item name="country" rules={[{ required: true}]}>
                                <Input />
                            </Form.Item>
                        </div>

                        <MoviesAutoFields/>

                        {fileMovies == null &&
                            <label className="addmovies-cont-form-section-movies">
                                <input type="file" onChange={(e)=>setFileMovies(e.target.files[0])}  accept="video/*" />
                                <div className="addmovies-cont-form-section-movies-des">
                                    <h3>Update Movies</h3>
                                    <FontAwesomeIcon icon={faUpload} />
                                </div> 
                            </label>
                        }

                        {fileMovies != null &&
                            <>
                            {fileMovies.type == "video/mp4" ? 
                                <div className="addmovies-cont-form-section-movies-prev">
                                    <video  controls>
                                        <source src={URL.createObjectURL(fileMovies)} type="video/mp4" />
                                    </video> 
                                </div>
                            :
                                <div className="addmovies-cont-form-section-movies">
                                    <div className="addmovies-cont-form-section-movies-des">
                                        <h3>{fileMovies.name}</h3>
                                    </div> 
                                </div>
                            }
                            </>
                        }
                        
                    </div>
                    <div className="addmovies-cont-form-footer">
                        <button type="submit" className="addmovies-cont-form-footer-btn-next">Edit</button>
                        {fileMovies != null &&
                            <button onClick={()=>{setFileMovies(null)}} type="button" className="addmovies-cont-form-footer-btn-del">Delete</button>
                        }
                    </div>

                </Form>
            </div>
        </div>
    )
}

export default MoviesEdit;
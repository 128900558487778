import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react" ;
import {Get_Dashboard} from '../../widget/API';
import {ToastContainer} from "react-toastify";
import Toast_noty from '../../widget/Toast_noty';
import Gold from '../../static/img/gold.png'
import Loader from '../../widget/Loader/Loader';
import {Link} from 'react-router-dom'
import './dashboardStyle.css';

function Dashboard(props) {
    const[dashboardData , setDashboardData]=useState('');
    const[dispaly , setDisplay]=useState(true);

    useEffect(()=>{
        setDisplay(true);
        const returndata = Get_Dashboard();
        returndata.then((res)=>{
        setDisplay(false);
        if(res.result == 'ok'){
            setDashboardData(res.data)
        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
        returndata.catch((er)=>{
            setDisplay(false);
            Toast_noty('please try again', 5000, "error");
        })
      },[])


    return (
        <div className="dashboard">
            {dispaly && <Loader/>}
           <div className="dashboard-one">
               <div className="dashboard-one-left">
                   <div className="dashboard-one-left-inner">
                       <div className="dashboard-one-left-inner-left">
                           <h3>Congratulations 🎉 Admin!</h3>
                           <span> You have won gold medal </span>
                           <Link to='/panel/albumlist'><button>View Album</button></Link>
                       </div>
                       <div className="dashboard-one-left-inner-right">
                           <img src={Gold} alt="gold" />
                       </div>
                   </div>
               </div>
               <div className="dashboard-one-right">
                   <div className="dashboard-one-right-inner">
                       <div className="dashboard-one-right-inner-head">
                           <h3>Statistics</h3>
                       </div>
                       <div className="dashboard-one-right-inner-cont">
                           <div className="dashboard-one-right-inner-cont-item">
                               <div className="dashboard-one-right-inner-cont-item-icon">
                                   <FontAwesomeIcon icon={faUser} />
                               </div>
                               <div className="dashboard-one-right-inner-cont-item-des">
                                   <h5>{dashboardData.followers} K</h5>
                                   <span>Followers</span>
                               </div>
                           </div>
                           <div className="dashboard-one-right-inner-cont-item">
                               <div className="dashboard-one-right-inner-cont-item-icon">
                                   <FontAwesomeIcon icon={faUser} />
                               </div>
                               <div className="dashboard-one-right-inner-cont-item-des">
                                   <h5>{dashboardData.manger} N </h5>
                                   <span>Manger</span>
                               </div>
                           </div>
                           <div className="dashboard-one-right-inner-cont-item">
                               <div className="dashboard-one-right-inner-cont-item-icon">
                                   <FontAwesomeIcon icon={faUser} />
                               </div>
                               <div className="dashboard-one-right-inner-cont-item-des">
                                   <h5> {dashboardData.album}  N</h5>
                                   <span>Album</span>
                               </div>
                           </div>
                           <div className="dashboard-one-right-inner-cont-item">
                               <div className="dashboard-one-right-inner-cont-item-icon">
                                   <FontAwesomeIcon icon={faUser} />
                               </div>
                               <div className="dashboard-one-right-inner-cont-item-des">
                                   <h5>9745  K</h5>
                                   <span>User</span>
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
        </div>
    )
}

export default Dashboard;
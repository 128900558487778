import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react" ;
import {ToastContainer} from "react-toastify";
import Loader from '../../../../widget/Loader/Loader';

function AddDynamicMovieBar(props) {
    const[dispaly , setDisplay]=useState(false);
    const[listData , setListData]=useState([]);


    useEffect(()=>{
        if(props.listData){
            setListData(props.listData);
        }
    },[props.listData])


    return (
        <div className="dynamic-bar">
            {dispaly && <Loader/>}
            <div className="dynamic-bar-inner">
                <div className="dynamic-bar-inner-head">Filters</div>
                <div className="dynamic-bar-inner-main">
                    {listData.map((item , index)=>
                        <div onClick={()=>{props.setActiveTab(item.name)}} className={`dynamic-bar-inner-main-item ${props.activeTab == item.name && 'dynamic-bar-inner-main-item-active'}`}>{item.name.substring(11)}</div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default AddDynamicMovieBar;
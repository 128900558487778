import React, { useEffect, useRef, useState } from "react" ;
import Toast_noty from '../../../widget/Toast_noty'
import Loader from "../../../widget/Loader/Loader";

import { Admin_Get_Music_Video_Detail} from '../../../widget/API_Admin';
import {Link} from 'react-router-dom';
import MusicVideoDetailHead from "./widget/MusicVideoDetailHead";
import FileMusicVideo from '../../../widget/FileMusicVideo';
import CModal from "../../../widget/CModal/CModal";
import MusicVideoDetailCont from './widget/MusicVideoDetailCont';
import MusicVideoDetailFeat from './widget/MusicVideoDetailFeat'
import MusicVideoDetailViews from './widget/MusicVideoDetailViews';
import MusicVideoDetailLikes from './widget/MusicVideoDetailLikes';
import SkeletonSection from '../../../widget/skeleton/SkeletonSection';
import '../musicvideoStyle.css';



function MusicVideoDetail(props) {
    const[dispaly , setDisplay]=useState(false);
    const [musicVideo , setMusicVideo]=useState(null);





    const getDetailMusiVideo=()=>{
        if(props.match.params.id){
            setDisplay(true)
            const returndata =  Admin_Get_Music_Video_Detail(props.match.params.id , props.match.params.artistID);
            returndata.then((res)=>{
            setDisplay(false)
            if(res.result == 'ok'){
                setMusicVideo(res.data)
            }
            else if(res.error == 'unauthenticated') {
                localStorage.clear();
                props.history.push('/');  
            }
            else {
                Toast_noty(res.error, 5000, "error");
            }
            })
             returndata.catch((er)=>{
                setDisplay(false)
                Toast_noty('please try again', 5000, "error");
            })
        }
    }


    useEffect(()=>{
        getDetailMusiVideo();
      },[])

    return (
        <div className="musicvideodetail">
            {/* {dispaly && <Loader/>} */}
            {musicVideo == null && <SkeletonSection/>}
            {musicVideo == null && <SkeletonSection/>}
            {musicVideo != null && <MusicVideoDetailHead musicVideo={musicVideo} /> }
            {musicVideo != null &&<MusicVideoDetailCont musicVideo={musicVideo} getDetailMusiVideo={getDetailMusiVideo} />}
            {musicVideo != null &&<MusicVideoDetailFeat musicVideo={musicVideo} getDetailMusiVideo={getDetailMusiVideo} />}
            {musicVideo != null &&<MusicVideoDetailViews musicVideo={musicVideo}  />}
            {musicVideo != null &&<MusicVideoDetailLikes musicVideo={musicVideo}  />}
            
        </div>
    )
}

export default MusicVideoDetail;
import {BASE_URL} from './BASE_URL';
import moment from 'moment'


// Api Request For Admin

function Admin_Get_List_Main_Dynamic() {
  return fetch(`${BASE_URL}/list_dynamic/init` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Get_List_Main_Dynamic};




function Admin_Filter_Dynamic(mainState , sortSelect) {
  let test = {
    "tables" : [
          {
              "name" : "App\\Models\\Artist",
              "other" : {
                  "name" : {
                      "type" : "string",
                      "exactly" : false,
                      "list" : [
                          "sha",
                          "re"
                      ]
                  },
                  "updated_at" : {
                      "type" : "date_time",
                      "date_type" : "range",
                      "list" : [
                          "2019-01-01 12:00",
                          "2019-02-02 12:00"
                      ]
                  }
              }
          }
      ],
      "sort" : "name"
  }
  let postdata = {
    'tables':[] ,
    'sort':sortSelect ,
  }
  for(let key in mainState) {
      postdata['tables'].push({
          "name":`App\\Models\\${key}` ,
          "other":mainState[key] ,
      })
  }
  return fetch(`${BASE_URL}/list_dynamic/result` , {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: JSON.stringify(postdata)
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Filter_Dynamic};





function Admin_Delete_Dynamic_List(id) {
  return fetch(`${BASE_URL}/list_dynamic/${id}` , {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Delete_Dynamic_List};



function Admin_Delete_Item_From_List(id) {
  return fetch(`${BASE_URL}/list_dynamic/1000/data/${id}` , {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Delete_Item_From_List};



function Admin_Get_All_Data(current , sort , type , table) {
  let Url = `${BASE_URL}/management?model=${table}&page=${current}`;
  if(sort != null){
    Url = `${Url}&sort=${sort}`;
  }
  if(type != null){
    Url = `${Url}&type=${type}`;
  }
  return fetch(Url , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Get_All_Data};





function Admin_Change_Status_Data(id , type , reson , table) {
  let postData = {
    'type':type,
  }
  if(type == 'rejected'){
    postData['message'] = reson ;
  }
  return fetch(`${BASE_URL}/management/${id}/accessing?model=${table}` , {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: JSON.stringify(postData)
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Change_Status_Data};






function Admin_Get_Detail_Data(id , table) {
  return fetch(`${BASE_URL}/management/${table}/${id}` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Get_Detail_Data};



function Admin_Get_Cover_Image(artistId , id) {
  return fetch(`${BASE_URL}/artists/${artistId}/albums/${id}/cover` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.blob())
    .then((res) => {
      return URL.createObjectURL(res); ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Get_Cover_Image};


function Admin_Get_User_Image(artistId) {
  return fetch(`${BASE_URL}/artists/${artistId}/cover` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.blob())
    .then((res) => {
      return URL.createObjectURL(res); ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Get_User_Image};



function Admin_Get_All_User(current , type) {
  let Url = `${BASE_URL}/artists_management?page=${current}`;
  if(type != null){
    Url = `${Url}&type=${type}`;
  }
  return fetch(Url , {
    method: 'GET',
    // mode: 'cors',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
      // console.log(er)
    })
}
export {Admin_Get_All_User};


function Admin_Change_Status_User(id , type , reson) {
  let postData = {
    'type':type,
  }
  if(type == 'rejected'){
    postData['message'] = reson ;
  }
  return fetch(`${BASE_URL}/artists_management/${id}` , {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: JSON.stringify(postData)
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Change_Status_User};




function Admin_Get_All_Banner() {
  return fetch(`${BASE_URL}/banners` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Get_All_Banner};






function Admin_Delete_Banner(id) {
  return fetch(`${BASE_URL}/banners/${id}` , {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Delete_Banner};





function Admin_Get_Data_Add_Banner_List(value) {
  let Url = `${BASE_URL}/model_all?model=${value.charAt(0).toUpperCase() + value.slice(1)}`;
  return fetch(Url , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Get_Data_Add_Banner_List};




function Admin_Add_Banner(values , banner ) {
  var formdata = new FormData();
  formdata.append("value", values.value);
  formdata.append("type", values.type);
  formdata.append("image", banner[0].originFileObj);
  return fetch(`${BASE_URL}/banners` , {
    method: 'POST',
    headers: {
      // 'Accept': 'application/json',
      // "Content-type": "multipart/form-data" ,
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: formdata ,
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Add_Banner};






function Admin_Change_Status_Banner(id , state) {
  return fetch(`${BASE_URL}/banners/${id}` , {
    method: 'POST',
    headers: {
      // 'Accept': 'application/json',
      // "Content-type": "multipart/form-data" ,
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: JSON.stringify({
      'active':state
    })
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Change_Status_Banner};




function Admin_Get_All_Gender() {
  return fetch(`${BASE_URL}/genders` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      "Content-type": "multipart/form-data" ,
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Get_All_Gender};


function Admin_Add_Gender(e) {
  return fetch(`${BASE_URL}/genders` , {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: JSON.stringify({
      'name':e.target[0].value
    })
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Add_Gender};





function Admin_Delete_Gender(id) {
  return fetch(`${BASE_URL}/genders/${id}` , {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Delete_Gender};





function Admin_Get_All_Category() {
  return fetch(`${BASE_URL}/categories` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      "Content-type": "multipart/form-data" ,
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Get_All_Category};



function Admin_Add_Category(e) {
  return fetch(`${BASE_URL}/categories` , {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: JSON.stringify({
      'name':e.target[0].value
    })
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Add_Category};




function Admin_Delete_Category(id) {
  return fetch(`${BASE_URL}/categories/${id}` , {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Delete_Category};






function Admin_Add_Dynamic_List(values , listSelect , type , activeTab , dynamicListSelect) {
  let postData = {} ;
  let Url_Requrst = "" ;

  if(activeTab == 0) {
    postData = {
      "detail" : {
        "name" : values.name
      },
      "data":listSelect
    }
    Url_Requrst = `${BASE_URL}/list_dynamic?type=${type}` ;
  }
  else if (activeTab == 1) {
    postData = {
      "data":listSelect
    }
    Url_Requrst = `${BASE_URL}/list_dynamic/${dynamicListSelect}/data` ;
  }
 
  return fetch(Url_Requrst , {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: JSON.stringify(postData)
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Add_Dynamic_List};




function Admin_Get_All_Dynamic_List(typeList) {
  return fetch(`${BASE_URL}/list_dynamic?type=${typeList}` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Get_All_Dynamic_List};






function Admin_Get_All_Properties() {
  return fetch(`${BASE_URL}/properties` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Get_All_Properties};




function Admin_Get_All_Init_Properties() {
  return fetch(`${BASE_URL}/properties/init` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Get_All_Init_Properties};




function Admin_Add_Peroperty(values) {
  let postData = {
    "name" :values.name ,
    "description":values.description ,
    "type":values.type ,
  }
  if(values.type == 'enum'){
    postData['choose'] = values.selectEnum ;
  }
  else {
    postData['choose'] = 'single' ;
  }
  if(values.typeOfEnum == 'table'){
    postData['table'] = true ;
    postData['values'] = values.table
  }
  if(values.typeOfEnum == 'multi'){
    postData['table'] = false ;
    postData['values'] = JSON.stringify(values.multi)
  }
  return fetch(`${BASE_URL}/properties` , {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: JSON.stringify(postData)
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Add_Peroperty};




function Admin_Delete_Peroperty(id) {
  return fetch(`${BASE_URL}/properties/${id}` , {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Delete_Peroperty};




function Admin_Get_All_Label(typeTable) {
  return fetch(`${BASE_URL}/labels?type=${typeTable}` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Get_All_Label};





function Admin_Add_Label(values) {
  let postData = {
    "type":values.type ,
    "property_id":values.peroperty ,
    "requirement":values.requirement ,
  }
  return fetch(`${BASE_URL}/labels` , {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: JSON.stringify(postData)
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Add_Label};






function Admin_Delete_Label(id) {
  return fetch(`${BASE_URL}/labels/${id}` , {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Delete_Label};




function Admin_Edit_Label(values , id) {
  let postData = {
    "type":values.type ,
    "property_id":values.peroperty ,
    "requirement":values.requirement ,
  }
  return fetch(`${BASE_URL}/labels/${id}` , {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: JSON.stringify(postData)
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Edit_Label};






function Admin_Add_Movies(values , cover ,movies  ) {
  var formdata = new FormData();
  formdata.append("name", values.name);
  formdata.append("description", values.description);
  formdata.append("duration", moment(values.duration._d , 'HH:mm:ss').diff(moment().startOf('day'), 'seconds'));
  formdata.append("publish_date", moment(values.publish_date._d).format('YYYY-MM-DD hh:mm'));
  formdata.append("movie", movies);
  formdata.append("cover", cover[0].originFileObj);
  formdata.append("country", values.country);
  if(values.labels){
    formdata.append("labels", JSON.stringify(values.labels));
  }
  return fetch(`${BASE_URL}/movies` , {
    method: 'POST',
    headers: {
      // 'Accept': 'application/json',
      // "Content-type": "multipart/form-data" ,
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: formdata ,
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Add_Movies};





function Admin_Edit_Movies(moviesId , values ,movies ) {
  var formdata = new FormData();
  formdata.append("name", values.name);
  formdata.append("description", values.description);
  formdata.append("publish_date", moment(values.publish_date._d).format('YYYY-MM-DD hh:mm'));
  formdata.append("country", values.country);
  if(movies != null){
    formdata.append("movie", movies);
    formdata.append("duration", moment(values.duration._d , 'HH:mm:ss').diff(moment().startOf('day'), 'seconds'));
  }
  if(values.labels){
    formdata.append("labels", JSON.stringify(values.labels)) ;
  }
  return fetch(`${BASE_URL}/movies/${moviesId}` , {
    method: 'POST',
    headers: {
      // 'Accept': 'application/json',
      // "Content-type": "multipart/form-data" ,
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: formdata ,
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Edit_Movies};




function Admin_Update_Cover_Movies(moviesId , cover ) {
  var formdata = new FormData();
  formdata.append("cover", cover[0].originFileObj);
  return fetch(`${BASE_URL}/movies/${moviesId}` , {
    method: 'POST',
    headers: {
      // 'Accept': 'application/json',
      // "Content-type": "multipart/form-data" ,
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: formdata ,
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Update_Cover_Movies};



function Admin_Delete_Movies(moviesId) {
  return fetch(`${BASE_URL}/movies/${moviesId}` , {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Delete_Movies};






function Admin_Get_All_Movies(current) {
  return fetch(`${BASE_URL}/movies?page=${current}` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Get_All_Movies};




function Admin_Get_Movies_Single(id) {
  return fetch(`${BASE_URL}/movies/${id}` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Get_Movies_Single};




function Admin_Movies_Add_Ads(moviesId , values  ) {
  var formdata = new FormData();
  for(let i in values){
    formdata.append(`file_${i}`, values[i].file);
    formdata.append(`time_${i}`, values[i].duration);
    formdata.append(`duration_${i}`, values[i].ads_duration);
  }

  return fetch(`${BASE_URL}/movies/${moviesId}/ads` , {
    method: 'POST',
    headers: {
      // 'Accept': 'application/json',
      // "Content-type": "multipart/form-data" ,
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: formdata ,
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Movies_Add_Ads};




function Admin_Movies_Delete_Ads(moviesId , adsId) {
  return fetch(`${BASE_URL}/movies/${moviesId}/ads/${adsId}` , {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Movies_Delete_Ads};




function Admin_Get_Music_Video_File_Video(id , artistId) {
  return fetch(`${BASE_URL}/artists/${artistId}/music_videos/${id}/video` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.blob())
    .then((res) => {
      return URL.createObjectURL(res) ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Get_Music_Video_File_Video};



function Admin_Get_Movies(id ) {
  return fetch(`${BASE_URL}/movies/${id}/file` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.blob())
    .then((res) => {
      return URL.createObjectURL(res) ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Get_Movies};



function Admin_Get_All_Series(current) {
  return fetch(`${BASE_URL}/series?page=${current}` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Get_All_Series};




function Admin_Get_Series_Single(seriesId) {
  return fetch(`${BASE_URL}/series/${seriesId}` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Get_Series_Single};



function Admin_Add_Series_One(values , cover ) {
  var formdata = new FormData();
  formdata.append("name", values.name);
  formdata.append("director", values.director);
  formdata.append("season_count", values.season_count);
  formdata.append("year", values.year);
  formdata.append("publish_time", moment(values.publish_time._d).format('YYYY-MM-DD hh:mm'));
  formdata.append("country", values.country);
  formdata.append("cover", cover[0].originFileObj);
  if(values.labels){
    formdata.append("labels", JSON.stringify(values.labels));
  }
  return fetch(`${BASE_URL}/series` , {
    method: 'POST',
    headers: {
      // 'Accept': 'application/json',
      // "Content-type": "multipart/form-data" ,
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: formdata ,
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Add_Series_One};




function Admin_Add_Series_two(seriesId ,values , fileEpisode  ) {
  var formdata = new FormData();
  formdata.append("description", values.description);
  formdata.append("season_number", values.season_number);
  formdata.append("episode_number", values.episode_number);
  formdata.append("duration", moment(values.duration._d , 'HH:mm:ss').diff(moment().startOf('day'), 'seconds'));
  formdata.append("movie", fileEpisode);
  return fetch(`${BASE_URL}/series/${seriesId}/episode` , {
    method: 'POST',
    headers: {
      // 'Accept': 'application/json',
      // "Content-type": "multipart/form-data" ,
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: formdata ,
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Add_Series_two};


function Admin_Get_All_Episode(seriesId) {
  return fetch(`${BASE_URL}/series/${seriesId}/episode` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Get_All_Episode};




function Admin_Get_Episode_File(seriesId , episodeId) {
  return fetch(`${BASE_URL}/series/${seriesId}/episode/${episodeId}/file` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.blob())
    .then((res) => {
      return URL.createObjectURL(res); 
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Get_Episode_File};




function Admin_Update_Cover_Series(seriesId , cover ) {
  var formdata = new FormData();
  formdata.append("cover", cover[0].originFileObj);
  return fetch(`${BASE_URL}/series/${seriesId}` , {
    method: 'POST',
    headers: {
      // 'Accept': 'application/json',
      // "Content-type": "multipart/form-data" ,
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: formdata ,
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Update_Cover_Series};



function Admin_Delete_Series(seriesId) {
  return fetch(`${BASE_URL}/series/${seriesId}` , {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Delete_Series};




function Admin_Edit_Series(seriesId , values ) {
  var formdata = new FormData();
  formdata.append("name", values.name);
  formdata.append("director", values.director);
  formdata.append("publish_time", moment(values.publish_time._d).format('YYYY-MM-DD hh:mm'));
  formdata.append("country", values.country);
  formdata.append("season_count", values.season_count);
  formdata.append("year", values.year);
  if(values.labels){
    formdata.append("labels", JSON.stringify(values.labels)) ;
  }
  return fetch(`${BASE_URL}/series/${seriesId}` , {
    method: 'POST',
    headers: {
      // 'Accept': 'application/json',
      // "Content-type": "multipart/form-data" ,
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: formdata ,
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Edit_Series};




function Admin_Delete_Episode(seriesId , episodeId) {
  return fetch(`${BASE_URL}/series/${seriesId}/episode/${episodeId}` , {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Delete_Episode};



function Admin_Edit_Episode(seriesId ,episodeId , values , fileEpisode ) {
  var formdata = new FormData();
  formdata.append("description", values.description);
  formdata.append("season_number", values.season_number);
  formdata.append("episode_number", values.episode_number);
  formdata.append("id", episodeId);
  if(fileEpisode != null ){
    formdata.append("duration", moment(values.duration._d , 'HH:mm:ss').diff(moment().startOf('day'), 'seconds'));
    formdata.append("movie", fileEpisode);
  }
  
  return fetch(`${BASE_URL}/series/${seriesId}/episode` , {
    method: 'POST',
    headers: {
      // 'Accept': 'application/json',
      // "Content-type": "multipart/form-data" ,
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: formdata ,
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Edit_Episode};





function Admin_Get_Episode_Ads(seriesId , episodeId) {
  return fetch(`${BASE_URL}/series/${seriesId}/episode/${episodeId}/ads` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res; 
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Get_Episode_Ads};




function Admin_Sereas_Add_Ads(seriesId ,episodeId, values  ) {
  var formdata = new FormData();
  for(let i in values){
    formdata.append(`file_${i}`, values[i].file);
    formdata.append(`time_${i}`, values[i].duration);
    formdata.append(`duration_${i}`, values[i].ads_duration);
  }

  return fetch(`${BASE_URL}/series/${seriesId}/episode/${episodeId}/ads` , {
    method: 'POST',
    headers: {
      // 'Accept': 'application/json',
      // "Content-type": "multipart/form-data" ,
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: formdata ,
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Sereas_Add_Ads};




function Admin_Series_Delete_Ads(seriesId , episodeId , adsId) {
  return fetch(`${BASE_URL}/series/${seriesId}/episode/${episodeId}/ads/${adsId}` , {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Series_Delete_Ads};





function Admin_Get_Auto_Feilds(typeTable) {
  return fetch(`${BASE_URL}/artists/0/labels?type=${typeTable}` , {
    method: 'GET',
    headers: {
      // 'Accept': 'application/json',
      // 'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res; 
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Get_Auto_Feilds};




function Admin_Get_Table_Value(table) {
  return fetch(`${BASE_URL}/artists/0/label_tables?table=${table}` , {
    method: 'GET',
    headers: {
      // 'Accept': 'application/json',
      // 'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res; 
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Get_Table_Value};



function Admin_Add_User(e) {
  return fetch(`${BASE_URL}/auth/register` , {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'artist':{
        "name":e.target[0].value ,
        "about":e.target[3].value ,
      } ,
      "admin":{
        'email':e.target[1].value ,
        'password':e.target[2].value ,
      }
    }) 
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Add_User};




function Admin_Add_Album_One(values) {
  let postData = {
    "album" : {
     "name" : values.name,
     "cover_producer":  values.cover_producer,
     "gender_id" :  values.gender,
     "release_time" :  moment(values.release_time._d).format('YYYY-MM-DD hh:mm') ,
    },
     "categories_id" : values.category,
  }
  if(values.pay){
    postData['product'] = {
      "price_rial" : values.Rial_price,
      "price_dollar" : values.Dollar_price,
      "vip" : values.vip
    }
  }
  if(values.labels){
    postData['labels'] = JSON.stringify(values.labels) ;
  }
  return fetch(`${BASE_URL}/artists/${values.artist}/albums` , {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: JSON.stringify(postData)
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Add_Album_One};



function Admin_Add_Album_Two(albumId , profileImage , artistID) {
  var formdata = new FormData();
  formdata.append("image", profileImage[0].originFileObj);
  return fetch(`${BASE_URL}/artists/${artistID}/albums/${albumId}/cover` , {
    method: 'POST',
    headers: {
      // 'Accept': 'application/json',
      // "Content-type": "multipart/form-data" ,
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: formdata ,
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Add_Album_Two};



function Admin_Add_Album_three(albumId ,values , albumPay , typeAlbum , fileMusic , duration , artistID) {
  var formdata = new FormData();
  var postData = {}
  if(typeAlbum == 'album'){
    postData = {
      "name" : values.name,
      "lyric" : values.lyric,
      "producer" : values.producer,
      "music_producer" : values.music_producer,
      "duration" : duration,
    }
    // if(albumPay){
    //   postData['product'] = JSON.stringify({
    //     "price_rial" : values.Rial_price,
    //     "price_dollar" : values.Dollar_price,
    //     "vip" : values.vip
    //   })
    // }
  }
  else {
    postData = {
      "lyric" : values.lyric,
      "producer" : values.producer,
      "music_producer" : values.music_producer,
      "duration" : duration,
    }
  }
  if(values.isFeats){
    formdata.append("feats", JSON.stringify(values.feats));
  }
  if(values.labels){
    formdata.append("labels" ,JSON.stringify(values.labels))
  }
  formdata.append("audio", fileMusic);
  formdata.append("type", typeAlbum);
  formdata.append("track" ,JSON.stringify(postData))

  return fetch(`${BASE_URL}/artists/${artistID}/albums/${albumId}/tracks` , {
    method: 'POST',
    headers: {
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: formdata ,
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Add_Album_three};



function Admin_Delete_Album(albumId , artistID) {
  return fetch(`${BASE_URL}/artists/${artistID}/albums/${albumId}` , {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Delete_Album};



function Admin_Update_Cover_Album(id , cover , artistID ) {
  var formdata = new FormData();
  formdata.append("image", cover[0].originFileObj);
  return fetch(`${BASE_URL}/artists/${artistID}/albums/${id}/cover` , {
    method: 'POST',
    headers: {
      // 'Accept': 'application/json',
      // "Content-type": "multipart/form-data" ,
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: formdata ,
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Update_Cover_Album};


function Admin_Get_Album_Comment(albumId , current , artistID) {
  return fetch(`${BASE_URL}/artists/${artistID}/likes?page=${current}` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Get_Album_Comment};



function Admin_Get_Album_Views(albumId , current , artistID) {
  return fetch(`${BASE_URL}/artists/${artistID}/albums/${albumId}/views?page=${current}` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Get_Album_Views};



function Admin_Play_Track(albumId ,trackId , artistID) {
  return fetch(`${BASE_URL}/artists/${artistID}/albums/${albumId}/tracks/${trackId}/audio` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.blob())
    .then((res) => {
      return URL.createObjectURL(res); ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Play_Track};



function Admin_Delete_Track(albumId ,trackId , artistID) {
  return fetch(`${BASE_URL}/artists/${artistID}/albums/${albumId}/tracks/${trackId}` , {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Delete_Track};




function Admin_Update_Track_Audio(albumId , trackId ,duration , audio , artistID ) {
  var formdata = new FormData();
  formdata.append("audio", audio);
  formdata.append("duration", duration);
  return fetch(`${BASE_URL}/artists/${artistID}/albums/${albumId}/tracks/${trackId}/audio` , {
    method: 'POST',
    headers: {
      // 'Accept': 'application/json',
      // "Content-type": "multipart/form-data" ,
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: formdata ,
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Update_Track_Audio};




function Admin_Edit_Track_Fits(values, albumId ,trackId , artistID) {
  let postdata = {'feats':{}}
  for(let i of values.feats){
    postdata['feats'][String(i.artist_id)] = {
      'percentage' : i.percentage
    }
  }
  return fetch(`${BASE_URL}/artists/${artistID}/albums/${albumId}/tracks/${trackId}/feats` , {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: JSON.stringify(postdata) ,
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Edit_Track_Fits};



function Admin_Move_Track_To_Album(albumId , trackId , newAlbum , artistID) {
  return fetch(`${BASE_URL}/artists/${artistID}/albums/${albumId}/tracks/${trackId}` , {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: JSON.stringify({
      'album_id':newAlbum ,
    })
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Move_Track_To_Album};


function Admin_Edit_Album(values , id , artistID , payment) {
  let postData = {'album':values}
  if (values.labels){
    postData['labels'] = values.labels ;
  }
  else {
    postData['labels'] = [] ;
  }
  if(payment && payment == true){
    postData['product'] = {
      "price_rial" : values.Rial_price,
      "price_dollar" : values.Dollar_price,
      "vip" : values.vip
    }
  }
  return fetch(`${BASE_URL}/artists/${artistID}/albums/${id}` , {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body:JSON.stringify(postData) ,

    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Edit_Album};



function Admin_Edit_Track(trackId , values , artistID) {
  let postData = {'track':values}
  if (values.labels){
    postData['labels'] = values.labels ;
  } 
  else {
    postData['labels'] = [] ;
  }
  return fetch(`${BASE_URL}/artists/${artistID}/albums/0/tracks/${trackId}` , {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: JSON.stringify(postData)
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Edit_Track};


function Admin_Add_Music_Video(values , cover , music) {
  var formdata = new FormData();
  if(values.isFeats){
    formdata.append("feats", JSON.stringify(values.feats));
  }
  // if(values.pay){
  //   formdata.append('product' , JSON.stringify({
  //     "price_rial" : values.Rial_price,
  //     "price_dollar" : values.Dollar_price,
  //     "vip" : values.vip
  //   }))
  // }
  if(values.labels){
    formdata.append("labels", JSON.stringify(values.labels));
  }
  formdata.append("name", values.name);
  formdata.append("director", values.director);
  formdata.append("release_time", moment(values.release_time._d).format('YYYY-MM-DD hh:mm'));
  formdata.append("cover", cover[0].originFileObj);
  formdata.append("video", music);
  return fetch(`${BASE_URL}/artists/${values.artist}/music_videos` , {
    method: 'POST',
    headers: {
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: formdata ,
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Add_Music_Video};




function Admin_Get_Music_Video_Detail(id , artistID) {
  return fetch(`${BASE_URL}/artists/${artistID}/music_videos/${id}` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Get_Music_Video_Detail};


function Admin_Update_Cover_Music_Video(id , cover , artistID) {
  var formdata = new FormData();
  formdata.append("cover", cover[0].originFileObj);
  return fetch(`${BASE_URL}/artists/${artistID}/music_videos/${id}/cover` , {
    method: 'POST',
    headers: {
      // 'Accept': 'application/json',
      // "Content-type": "multipart/form-data" ,
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: formdata ,
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Update_Cover_Music_Video};


function Admin_Delete_Music_Video(id , artistID) {
  return fetch(`${BASE_URL}/artists/${artistID}/music_videos/${id}` , {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Delete_Music_Video};



function Admin_Update_Music_Video(id , musicvideo , artistID ) {
  var formdata = new FormData();
  formdata.append("video", musicvideo);
  return fetch(`${BASE_URL}/artists/${artistID}/music_videos/${id}/video` , {
    method: 'POST',
    headers: {
      // 'Accept': 'application/json',
      // "Content-type": "multipart/form-data" ,
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: formdata ,
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Update_Music_Video};


function Admin_Get_Music_Video_Likes(musicVideoId , current , artistID) {
  return fetch(`${BASE_URL}/artists/${artistID}/music_videos/${musicVideoId}/likes?page=${current}` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Get_Music_Video_Likes};



function Admin_Get_Music_Video_Views(musicVideoId , current , artistID) {
  return fetch(`${BASE_URL}/artists/${artistID}/music_videos/${musicVideoId}/views?page=${current}` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Get_Music_Video_Views};


function Admin_Edit_MusicVideo_Fits(values, id , artistID) {
  let postdata = {'feats':{}}
  for(let i of values.feats){
    postdata['feats'][String(i.artist_id)] = {
      'percentage' : i.percentage
    }
  }
  return fetch(`${BASE_URL}/artists/${artistID}/music_videos/${id}/feats` , {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: JSON.stringify(postdata) ,
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Edit_MusicVideo_Fits};



function Admin_Edit_Music_Video(values , id , artistID , payment) {
  let postData = {'video':values}
  if (values.labels){
    postData['labels'] = values.labels ;
  } 
  else {
    postData['labels'] = [] ;
  }
  // if(payment && payment == true){
  //   postData['product'] = {
  //     "price_rial" : values.Rial_price,
  //     "price_dollar" : values.Dollar_price,
  //     "vip" : values.vip
  //   }
  // }
  return fetch(`${BASE_URL}/artists/${artistID}/music_videos/${id}` , {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body:JSON.stringify(postData) ,

    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Edit_Music_Video};



function Admin_Add_Podcast(values , cover , podcast , duration) {
  var formdata = new FormData();
  if(values.isFeats){
    formdata.append("feats", JSON.stringify(values.feats));
  }
  // if(values.pay){
  //   formdata.append('product' , JSON.stringify({
  //     "price_rial" : values.Rial_price,
  //     "price_dollar" : values.Dollar_price,
  //     "vip" : values.vip
  //   }))
  // }
  // if(values.pay){
  //   formdata.append("price_rial", values.Rial_price);
  //   formdata.append("price_dollar", values.Dollar_price);
  //   formdata.append("vip", values.vip);
  // }
  if(values.labels){
    formdata.append("labels", JSON.stringify(values.labels));
  }
  formdata.append("name", values.name);
  formdata.append("categories_id", values.category);
  formdata.append("gender_id", values.gender);
  formdata.append("duration", duration);
  formdata.append("producer", values.producer);
  formdata.append("cover_producer", values.cover_producer);
  formdata.append("release_time", moment(values.release_time._d).format('YYYY-MM-DD hh:mm'));
  formdata.append("cover", cover[0].originFileObj);
  formdata.append("audio", podcast);
  return fetch(`${BASE_URL}/artists/${values.artist}/podcasts` , {
    method: 'POST',
    headers: {
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: formdata ,
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Add_Podcast};



function Admin_Get_Podcast_Detail(id , artistID) {
  return fetch(`${BASE_URL}/artists/${artistID}/podcasts/${id}` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Get_Podcast_Detail};


function Admin_Get_Podcast_Likes(podcastId , current , artistID) {
  return fetch(`${BASE_URL}/artists/${artistID}/podcasts/${podcastId}/likes?page=${current}` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Get_Podcast_Likes};



function Admin_Get_Podcast_Views(podcastId , current , artistID) {
  return fetch(`${BASE_URL}/artists/${artistID}/podcasts/${podcastId}/views?page=${current}` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Get_Podcast_Views};



function Admin_Delete_Podcast(id , artistID) {
  return fetch(`${BASE_URL}/artists/${artistID}/podcasts/${id}` , {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Delete_Podcast};


function Admin_Update_Cover_Podcast(id , cover , artistID ) {
  var formdata = new FormData();
  formdata.append("cover", cover[0].originFileObj);
  return fetch(`${BASE_URL}/artists/${artistID}/podcasts/${id}/cover` , {
    method: 'POST',
    headers: {
      // 'Accept': 'application/json',
      // "Content-type": "multipart/form-data" ,
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: formdata ,
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Update_Cover_Podcast};


function Admin_Play_Podcast(PodcastId , artistID) {
  return fetch(`${BASE_URL}/artists/${artistID}/podcasts/${PodcastId}/audio` , {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    })
    .then(res => res.blob())
    .then((res) => {
      return URL.createObjectURL(res); 
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Play_Podcast};


function Admin_Edit_Podcast_Fits(values, id , artistID) {
  let postdata = {'feats':{}}
  for(let i of values.feats){
    postdata['feats'][String(i.artist_id)] = {
      'percentage' : i.percentage
    }
  }
  return fetch(`${BASE_URL}/artists/${artistID}/podcasts/${id}/feats` , {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: JSON.stringify(postdata) ,
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Edit_Podcast_Fits};


function Admin_Update_Podcast(id , podcast , artistID) {
  var formdata = new FormData();
  formdata.append("audio", podcast);
  return fetch(`${BASE_URL}/artists/${artistID}/podcasts/${id}/audio` , {
    method: 'POST',
    headers: {
      // 'Accept': 'application/json',
      // "Content-type": "multipart/form-data" ,
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body: formdata ,
    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Update_Podcast};



function Admin_Edit_Podcast(values , id , artistID , payment) {
  let postData = {'podcast':values}
  if (values.labels){
    postData['labels'] = values.labels ;
  } 
  else {
    postData['labels'] = [] ;
  }
  // if(payment && payment == true){
  //   postData['product'] = {
  //     "price_rial" : values.Rial_price,
  //     "price_dollar" : values.Dollar_price,
  //     "vip" : values.vip
  //   }
  // }
  return fetch(`${BASE_URL}/artists/${artistID}/podcasts/${id}` , {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token':JSON.parse(localStorage.getItem('userInfo')).token ,
    },
    body:JSON.stringify(postData) ,

    })
    .then(res => res.json())
    .then((res) => {
      return res ;
    })
    .catch((er)=>{
      return er ;
    })
}
export {Admin_Edit_Podcast};
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { render } from "@testing-library/react";
import React  from "react" ;
import {Route} from 'react-router-dom';

import Dashboard from '../page/dashboard/Dashboard';

import DynamicAllAdd from "../page/dynamic/DynamicAllAdd/DynamicAllAdd";
import DynamicArtistAdd from '../page/dynamic/DynamicArtistAdd/DynamicArtistAdd'
import DynamicAllList from '../page/dynamic/DynamicAllList/DynamicAllList';
import DynamicPodcastAdd from '../page/dynamic/DynamicPodcastAdd/DynamicPodcastAdd'
import DynamicMusicVideoAdd from '../page/dynamic/DynamicMusicVideoAdd/DynamicMusicVideoAdd';
import DynamicMovieAdd from '../page/dynamic/DynamicMovieAdd/DynamicMovieAdd';
import DynamicSeriesAdd from '../page/dynamic/DynamicSeriesAdd/DynamicSeriesAdd';
import DynamicAlbumAdd from '../page/dynamic/DynamicAlbumAdd/DynamicAlbumAdd';

import AlbumList from '../page/confirmation/album/albumlist/Album';
import User from '../page/confirmation/user/userlist/User'
import Podcast from '../page/confirmation/podcasts/podcastlist/Podcast';
import MusicVideo from '../page/confirmation/musicvideo/musicvideo/MusicVideo';

import Banner from '../page/banner/Banner';

import Genders from '../page/gender/Genders';

import Categories from '../page/categories/Categories';

import Properties from '../page/manageFields/properties/Properties';
import Label from '../page/manageFields/label/Label';

import MoviesAdd from '../page/movies/moviesadd/MoviesAdd'
import MoviesList from '../page/movies/moveislist/MoviesList';
import MoviesDetail from '../page/movies/moviesdetail/MoviesDetail'
import MoviesEdit from '../page/movies/moviesedit/MoviesEdit';

import SeriesList from '../page/series/serieslist/SeriesList';
import SeriesAdd from '../page/series/seriesadd/SeriesAdd'
import SeriesDetail from '../page/series/seriesdetail/SeriesDetail'
import SeriesEdit from '../page/series/seriesedit/SeriesEdit'
import SeriesEditAds from '../page/series/serieseditads/SeriesEditAds';

import EpisodeAdd from '../page/series/episodeadd/EpisodeAdd';
import EpisodeEdit from '../page/series/episodeedit/EpisodeEdit';

import AlbumAdd from '../page/album/albumadd/AlbumAdd';
import AlbumDetail from '../page/album/albumdetail/AlbumDetail';
import AlbumEdit from "../page/album/albumedit/AlbumEdit";

import TrackAdd from '../page/track/trackadd/TrackAdd';
import TrackEdit from '../page/track/trackedit/TrackEdit';
import TrackDetail from '../page/track/trackdetail/TrackDetail'

import MusicVideoAdd from '../page/musicvideo/musicvideoadd/MusicVideoAdd'
import MusicVideoDetail from '../page/musicvideo/musicvideodetail/MusicVideoDetail'
import MusicVideoEdit from '../page/musicvideo/musicvideoedit/MusicVideoEdit'

import PodcastsAdd from '../page/podcasts/podcastsadd/PodcastsAdd';
import PodcastDetail from '../page/podcasts/podcastdetail/PodcastDetail';
import PodcastEdit from '../page/podcasts/podcastedit/PodcastEdit';


const RouteData = [
    {
        'path':"/panel" ,
        'component':Dashboard ,
    } ,
    {
        'path':"/panel/dynamic/all/add" ,
        'component':DynamicAllAdd ,
    } ,
    {
        'path':"/panel/dynamic/artist/add" ,
        'component':DynamicArtistAdd ,
    } ,
    {
        'path':"/panel/dynamic/podcast/add" ,
        'component':DynamicPodcastAdd ,
    } ,
    {
        'path':"/panel/dynamic/musicvideo/add" ,
        'component':DynamicMusicVideoAdd ,
    } ,
    {
        'path':"/panel/dynamic/movie/add" ,
        'component':DynamicMovieAdd ,
    } ,
    {
        'path':"/panel/dynamic/series/add" ,
        'component':DynamicSeriesAdd ,
    } ,
    {
        'path':"/panel/dynamic/album/add" ,
        'component':DynamicAlbumAdd ,
    } ,
    {
        'path':"/panel/dynamic/all/list" ,
        'component':DynamicAllList ,
    } ,
    {
        'path':"/panel/confirmation/album" ,
        'component':AlbumList ,
    } ,
    {
        'path':"/panel/confirmation/musicvideo" ,
        'component':MusicVideo ,
    } ,
    {
        'path':"/panel/confirmation/user" ,
        'component':User ,
    } ,
    {
        'path':"/panel/confirmation/podcast" ,
        'component':Podcast ,
    } ,
    {
        'path':"/panel/banner" ,
        'component':Banner ,
    } ,
    {
        'path':"/panel/gender" ,
        'component':Genders ,
    } ,
    {
        'path':"/panel/categories" ,
        'component':Categories ,
    } ,
    {
        'path':"/panel/properties" ,
        'component':Properties ,
    } ,
    {
        'path':"/panel/label" ,
        'component':Label ,
    } ,
    {
        'path':"/panel/movies/add" ,
        'component':MoviesAdd ,
    } ,
    {
        'path':"/panel/movies/list" ,
        'component':MoviesList ,
    } ,
    {
        'path':"/panel/moviesdetail/:id" ,
        'component':MoviesDetail ,
    } ,
    {
        'path':"/panel/moviesedit/:id" ,
        'component':MoviesEdit ,
    } ,
    {
        'path':"/panel/series/list" ,
        'component':SeriesList ,
    } ,
    {
        'path':"/panel/series/add" ,
        'component':SeriesAdd ,
    } ,
    {
        'path':"/panel/seriesdetail/:id" ,
        'component':SeriesDetail ,
    } ,
    {
        'path':"/panel/seriesedit/:id" ,
        'component':SeriesEdit ,
    } , 
    {
        'path':"/panel/serieseditads/:seriesId/:id" ,
        'component':SeriesEditAds ,
    } ,   
    {
        'path':"/panel/episodeadd/:seriesId" ,
        'component':EpisodeAdd ,
    } ,   
    {
        'path':"/panel/episodeedit/:seriesId/:id" ,
        'component':EpisodeEdit ,
    } ,   
    {
        'path':"/panel/album/add" ,
        'component':AlbumAdd ,
    } ,  
    {
        'path':"/panel/album/detail/:id" ,
        'component':AlbumDetail ,
    } ,
    {
        'path':"/panel/album/edit/:id" ,
        'component':AlbumEdit ,
    } ,  
    {
        'path':"/panel/track/add/:id/:artistID" ,
        'component':TrackAdd ,
    } ,  
    {
        'path':"/panel/track/edit/:id/:artistID" ,
        'component':TrackEdit ,
    } , 
    {
        'path':"/panel/track/detail/:albumId/:trackId/:artistID" ,
        'component':TrackDetail ,
    } , 
    {
        'path':"/panel/musicvideo/add" ,
        'component':MusicVideoAdd ,
    } ,
    {
        'path':"/panel/musicvideo/detail/:id/:artistID" ,
        'component':MusicVideoDetail ,
    } ,
    {
        'path':"/panel/musicvideo/edit/:id/:artistID" ,
        'component':MusicVideoEdit ,
    } ,
    {
        'path':"/panel/podcast/add" ,
        'component':PodcastsAdd ,
    } ,
    {
        'path':"/panel/podcast/detail/:id/:artistID" ,
        'component':PodcastDetail ,
    } ,
    {
        'path':"/panel/podcast/edit/:id/:artistID" ,
        'component':PodcastEdit ,
    } ,
    

]

export default RouteData ;